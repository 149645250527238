import React, { Fragment, useState, useEffect } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { useDispatch } from 'react-redux';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import TableHelper from 'helpers/table';
import JsxHelper from 'helpers/jsx';
import ReportService from 'services/report';
import StringHelper from 'helpers/string';

const SentEmails = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState('fetch');
  const [tableData, setTableData] = useState([]);
  const [failedOnly, setFailedOnly] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const breadcrumbs = JsxHelper.createBreadcrumbs(`Sent Emails`, 'reports');
  const DEFAULT_LIMIT = 1000;

  const refreshData = () => {
    setTableData([]);
    setCurrentPage(1);
    loadData('refresh', 1);
  };

  const fetchData = () => loadData('fetch')

  const fetchFailedOnly = () => {
    setFailedOnly(true);
    loadData('failed', 1, true);
  };

  const loadData = async (button, page, failures) => {
    page = page || currentPage;
    failures = failures || false;
    setLoading(button || true);
    ReportService.listSentEmailsHistory({
      limit: DEFAULT_LIMIT,
      page,
      failed_only: failures,
    })
    .then(res => {
      setTableData(page === 1 ? res : tableData.concat(res));
      setCurrentPage(page + 1);
    })
    .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const headers = [
    JsxHelper.createTableTimeHeader('created_at'),
    {
      name: 'Template',
      selector: 'template',
      sortable: true,
      searchable: true,
      width: '30%',
      cell: (row) => {
        const searchText = row.template + ' ' + row.type + ' ' + Object.keys(row.recipients).join(' ');
        TableHelper.customizeCellValue(row, 'template', searchText);
        return JsxHelper.createTableMultiLineCell({
          header: row.template,
          subheader: StringHelper.capitalizeFirstLetter(row.type),
        })
      }
    },
    JsxHelper.createTableStatusHeader('status'),
    JsxHelper.createTableTextHeaderWithCallback('recipients', 'Recipient(s)', '58%', (row) => {
      // The recipients object is a dictionary with email addresses as keys and names as values.
      return Object.keys(row.recipients).map(email => JsxHelper.createCopyButton({
        label: email,
        background: 'info',
        small: true,
        dispatch,
      }))
    }),
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Sent Emails</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createButton({ label: 'Failed Only', loading: loading === 'failed', disabled: loading || failedOnly, onClick: fetchFailedOnly })}
          {JsxHelper.createButton({ label: 'Refresh', loading: loading === 'refresh', disabled: loading, onClick: refreshData })}
          {JsxHelper.createButton({ label: 'Load More', loading: loading === 'fetch', disabled: loading, onClick: fetchData })}
          {JsxHelper.createBackButton('/reports')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable loading={loading} columns={headers} body={tableData} />
      </Content>
    </Fragment>
  );
};

export default SentEmails;
