import React, { useState, useEffect } from 'react';
import StringHelper from 'helpers/string';
import { isEmpty } from 'helpers';
import JsxHelper from 'helpers/jsx';

const TrafficCards = ({ website, data, loading }) => {
  const [details, setDetails] = useState({});

  const calcData = (data, key) =>
    Object.keys(data).reduce(function (previous, date) {
      return previous + data[date][key];
    }, 0);

  const renderValues = (keyPair) =>
    <div>
      {keyPair.map((item, index) => (
        <div key={index} className='analytics-card__custom'>
          <div className='analytics-card__title'>{item.title}</div>
          <div className='analytics-card__value'>{item.value}</div>
        </div>
      ))}
    </div>;
  
  const getDetailsValue = (key) =>
    loading ? 'Loading...' : details[key];

  // Prepare data.
  useEffect(() => {
    const NOT_AVAILABLE = 'N/A';
    let values = {
      file_storage: NOT_AVAILABLE,
      ec2: NOT_AVAILABLE,
      s3: NOT_AVAILABLE,
      cloudfront: NOT_AVAILABLE,
      database: NOT_AVAILABLE
    };
    if (data && data.storage) {
      values.file_storage = renderValues([
        { title: 'EC2 Files', value: StringHelper.convertToHumanReadableSize(data.storage.ec2_app_size_kb, 'KB') },
        { title: 'S3 Media', value: StringHelper.convertToHumanReadableSize(data.storage.s3_media_size_kb, 'KB') },
        { title: 'S3 Backups', value: StringHelper.convertToHumanReadableSize(data.storage.s3_backup_size_kb, 'KB')}
      ]);
    }
    if (data && data.database) {
      values.database = renderValues([
        { title: 'Size', value: StringHelper.convertToHumanReadableSize(data.database.size, 'B') },
        { title: 'Tables', value: Object.keys(data.database.tables).length },
      ]);
    }
    if (data && data.traffic) {
      const traffic = data.traffic;
      const sizeUnit = data.size_unit || 'B';

      // Unique users card
      if (traffic.ec2 && !isEmpty(traffic.ec2.requests)) {
        const robotBandwidth = calcData(traffic.ec2.bandwidth, 'robots');
        const humanBandwidth = calcData(traffic.ec2.bandwidth, 'users');
        const totalHumans = calcData(traffic.ec2.requests, 'total_users');
        const totalRobots = calcData(traffic.ec2.requests, 'total_robots');
        values.ec2 = renderValues([
          { title: 'Bandwidth', value: StringHelper.convertToHumanReadableSize(robotBandwidth + humanBandwidth, sizeUnit) },
          { title: 'Requests', value: totalHumans + totalRobots },
          { title: 'Humans', value: calcData(traffic.ec2.requests, 'unique_users') },
        ]);
      }

      // S3 traffic out card
      if (traffic.s3 && !isEmpty(traffic.s3.bandwidth)) {
        values.s3 = renderValues([
          { title: 'Outgoing', value: StringHelper.convertToHumanReadableSize(calcData(traffic.s3.bandwidth, 'out_to_internet'), sizeUnit) },
          { title: 'Ingoing', value: StringHelper.convertToHumanReadableSize(calcData(traffic.s3.bandwidth, 'in_from_internet'), sizeUnit) },
          { title: 'CloudFront', value: StringHelper.convertToHumanReadableSize(calcData(traffic.s3.bandwidth, 'out_to_cloudfront'), sizeUnit)}
        ]);
      }

      // Cloudfront traffic out card
      if (traffic.cloudfront && !isEmpty(traffic.cloudfront.bandwidth)) {
        values.cloudfront = renderValues([
          { title: 'Bandwidth', value: StringHelper.convertToHumanReadableSize(calcData(traffic.cloudfront.bandwidth, 'out_to_internet'), sizeUnit) },
          { title: 'HTTPS', value: calcData(traffic.cloudfront.requests, 'https') },
          { title: 'HTTP', value: calcData(traffic.cloudfront.requests, 'http') }
        ]);
      }
    }
    setDetails(prev => ({ ...prev, ...values }));
  }, [data, website]);

  return JsxHelper.createAnalyticsCards({
    customClass: 'analytics-cards__website',
    items: [
      { icon: 'cloudstorage', title: 'File Storage', value: getDetailsValue('file_storage') },
      { icon: 'database', title: 'Database Storage', value: getDetailsValue('database') },
      { icon: 'ec2', title: 'Amazon EC2', value: getDetailsValue('ec2') },
      { icon: 's3', title: 'Amazon S3', value: getDetailsValue('s3') },
      { icon: 'cdn', title: 'Amazon CloudFront', value: getDetailsValue('cloudfront') },
    ]
  });
};

export default TrafficCards;
