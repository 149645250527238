import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { globalServersSelector } from 'store/server/serverSelectors';
import { TitleBar } from 'styles/layout/titlebar';
import Can from 'utils/can';
import { useHistory } from 'react-router-dom';
import { deleteServer, resizeServerVolumes } from 'store/server/serverActions';
import ServerService from 'services/server';
import useConfirm from 'hooks/useConfirm';
import {
  setGlobalSuccessMsg,
  setGlobalErrorMsg,
  setGlobalPleaseWaitMsg,
} from 'store/global/globalActions';
import FormHelper from 'helpers/form';
import { useForm } from 'react-hook-form';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { isEmpty } from '../../helpers';
import StringHelper from '../../helpers/string';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import TableHelper from 'helpers/table';
import { websitesSelector } from 'store/website/websiteSelectors';
import { setGlobalWarningMsg } from 'store/global/globalActions';
import UserHelper from 'helpers/user';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import { openNewTab } from 'helpers';
import env from 'config/env';

const ServerIndex = () => {
  useTitle('Servers');
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({ reValidateMode: 'onSubmit' });
  const confirm = useConfirm();
  const servers = useSelector(globalServersSelector);
  const allWebsites = useSelector(websitesSelector);
  const [selectedServer, setSelectedServer] = useState(false);
  const [selectedServerSize, setSelectedServerSize] = useState();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newVolumeSize, setNewVolumeSize] = useState();
  const [serversCounts, setServersCounts] = useState(null);

  useEffect(() => {
    let serversCountsTemp = {}
    for (let i in servers) {
      serversCountsTemp[servers[i].slug] = {
        serversCount: allWebsites.filter(item => item.server_slug === servers[i].slug).length,
        liveSites: allWebsites.filter(item => item.is_live && item.server_slug === servers[i].slug ).length
      }
    }
    setServersCounts(serversCountsTemp)
    // eslint-disable-next-line
  }, []);

  const handleDeleteServer = server => {
    dispatch(setGlobalPleaseWaitMsg({ id: server.slug, model: 'server', action: 'deleted' }));
    dispatch(deleteServer(server)).then(() =>
      dispatch(setGlobalSuccessMsg({ id: server.slug, model: 'server', action: 'deleted' })),
    );
  };

  const startServer = serverSlug => {
    dispatch(setGlobalPleaseWaitMsg({ id: serverSlug, model: 'server', action: 'started' }));
    const data = { server_slug: serverSlug };
    ServerService.start(data)
      .then(() => dispatch(setGlobalSuccessMsg({ id: serverSlug, model: 'server', action: 'started' })))
      .catch(err => dispatch(setGlobalErrorMsg(err)));
  };

  const stopServer = serverSlug => {
    dispatch(setGlobalPleaseWaitMsg({ id: serverSlug, model: 'server', action: 'stopped' }));
    const data = { server_slug: serverSlug };
    ServerService.stop(data)
      .then(() => dispatch(setGlobalSuccessMsg({ id: serverSlug, model: 'server', action: 'stopped' })))
      .catch(err => dispatch(setGlobalErrorMsg(err)));
  };

  const rebootServer = serverSlug => {
    dispatch(setGlobalPleaseWaitMsg({ id: serverSlug, model: 'server', action: 'rebooted' }));
    const data = { server_slug: serverSlug };
    ServerService.reboot(data)
      .then(() => dispatch(setGlobalSuccessMsg({ id: serverSlug, model: 'server', action: 'rebooted' })))
      .catch(err => dispatch(setGlobalErrorMsg(err)));
  };

  const syncEWSMCode = server => {
    if (loading) {
      dispatch(setGlobalWarningMsg('Please wait for the previous request to finish.'));
      return;
    }
    setLoading(true);
    const data = {
      server_slug: server.slug
    };
    ServerService.syncEWSMCode(data)
      .then(() =>
        dispatch(
          setGlobalSuccessMsg({
            id: server.slug,
            model: 'server sync EWSM code request',
            action: 'sent',
          }),
        ),
      )
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  };

  const resizeVolume = () => {
    if (loading) {
      dispatch(setGlobalWarningMsg('Please wait for the previous request to finish.'));
      return;
    }
    setLoading(true);
    const data = {
      server_slug: selectedServer.slug,
      new_size: parseInt(newVolumeSize),
    };
    dispatch(resizeServerVolumes(data)).then(() => {
      setLoading(false);
      setModal(false);
    });
  };

  const actions = [
    {
      value: 'Health check',
      onClick: server => openNewTab(env.buildHealthCheckApiEndpoint(server)),
    },
    {
      value: 'Access stats',
      onClick: server => {
        history.push({
          pathname: `servers/${server.slug}/access-stats`,
          state: server,
        });
      },
    },
    {
      value: 'OPcache stats',
      onClick: server => {
        history.push({
          pathname: `servers/${server.slug}/opcache-stats`,
          state: server,
        });
      },
    },
    {
      value: 'Redis stats',
      onClick: server => {
        history.push({
          pathname: `servers/${server.slug}/redis-stats`,
          state: server,
        });
      },
    },
    {
      value: 'Websites',
      onClick: server => {
        history.push({
          pathname: `/websites`,
          search: `&filter=Server:${server.slug}`,
        });
      },
    },
    {
      doHide: () => !UserHelper.isAdmin(),
      value: 'Instances',
      onClick: server => {
        history.push({
          pathname: `servers/${server.slug}/instances`,
          state: server,
        });
      },
    },
    {
      doHide: () => !UserHelper.isAdmin(),
      value: 'PHP Config',
      onClick: server => {
        history.push({
          pathname: `servers/${server.slug}/php-config`,
          state: server,
        });
      },
    },
    {
      doHide: () => !UserHelper.isAdminOrAgent(),
      value: 'Resize volume',
      onClick: server => {
        const sizeInGB = StringHelper.convertBytesToGB(server.diskspace_usage.total); // round up to nearest GB
        setSelectedServerSize(sizeInGB);
        setSelectedServer(server);
        setModal(true);
      },
    },
    {
      doHide: () => !UserHelper.isAdmin(),
      value: 'SSH Key',
      onClick: server => {
        const data = { server_slug: server.slug, file_name: `${server.slug}.pem`, blob: true };
        ServerService.getSSHKey(data).catch(err => dispatch(setGlobalErrorMsg(err)));
      },
    },
    {
      doHide: () => !UserHelper.isAdmin(),
      value: 'Sync EWSM code',
      onClick: syncEWSMCode,
    },
    {
      doHide: () => !UserHelper.isAdmin(),
      value: 'Run tests',
      onClick: server => {
        history.push({
          pathname: '/tools/tests',
          state: server.slug,
        });
      },
    },
    {
      value: 'Start',
      onClick: server => startServer(server.slug)
    },
    {
      value: 'Reboot',
      onClick: server => DialogHelper
        .confirmAction(confirm, 'reboot', server.slug, 'server')
        .then(() => rebootServer(server.slug))
    },
    {
      value: 'Stop',
      onClick: server => DialogHelper
        .confirmAction(confirm, 'stop', server.slug, 'server')
        .then(() => stopServer(server.slug))
    },
    {
      value: 'Delete',
      doHide: () => !UserHelper.isAdmin(),
      onClick: server => DialogHelper
        .confirmDelete(confirm, server.slug, 'server')
        .then(() => handleDeleteServer(server))
    },
  ];
  const headers = [
    JsxHelper.createTableTextHeaderWithCallback('slug', 'Server', '22%', row => {
      const subheader = row.instance_type + (row.is_staging ? ' / staging' : '');
      TableHelper.customizeCellValue(row, 'slug', row.slug + ' ' + subheader);
      return JsxHelper.createTableMultiLineCell({
        header: row.slug,
        subheader,
        headerClass: row.is_staging ? 'alt-font-color' : '',
      });
    }),
    JsxHelper.createTableStatusHeader('status', null, false, '7%'),
    JsxHelper.createTableCopyButtonHeader(dispatch, 'public_ipv4', 'IP Address', '14%'),
    JsxHelper.createTableCopyButtonHeader(dispatch, 'sftp_port', 'SSH Port', '10%', 'primary'),
    JsxHelper.createTableTextHeaderWithCallback(
      'diskspace_usage',
      'Disk Usage',
      '10%',
      row => {
        if (isEmpty(row.diskspace_usage)) {
          TableHelper.customizeCellValue(row, 'diskspace_usage', 0);
          row.custom_diskspace_usage = 'N/A';
          return 'N/A';
        }
        const perc = (100 * (row.diskspace_usage.used / row.diskspace_usage.total)).toFixed(2);
        const color = perc < 70 ? 'success' : perc < 85 ? 'warning' : 'danger';
        const used = StringHelper.convertBytes(row.diskspace_usage.used);
        const total = StringHelper.convertBytes(row.diskspace_usage.total);
        const free = StringHelper.convertBytes(row.diskspace_usage.free);
        const tooltip = `Used ${used} out of ${total} (${free} free).`;
        const id = `du-perc-${row.slug}`;
        TableHelper.customizeCellValue(row, 'diskspace_usage', parseInt(perc));
        return JsxHelper.createBubble({
          id,
          background: color,
          text: `${perc}%`,
          tooltip,
        });
      },
    ),
    JsxHelper.createTableTextHeaderWithCallback(
      'disk_size',
      'Disk Size',
      '12%',
      row => {
        const totalInGB = StringHelper.convertBytesToGB(row.diskspace_usage.total);
        const freeInHuman = StringHelper.convertBytes(row.diskspace_usage.free);
        const freePerc = (100 * (row.diskspace_usage.free / row.diskspace_usage.total)).toFixed(2);
        TableHelper.customizeCellValue(row, 'disk_size', totalInGB);
        return JsxHelper.createTableMultiLineCell({
          header: `${totalInGB} GB`,
          subheaderHTML: <div>
            <span>{freeInHuman} free ({freePerc}%)</span>
            {row.min_free_disk_space > 0 && <span style={{marginTop: '3px'}} className='info-font-color display-block'>{row.min_free_disk_space} GB free required</span>}
          </div>,
        });
      },
    ),
    JsxHelper.createTableTextHeaderWithCallback(
      'total_sites',
      'Websites',
      '12%',
      row => {
        const totalSites = !isEmpty(serversCounts) && !isEmpty(serversCounts[row.slug]) ? serversCounts[row.slug].serversCount : 0;
        const liveSites = !isEmpty(serversCounts) && !isEmpty(serversCounts[row.slug]) ? serversCounts[row.slug].liveSites : 0;
        const livePercnt = totalSites ? ((liveSites / totalSites) * 100).toFixed(2) : 0;
        TableHelper.customizeCellValue(row, 'total_sites', totalSites);
        return JsxHelper.createTableMultiLineCell({
          header: totalSites,
          subheader: `${liveSites} live (${livePercnt}%)`,
        });
      },
    ),
    JsxHelper.createTableActionsHeader(actions, '13%'),
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={JsxHelper.createBreadcrumbs('servers')}>Servers</TitleBar.Title>
        <TitleBar.Actions>
          <Can
            perform='server:create:*'
            yes={() => JsxHelper.createButton({ label: 'Create Server', linkTo: `/servers/create` })}
          />
        </TitleBar.Actions>
      </TitleBar>
      <Content className='main-servers-table'>
        <WPSDataTable columns={headers} body={servers} rowsPerPage={100} />
        {modal && DialogHelper.inputs({
          title: 'Resize Volume',
          confirmBtn: 'Resize',
          icon: 'create',
          iconColor: 'success',
          loading,
          onClose: () => setModal(false),
          onConfirm: handleSubmit(resizeVolume),
            header: <span>You're about to modify the current size of <b>{selectedServerSize} GB</b> for
            the EBS Volumes associated with all the server instances, including the secondary instances.</span>,
          inputs: [{
            label: 'Enter new size in GB',
            name: 'size',
            type: 'number',
            step: '1',
            min: '1',
            value: newVolumeSize,
            required: true,
            onChange: (e) => setNewVolumeSize(e.target.value),
            ref: register({ required: FormHelper.messages.required }),
            errors,
          }]
        })}
      </Content>
    </Fragment>
  );
};

export default ServerIndex;
