import React, { Fragment, useState, useEffect, useRef } from 'react';
import GitHelper from 'helpers/git';
import StringHelper from 'helpers/string';
import JsxHelper from 'helpers/jsx';
import UrlHelper from 'helpers/url';
import UserHelper from 'helpers/user';
import DialogHelper from 'helpers/dialog';
import { useDispatch, useSelector } from 'react-redux';
import useTitle from 'hooks/useTitle';
import useModal from 'hooks/useModal';
import { Content } from 'styles/globalStyles';
import PackageService from 'services/package';
import PackageCard from '../card'
import { createPartnerPackageFromGit } from 'store/partnerPackage/partnerPackageActions';
import { createPrivatePackageFromGit } from 'store/website/websiteActions';
import { userSlug, partnerSelector } from 'store/me/meSelectors';
import { activeWebsite } from 'store/website/websiteSelectors';
import { useHistory } from 'react-router-dom';
import 'components/stepper/stepper.css';

const ConnectGit = ({ type }) => {
  useTitle('Connect via Git');

  const dispatch = useDispatch();
  const history = useHistory();
  const modalDialog = useModal();
  const websiteSlug = UrlHelper.getQueryParam('website');
  const website = useSelector(activeWebsite(websiteSlug));
  const myUserSlug = useSelector(userSlug);
  const myPartner = useSelector(partnerSelector);
  const mounted = useRef(true);
  const [sshLoading, setSshLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [repositoryError, setRepositoryError] = useState(false);
  const [repositoryUrl, setRepositoryUrl] = useState('');
  const [productionBranch, setProductionBranch] = useState('');
  const [sshPublicKey, setSshPublicKey] = useState(false);
  const [sshKeysId, setSshKeysId] = useState(false);
  const [webhookApiUrl, setWebhookApiUrl] = useState(false);
  const [webhookApiKey, setWebhookApiKey] = useState(false);
  const [packageData, setPackageData] = useState(false);
  const [confirmWebhookAdd, setConfirmWebhookAdd] = useState(false);
  const [gitService, setGitService] = useState('bitbucket');

  // ------------------------------
  // STEPPER METHODS
  // ------------------------------

  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepForData, setCurrentStepForData] = useState(false);
  const [disabledStepsData, setDisabledStepsData] = useState([]);
  const stepperRef = useRef();
  const disabledStepsElements = useRef([]);
  const stepToStateColors = useRef([]);

  const initStepper = (step) => {
    let disabledSteps = []; // Starts with index 0 (step 1 = index 0)
    let stepToState = { 1: 'complete', 2: 'complete', 3: 'complete' }

    disabledSteps = createDisabledSteps(step);

    // Set disabled steps
    setDisabledStepsData(disabledSteps);
    disabledStepsElements.current = disabledSteps;
    stepToStateColors.current = stepToState;

    // Initialize steps and go to initial
    stepperSteps[step - 1].onClick();

    // Set initial step at the end of conditions
    updateCurrentStep(step);
  }

  const goToStep = async (step) => initStepper(step);

  const initStep = stepNum => {
    for (var i = 1; i <= 3; i++) {
      const stepIndex = i - 1;
      let step = stepperRef.current.children[0].children[0].children[stepIndex].children[0];
      step.classList.remove('complete-color');
      step.classList.remove('warning-color');
      step.classList.remove('danger-color');
      step.classList.remove('active-color');
      if (stepNum === i) {
        // Color the clicked step with active-color
        step.classList.add('active-color');
      } else if (!disabledStepsElements.current.includes(stepIndex)) {
        // Color the clicked step with the state color
        step.classList.add(`${stepToStateColors.current[i]}-color`);
      }
    }
    setCurrentStepForData(stepNum);
  };

  /* eslint-disable no-unused-vars */
  const [stepperSteps, setStepperSteps] = useState([
    {
      title: 'Set Git Repository',
      onClick: () => initStep(1),
    },
    {
      title: 'Add SSH Keys',
      onClick: () => initStep(2),
    },
    {
      title: 'Review ' + StringHelper.capitalizeFirstLetter(type),
      onClick: () => initStep(3),
    },
    {
      title: 'Add Webhook',
      onClick: () => initStep(4),
    }
  ]);

  const createDisabledSteps = (start) => {
    let steps = [];
    for (let i = start; i <= stepperSteps.length; i++) {
      steps.push(i);
    }
    return steps;
  }

  const updateCurrentStep = newStep => {
    setCurrentStep(newStep + 1);
    setCurrentStepForData(newStep);
  };

  // ------------------------------
  // LIFECYCLE METHODS
  // ------------------------------

  useEffect(() => {
    initStepper(1);
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  // ------------------------------
  // RENDER & HELPER METHODS
  // ------------------------------

  const goBack = () => history.goBack()

  // ------------------------------
  // EVENT HANDLERS
  // ------------------------------

  const onInstallPackage = () => {
    let apiParams = packageData;

    if (UserHelper.isAdminOrAgent()) {
      if (!website) {
        DialogHelper.error(modalDialog, 'Not supported from Admin interface.');
        return;
      }
      apiParams.partner_slug = website.partner_slug;
    } else {
      apiParams.partner_slug = UserHelper.isPartner() ? myUserSlug : myPartner.slug;
    }

    apiParams.repository_url = repositoryUrl;
    apiParams.repository_branch = productionBranch;
    apiParams.ssh_keys_id = sshKeysId;
    apiParams.webhook_api_key = webhookApiKey;

    setLoading(true);

    if (website) {
      apiParams.website_slug = website.slug;
      dispatch(createPrivatePackageFromGit(apiParams))
        .then(goBack).finally(() => setLoading(false));
    } else {
      dispatch(createPartnerPackageFromGit(apiParams))
        .then(goBack).finally(() => setLoading(false));
    }
  }

  const onGenerateKeys = () => {
    setSshLoading(true);
    PackageService.generateGitKeys()
      .then(res => {
        setSshPublicKey(res.ssh_public_key);
        setSshKeysId(res.ssh_keys_id);
        setWebhookApiUrl(res.webhook_api_url);
        setWebhookApiKey(res.webhook_api_key);
        JsxHelper.copyToClipboard(dispatch, res.ssh_public_key, 'SSH key');
      })
      .catch(error => DialogHelper.error(modalDialog, error))
      .finally(() => setSshLoading(false));
  }

  const onConnectRepository = () => {
    setLoading(true);
    const data = {
      repository_url: repositoryUrl,
      repository_branch: productionBranch,
      ssh_keys_id: sshKeysId,
    }

    PackageService.fetchFromGit(data)
      .then(res => {
        setPackageData({
          folder_name: res.FolderName,
          parent_folder_name: res.ParentThemeDir ? res.ParentThemeDir : null,
          display_name: res.Name,
          dir_size: res.DirSizeInBytes,
          local_src: res.LocalSource,
          src: res.Source,
          version: res.Version,
          type: res.Type,
          description: res.Description,
          official_uri: res.ThemeURI || res.PluginURI || res.URI,
          author: res.Author,
          author_uri: res.AuthorURI,
          is_git: true
        });
        goToStep(3);
      })
      .catch(error => DialogHelper.error(modalDialog, error))
      .finally(() => setLoading(false));
  }

  return (
    <Fragment>
      <Content id='connect-git-wizard'>
        <Fragment>
          <div ref={stepperRef} className='stepper'>
            {JsxHelper.createStepper(stepperSteps, currentStep, disabledStepsData)}
          </div>

          {currentStepForData === 1 &&
            (GitHelper.renderSetGitRepositoryStep(
              type,
              gitService,
              setGitService,
              repositoryUrl,
              setRepositoryUrl,
              repositoryError,
              setRepositoryError,
              sshPublicKey,
              productionBranch,
              setProductionBranch,
              loading || sshLoading,
              () => goToStep(2),
            )
          )}

          {currentStepForData === 2 && (
            (GitHelper.renderSetSshKeysStep(
              type,
              gitService,
              dispatch,
              repositoryUrl,
              sshPublicKey,
              onGenerateKeys,
              onConnectRepository,
              sshLoading,
              loading
            ))
          )}

          {currentStepForData === 3 && (
            (GitHelper.renderReviewStep(
              type,
              gitService,
              <div className='ns-steps'><PackageCard fileData={packageData}/></div>,
              () => goToStep(4)
            ))
          )}

          {currentStepForData === 4 && (
            (GitHelper.renderAddWebhookStep(
              type,
              gitService,
              dispatch,
              repositoryUrl,
              sshPublicKey,
              webhookApiUrl,
              confirmWebhookAdd,
              setConfirmWebhookAdd,
              loading,
              onInstallPackage
            ))
          )}
        </Fragment>
      </Content>
    </Fragment>
  );
};

export default ConnectGit;
