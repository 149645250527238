import React, { Fragment, useState, useEffect, useRef } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import JsxHelper from 'helpers/jsx';
import GitHelper from 'helpers/git';
import DialogHelper from 'helpers/dialog';
import PlanHelper from 'helpers/plan';
import ArrayHelper from 'helpers/array';
import WebsiteHelper from 'helpers/website';
import { isUndefined } from 'helpers';
import useTitle from 'hooks/useTitle';
import useModal from 'hooks/useModal';
import WebsiteService from 'services/website';
import WebsiteOptionsTrait from './WebsiteOptionsTrait';
import { Content } from 'styles/globalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { globalRegionsSelector } from 'store/region/regionSelectors';
import { partnerSelector } from 'store/me/meSelectors';
import { customersSelector } from 'store/customer/customerSelectors';
import { customerWebsitePlansSelector, nonAssignedCustomerWebsitePlans } from 'store/customerBilling/customerBillingSelectors';
import { activeSubscriptionsByType, plansByCategoryType } from 'store/billing/billingSelectors';
import { integrationSelector } from 'store/me/meSelectors';
import { WPSForm } from 'styles/layout/forms';

import 'components/stepper/stepper.css';

const WebsiteGitDeploy = () => {
  useTitle('Website Git Deploy');
  const dispatch = useDispatch();
  const history = useHistory();
  const RESOURCE_TYPE = 'WordPress';
  const WPSFORM_STYLE = { maxWidth: '1000px', margin: '40px 0 0 15px' };
  const BREADCRUMBS = JsxHelper.createBreadcrumbs('Git Deploy', 'website');
  const STEPS_NUM = 6;
  const GIT_OPTIONS = {
    auto_manage_wp_core: true,
    auto_updater_disabled: false,
    overwrite_local_changes: true,
  }
  const mounted = useRef(true);
  const modalDialog = useModal();
  const [modal, setModal] = useState(false);
  const regionSelectOptions = useSelector(globalRegionsSelector);
  const partner = useSelector(partnerSelector);
  const partnerWebsitePlans = useSelector(plansByCategoryType('website'));
  const customers = useSelector(customersSelector);
  const customerPlans = useSelector(customerWebsitePlansSelector);
  const nonAssignedPlans = useSelector(nonAssignedCustomerWebsitePlans);
  const websiteActiveSubscriptions = useSelector(activeSubscriptionsByType('website'));
  const integrations = useSelector(integrationSelector);
  const isStripeConnected = integrations.stripe ? true : false;
  const [customerDetails, setCustomerDetails] = useState(WebsiteOptionsTrait.EMPTY_CUSTOMER_DETAILS);
  const [customerLoading, setLoadingCustomer] = useState(false);
  const { register, handleSubmit, errors, triggerValidation } = useForm({ reValidateMode: 'onSubmit' });
  const [websiteExist, setWebsiteExist] = useState(false);
  const [websiteExistError, setWebsiteExistError] = useState(false);
  const [sshLoading, setSshLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [repositoryError, setRepositoryError] = useState(false);
  const [repositoryUrl, setRepositoryUrl] = useState('');
  const [repositoryBranch, setRepositoryBranch] = useState('');
  const [sshPublicKey, setSshPublicKey] = useState(false);
  const [sshKeysId, setSshKeysId] = useState(false);
  const [webhookApiUrl, setWebhookApiUrl] = useState(false);
  const [webhookApiKey, setWebhookApiKey] = useState(false);
  const [confirmWebhookAdd, setConfirmWebhookAdd] = useState(false);
  const [customersTrigger, setCustomersTrigger] = useState(1);
  const [partnerPlans, setPartnerPlans] = useState([]);
  const [gitService, setGitService] = useState('bitbucket');
  const [customerPlansList, setCustomerPlansList] = useState();
  const [website, setWebsite] = useState({...WebsiteOptionsTrait.initWebsiteData(
    partner,
    regionSelectOptions,
    customerPlans
  ), ...GIT_OPTIONS});

  // ------------------------------
  // LIFECYCLE METHODS
  // ------------------------------

  useEffect(() => {
    const _partnerPlans = PlanHelper.filterSubscribableWebsitePlans(partnerWebsitePlans, websiteActiveSubscriptions);
    setPartnerPlans(_partnerPlans);
    initStepper(1);
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    WebsiteOptionsTrait.onPartnerPlanSelection(
      website.partner_plan_slug,
      websiteActiveSubscriptions,
      partnerPlans,
      null,
      setWebsite
    );
    // eslint-disable-next-line
  }, [website.partner_plan_slug, partnerPlans]);
  
  useEffect(() => {
    if (website.customer_slug) {
      // Selected customer plans
      const selectedCustomerPlans = customerPlans.filter(
        plan => plan.assignee_slug === website.customer_slug,
      );
      const allPlans = [...nonAssignedPlans, ...selectedCustomerPlans];
      setCustomerPlansList(allPlans);
    } else {
      setCustomerPlansList(nonAssignedPlans);
    }
  }, [customerPlans, nonAssignedPlans, website.customer_slug]);

  // ------------------------------
  // STEPPER METHODS
  // ------------------------------

  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepForData, setCurrentStepForData] = useState(false);
  const [disabledStepsData, setDisabledStepsData] = useState([]);
  const stepperRef = useRef();
  const disabledStepsElements = useRef([]);

  const doHideCustomerStep = () => !isStripeConnected;

  const initStepper = (step) => {
    // Set disabled steps
    let disabledSteps = [];
    for (let i = step; i <= stepperSteps.length; i++) {
      disabledSteps.push(i);
    }
    setDisabledStepsData(disabledSteps);
    disabledStepsElements.current = disabledSteps;

    // Initialize steps and go to initial
    stepperSteps[step - 1].onClick();

    // Set initial step at the end of conditions
    setCurrentStep(step + 1);
    setCurrentStepForData(step);
  }

  const goToStep = async (step) => initStepper(step);

  const initStep = stepNum => {
    const stepsNum = STEPS_NUM - (doHideCustomerStep() ? 1 : 0);
    WebsiteOptionsTrait.initStep(stepperRef, disabledStepsElements, stepNum, stepsNum, setCurrentStepForData);
  };

  /* eslint-disable no-unused-vars */
  const [stepperSteps, setStepperSteps] = useState([
    {
      title: 'Choose Plan',
      onClick: () => initStep(1),
    },
    {
      title: 'Git Repository',
      onClick: () => initStep(2),
    },
    {
      title: 'Add SSH Keys',
      onClick: () => initStep(3),
    },
    {
      title: 'Add Webhook',
      onClick: () => initStep(4),
    },
    !doHideCustomerStep() && {
      title: 'Assign Client',
      onClick: () => initStep(5),
    },
    {
      title: 'WordPress Setup',
      onClick: () => initStep(doHideCustomerStep() ? 5 : 6),
    }
  ].filter(Boolean))

  const handleCustomerNextStep = () => goToStep(6);;

  // ------------------------------
  // HANDLER METHODS
  // ------------------------------

  const onGenerateKeys = () => {
    setSshLoading(true);
    WebsiteService.generateGitKeys()
      .then(res => {
        setSshPublicKey(res.ssh_public_key);
        setSshKeysId(res.ssh_keys_id);
        setWebhookApiUrl(res.webhook_api_url);
        setWebhookApiKey(res.webhook_api_key);
        JsxHelper.copyToClipboard(dispatch, res.ssh_public_key, 'SSH key');
      }).catch(error => DialogHelper.error(modalDialog, error))
      .finally(() => setSshLoading(false));
  }

  const onConnectRepository = async () => {
    setLoading(true);
    const data = {
      repository_url: repositoryUrl,
      repository_branch: repositoryBranch,
      ssh_keys_id: sshKeysId,
    }
    WebsiteService.isGitClonable(data)
      .then(() => goToStep(4))
      .catch(error => DialogHelper.error(modalDialog, error))
      .finally(() => setLoading(false));
  }

  const handleOnChange = e => {
    const { name } = e.target;
    let value = '';
    if (name === 'region_slug') {
      value = e.target.id;
    } else if (name === 'website_slug') {
      value = e.target.value.toLowerCase();
    } else if (e.target.type === 'checkbox' || !isUndefined(GIT_OPTIONS[name])) {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }
    setWebsite(prevState => ({ ...prevState, [name]: value }));
  };

  const onDeploySite = () => WebsiteOptionsTrait.onDeployGit({
    ...website,
    ssh_keys_id: sshKeysId,
    webhook_api_key: webhookApiKey,
    repository_url: repositoryUrl,
    repository_branch: repositoryBranch,
    auto_manage_wp_core: website.auto_manage_wp_core,
    auto_updater_disabled: website.auto_updater_disabled,
    overwrite_local_changes: website.overwrite_local_changes,
  }, history, dispatch, setLoading)

  const handleWebsiteIdOnBlur = () => {
    const onNotExists = () => initStep(2);
    WebsiteOptionsTrait.onWebsiteIdBlur(website, setLoading, loading, setWebsiteExistError, setWebsiteExist, onNotExists, modalDialog);
  };

  // ------------------------------
  // RENDER METHODS
  // ------------------------------

  const renderKnowledgeBaseLink = () => {
    return '';
  }

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={BREADCRUMBS}>Git Deploy</TitleBar.Title>
        <TitleBar.Actions>{JsxHelper.createBackButton()}</TitleBar.Actions>
      </TitleBar>
      <Content id='connect-git-wizard'>

        <div ref={stepperRef} className='stepper' style={{width: `${doHideCustomerStep() ? 75 : 80}%`}}>
          {JsxHelper.createStepper(stepperSteps, currentStep, disabledStepsData)}
        </div>

        {currentStepForData === 1 && (
          <WPSForm style={WPSFORM_STYLE}>
            {WebsiteOptionsTrait.renderRegionsAndZones(handleOnChange)}
            <WPSForm.Row className='display-block' style={{ marginLeft: '2px' }}>
              {WebsiteOptionsTrait.renderEnterAppID(website, websiteExist, websiteExistError, handleOnChange)}
              {WebsiteOptionsTrait.renderPricingPlansForPartner(website, partnerPlans, handleOnChange)}
            </WPSForm.Row>
            {WebsiteOptionsTrait.renderLightButton('Next', handleSubmit(handleWebsiteIdOnBlur), loading)}
          </WPSForm>
        )}

        {currentStepForData === 2 &&
          (GitHelper.renderSetGitRepositoryStep(
            RESOURCE_TYPE,
            gitService,
            setGitService,
            repositoryUrl,
            setRepositoryUrl,
            repositoryError,
            setRepositoryError,
            sshPublicKey,
            repositoryBranch,
            setRepositoryBranch,
            loading || sshLoading,
            () => goToStep(3),
          )
        )}

        {currentStepForData === 3 &&
          (GitHelper.renderSetSshKeysStep(
            RESOURCE_TYPE,
            gitService,
            dispatch,
            repositoryUrl,
            sshPublicKey,
            onGenerateKeys,
            onConnectRepository,
            sshLoading,
            loading
          )
        )}

        {currentStepForData === 4 && (
          (GitHelper.renderAddWebhookStep(
            RESOURCE_TYPE,
            gitService,
            dispatch,
            repositoryUrl,
            sshPublicKey,
            webhookApiUrl,
            confirmWebhookAdd,
            setConfirmWebhookAdd,
            loading,
            null, // No button
            () => goToStep(5),
          ))
        )}

        {currentStepForData === 5  && !doHideCustomerStep() && (
          <WPSForm style={WPSFORM_STYLE}>
            {WebsiteOptionsTrait.renderAssignClient(website, customers, customersTrigger, handleOnChange, setModal)}
            {website.customer_slug ? WebsiteOptionsTrait.renderAgencyPlans(website, customerPlansList, handleOnChange) : null}
            {WebsiteOptionsTrait.renderLightButton('Next', handleSubmit(handleCustomerNextStep), loading)}
          </WPSForm>
        )}

        {currentStepForData === (doHideCustomerStep() ? 5 : 6) && (
          <WPSForm style={WPSFORM_STYLE}>
            <WPSForm.Fieldset
              className='display-block'
              style={{ marginTop: '0px', marginLeft: '2px' }}>
              <legend>Admin User</legend>
              {WebsiteOptionsTrait.renderTextInputRowItem('Admin User', 'wp_admin_user', website, errors, register, true, handleOnChange)}
              {WebsiteOptionsTrait.renderTextInputRowItem('Admin Email', 'wp_admin_user_email', website, errors, register, true, handleOnChange)}
            </WPSForm.Fieldset>
            <WPSForm.Fieldset className='display-block' style={{ marginLeft: '2px' }}>
              <legend>Settings</legend>
              {WebsiteOptionsTrait.renderSelectInputRowItem('PHP Version', 'php_version', website, ArrayHelper.buildSelectOptions(WebsiteHelper.phpVersions, 'name', 'value'), true, handleOnChange)}
              {WebsiteOptionsTrait.renderTextInputRowItem('Site Title', 'site_title', website, errors, register, true, handleOnChange)}
              <WPSForm.Row>
                {JsxHelper.createCheckbox({
                  class: 'margin-top-24',
                  name: 'auto_manage_wp_core',
                  label: 'Use Managed WordPress Core',
                  checked: website.auto_manage_wp_core,
                  tooltip: 'Enable this option to let the platform handle WordPress core updates independently. The core files in your Git repository will be ignored.',
                  onChange: handleOnChange
                })}
              </WPSForm.Row>
              <WPSForm.Row>
                {JsxHelper.createCheckbox({
                  class: 'margin-top-12',
                  name: 'auto_updater_disabled',
                  label: 'Disable Auto-Updater',
                  checked: website.auto_updater_disabled,
                  tooltip: 'Enable this option to disable the Auto-Updater for this website.',
                  onChange: handleOnChange
                })}
              </WPSForm.Row>
              <WPSForm.Row>
                {JsxHelper.createCheckbox({
                  class: 'margin-top-12',
                  name: 'overwrite_local_changes',
                  label: 'Overwrite Local Changes',
                  checked: website.overwrite_local_changes,
                  tooltip: 'Enable this option to overwrite any changes made to the website files with the ones from the Git repository.',
                  onChange: handleOnChange
                })}
              </WPSForm.Row>
              <WPSForm.Row>
                {JsxHelper.createWarningBox('The wp-config.php file will always be overwritten with the platform\'s version.')}
              </WPSForm.Row>
            </WPSForm.Fieldset>
            {WebsiteOptionsTrait.renderLightButton('Create Site', handleSubmit(onDeploySite), loading)}
          </WPSForm>
        )}
      {modal && WebsiteOptionsTrait.createNewClientModal(
        loading,
        customerLoading,
        customerDetails,
        errors,
        register,
        setModal,
        handleSubmit(() => WebsiteOptionsTrait.createCustomer(
          customerDetails,
          setModal,
          setCustomersTrigger,
          setLoadingCustomer,
          triggerValidation,
          setWebsite,
          dispatch
        )),
        setCustomerDetails
      )}
      </Content>
    </Fragment>
  );
};

export default WebsiteGitDeploy;
