// CSS variables for WPS Theme, globally accessible with Theme Provider at index.js

import { lighten } from 'polished';

export default {
  // Only include text colors
  color: {
    black: '#000',
    white: '#FFF',
    inactive: '#5887B0',
    primary: '#2B68CD',
    secondary: '#3aa2f6',
    light: '#FFF',
    dark: '#000',
    danger: '#ec4343',
    success: '#1bba5c',
    warning: '#fc8121',
    info: '#232aa3',
    body: '#192634',
    link: '#458FF5',
    placeholder: '#5887B0',
    inactive2: '#AAB9E5',
    alternative: '#9E6DFF',
    disabled: '#808080',
    card: '#398eff',
    lightBlue: '#e6f7ff',
    shadeBlue: '#1a5276',
    lightOrange: '#fff4e5',
    darkOrange: '#9f6000',
    lightRed: '#ffdddd',
    lightGreen: '#f0fff0',
    darkGreen: '#259325',
    checkboxOn: '#2229a1',
    checkboxOff: 'lightgray',
    checkboxDisabled: '#f8f8f8',
  },
  // Refer to global backgrounds for all elements
  background: {
    body: '#FAFBFE',
    primary: 'linear-gradient(90deg, rgba(12,182,223,1) 0%, rgba(62,195,255,1) 100%)',
    primaryHover: lighten(0.27, '#676AFF'),
    success: 'linear-gradient(to left,rgba(101,204,113,1) 0%,rgb(21 195 91) 100%)',
    info: 'linear-gradient(90deg, rgba(35,42,163,1) 0%, rgba(29,36,138,1) 100%)',
    warning: 'linear-gradient(90deg, rgba(255,103,0,1) 0%, rgba(250,150,60,1) 100%)',
    danger: 'linear-gradient(90deg, rgba(200,1,1,1) 0%, rgba(250,60,60,1) 100%)',
    light: '#FFF',
    overlay: 'rgba(0,0,0,0.6)',
    disabled: 'linear-gradient(90deg, rgba(202,202,202,1) 0%, rgba(225,225,225,1) 100%)',
    alternative: 'linear-gradient(90deg, rgba(146,2,255,1) 0%, rgba(159,109,255,1) 100%)',
    toggle: 'linear-gradient(90deg, rgba(41,95,171,1) 0%, rgba(61,160,255,1) 100%)',
    success2: 'rgb(107,197,187)',
    sidebar2: 'linear-gradient(to bottom, #53b3ff, #094aff)',
    example: '#CACACA',
  },
  // Borders, outlines, scroll bars
  border: {
    primary: '#A8B5D9',
    light: '#CACACA',
    radius: '5px',
    scrollBar: '#B5D5F1',
    card: '#e0e9ff',
    uploader: '#eaeaea',
    orange: '#ffa500',
    mediumBlue: '#3498db',
    red: '#ff0000',
  },
  table: {
    oddRows: '#F0F4FC',
  },
};
