import React, { useState, Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { WPSForm } from 'styles/layout/forms';
import { useSelector, useDispatch } from 'react-redux';
import { globalRegionsSelector } from 'store/region/regionSelectors';
import { customersSelector } from 'store/customer/customerSelectors';
import { activeSubscriptionsByType, activeSubscriptions, plansByCategoryType } from 'store/billing/billingSelectors';
import { customerWebsitePlansSelector, nonAssignedCustomerWebsitePlans } from 'store/customerBilling/customerBillingSelectors';
import { isEmpty, isEmptyOrNull } from 'helpers';
import UserHelper from 'helpers/user';
import WebsiteHelper from 'helpers/website';
import PlanHelper from 'helpers/plan';
import { useRef } from 'react';
import useTitle from 'hooks/useTitle';
import { partnersSelector } from 'store/user/userSelectors';
import { integrationSelector, partnerSelector } from 'store/me/meSelectors';
import JsxHelper from 'helpers/jsx';
import { sharedServersSelector } from 'store/server/serverSelectors';
import DialogHelper from 'helpers/dialog';
import useModal from 'hooks/useModal';
import WebsiteOptionsTrait from './WebsiteOptionsTrait';
import { websitesListSelector, websitesSelector } from 'store/website/websiteSelectors';
import { OrderedList } from 'styles/layout/lists';
import 'components/stepper/stepper.css';
import { useHistory } from 'react-router-dom';

const WebsiteClone = () => {
  useTitle('Website Clone');
  const dispatch = useDispatch();
  const modalDialog = useModal();
  const history = useHistory();
  const stepperCreateSiteRef = useRef();
  const STEPS_NUM = 3;
  const { register, handleSubmit, errors, triggerValidation } = useForm({ reValidateMode: 'onSubmit' });
  const [modal, setModal] = useState(false);
  const payAsYouGoServers = useSelector(sharedServersSelector);
  const websiteOptions = useSelector(websitesListSelector);
  const integrations = useSelector(integrationSelector);
  const isStripeConnected = integrations.stripe ? true : false;
  const regionSelectOptions = useSelector(globalRegionsSelector);
  const partner = useSelector(partnerSelector);
  const partnerWebsitePlans = useSelector(plansByCategoryType('website'));
  const customers = useSelector(customersSelector);
  const customerPlans = useSelector(customerWebsitePlansSelector);
  const nonAssignedPlans = useSelector(nonAssignedCustomerWebsitePlans);
  const websiteActiveSubscriptions = useSelector(activeSubscriptionsByType('website'));
  const partnersSubscriptions = useSelector(activeSubscriptions());
  const [partnerPlans, setPartnerPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(false);
  const partners = useSelector(partnersSelector);
  const defaultWebsiteData = WebsiteOptionsTrait.initWebsiteData(partner, regionSelectOptions, customerPlans);
  const websites = useSelector(websitesSelector);

  // Admin: select the available partner plans when a partner is selected
  const initSelectedPartnerInputs = partnerSlug => {
    // Find partner
    const partner = partners.find(p => p.slug === partnerSlug);
    if (isEmptyOrNull(partner)) {
      setPartnerPlans([]);
      return;
    }
    // Get all partner subscriptions
    const partnerSubscriptions = partnersSubscriptions ? partnersSubscriptions.filter(
      s => s.subscriber_slug === partnerSlug,
    ) : [];
    // Filter subscribable plans
    const _partnerPlans = PlanHelper.filterSubscribableWebsitePlans(partnerWebsitePlans, partnerSubscriptions, partnerSlug);
    setPartnerPlans(_partnerPlans);
  };

  // Fetch all partners subscriptions on component mount.
  useEffect(() => {
    if (UserHelper.isAdminOrAgent()) {
      WebsiteOptionsTrait.fetchPartnerSubscriptionsForAdmins(partnersSubscriptions, dispatch);
    } else {
      const _partnerPlans = PlanHelper.filterSubscribableWebsitePlans(partnerWebsitePlans, websiteActiveSubscriptions);
      setPartnerPlans(_partnerPlans);
    }
    // eslint-disable-next-line
  }, []);

  const doHideCustomerStep = () => UserHelper.isAdminOrAgent() || !isStripeConnected

  const initStep = stepNum => {
    const stepsCount = STEPS_NUM - (doHideCustomerStep() ? 1 : 0);
    WebsiteOptionsTrait.initStep(stepperCreateSiteRef, refDisabledCreateSiteSteps, stepNum, stepsCount, setCreateSiteCurrentStepForData);
  };

  /* eslint-disable no-unused-vars */
  const [createSiteStepperSteps, setCreateSiteStepperSteps] = useState(
    [
      {
        title: 'Website Settings',
        onClick: () => initStep(1),
      },
      !doHideCustomerStep() && {
        title: 'Assign Client',
        onClick: () => initStep(2),
      },
      {
        title: 'Choose Plan',
        onClick: () => initStep(doHideCustomerStep() ? 2 : 3),
      },
    ].filter(Boolean)
  );
  const [createSiteCurrentStep, setCreateSiteCurrentStep] = useState(1);
  const [createSiteCurrentStepForData, setCreateSiteCurrentStepForData] = useState(1);
  const CustomerSteps = doHideCustomerStep() ? [1] : [1, 2];
  const [disabledCreateSiteStepsData, setDisabledCreateSiteStepsData] = useState([ ...CustomerSteps ]);
  const refDisabledCreateSiteSteps = useRef([...CustomerSteps]);
  const [website, setWebsite] = useState(defaultWebsiteData);
  const [customerPlansList, setCustomerPlansList] = useState();
  const [websiteExist, setWebsiteExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [websiteExistError, setWebsiteExistError] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(WebsiteOptionsTrait.EMPTY_CUSTOMER_DETAILS);
  const [customerLoading, setLoadingCustomer] = useState(false);
  const [customersTrigger, setCustomersTrigger] = useState(1);

  // Set the free trial notice for partner whenever a new subscription is set
  useEffect(() => {
    WebsiteOptionsTrait.onPartnerPlanSelection(
      website.partner_plan_slug,
      websiteActiveSubscriptions,
      partnerPlans,
      setSelectedPlan,
      setWebsite
    );
    // eslint-disable-next-line
  }, [website.partner_plan_slug, partnerPlans]);

  useEffect(() => {
    if (!UserHelper.isAdminOrAgent()) {
      if (website.customer_slug) {
        // Selected customer plans
        const selectedCustomerPlans = customerPlans.filter(
          plan => plan.assignee_slug === website.customer_slug,
        );
        const allPlans = [...nonAssignedPlans, ...selectedCustomerPlans];
        setCustomerPlansList(allPlans);
      } else {
        setCustomerPlansList(nonAssignedPlans);
      }
    }
  }, [customerPlans, nonAssignedPlans, website.customer_slug]);

  const handleOnChange = e => {
    const { name } = e.target;
    let value = '';
    if (name === 'partner_slug') {
      value = e.target.value;
      initSelectedPartnerInputs(value);
    } else if (name === 'region_slug') {
      value = e.target.id;
    } else if (name === 'website_slug') {
      value = e.target.value.toLowerCase();
    } else if (name === 'src_website_slug') {
      value = e.target.value.toLowerCase();
      if (!website.website_slug || website.website_slug.endsWith('-clone')) {
        setWebsite(prev => ({ ...prev, website_slug: `${value}-clone`  }));
      }
    } else {
      value = e.target.value;
    }
    setWebsite(prev => ({ ...prev, [name]: value }));
  };

  const onDeploySite = () => WebsiteOptionsTrait.onClone(websiteExist, website, history, dispatch, setLoading);

  // Check if website exists on input Blur (focusOut)
  const handleWebsiteIdOnBlur = () => {
    const onNotExists = (_website) => {
      if (!isEmpty(refDisabledCreateSiteSteps.current)) {
        if (!doHideCustomerStep()) {
          changeCreateSiteSteps(2);
          refDisabledCreateSiteSteps.current = [2];
          setCreateSiteCurrentStepForData(2);
          setDisabledCreateSiteStepsData([2]);
        } else {
          changeCreateSiteSteps(2);
          refDisabledCreateSiteSteps.current = [];
          setDisabledCreateSiteStepsData([]);
          setCreateSiteCurrentStepForData(2);
        }
      }
      WebsiteOptionsTrait.displayWarningIfOriginHasPremiumConfig(_website.src_website_slug, websites, modalDialog);
      initStep(2);
    };
    WebsiteOptionsTrait.onWebsiteIdBlur(website, setLoading, loading, setWebsiteExistError, setWebsiteExist, onNotExists);
  };

  const handleCreateSiteSecondStep = () => {
    const slugTest = WebsiteHelper.isValidSlug(website.website_slug);
    if (!slugTest.valid) {
      setWebsiteExistError(slugTest.error);
      setLoading(false);
      DialogHelper.error(modalDialog, slugTest.error);
    } else {
      setLoading(true);
      handleWebsiteIdOnBlur();
    }
  };

  const handleCreateSiteThirdStep = () => {
    changeCreateSiteSteps(3);
    refDisabledCreateSiteSteps.current = [];
    setDisabledCreateSiteStepsData([]);
    setCreateSiteCurrentStepForData(3);
    initStep(3);
  };

  const changeCreateSiteSteps = newIndex => {
    setCreateSiteCurrentStep(newIndex);
    let data = disabledCreateSiteStepsData;
    data.push(createSiteCurrentStep - 1);
    let fitleredSteps = data.filter(el => el !== newIndex - 1);
    refDisabledCreateSiteSteps.current = fitleredSteps;
    setDisabledCreateSiteStepsData(fitleredSteps);
  };

  const breadcrumbs = JsxHelper.createBreadcrumbs('Clone Website', 'website');

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Clone Website</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content style={{ position: 'relative' }}>
        <div
          className='create-site-stepper'
          style={doHideCustomerStep() ? { left: '-85px' } : {}}
          ref={stepperCreateSiteRef}>
          {JsxHelper.createStepper(createSiteStepperSteps, createSiteCurrentStep, disabledCreateSiteStepsData)}
        </div>
        <WPSForm
          onSubmit={handleSubmit(onDeploySite)}
          noValidate
          style={{ maxWidth: '1000px', marginTop: '130px' }}>
          {createSiteCurrentStepForData === 1 && (
            <Fragment>
              <WPSForm.Fieldset className='display-block' style={{ marginLeft: '2px', marginTop: '0px' }}>
                <legend>Important Notes</legend>
                <WPSForm.Row>
                  <OrderedList className='ol-instructions'>
                    <li>
                      <span>1.</span>
                      Settings for PHP, Nginx, WP Config, CRON, and Object Cache won't be copied; you can modify them after cloning.
                    </li>
                    <li>
                      <span>2.</span>
                      The same PHP version will be used for the new site to avoid compatibility issues; you can change it later.
                    </li>
                    <li>
                      <span>3.</span>
                      During the database copy process, old URLs are automatically replaced with new ones, including CDN links.
                    </li>
                    <li>
                      <span>4.</span>
                      The Media Library gets copied, but it won't sync automatically with S3; you'll need to handle this manually.
                    </li>
                    <li>
                      <span>5.</span>
                      For staging sites, use the Staging feature for an optimized development environment without creating a new subscription.
                    </li>
                  </OrderedList>
                </WPSForm.Row>
              </WPSForm.Fieldset>
              <WPSForm.Fieldset className='display-block' style={{ marginLeft: '2px' }}>
                <legend>Origin Site</legend>
                {WebsiteOptionsTrait.renderSelectInputRowItem('Select origin site', 'src_website_slug', website, websiteOptions, true, handleOnChange)}
              </WPSForm.Fieldset>
              {WebsiteOptionsTrait.renderEnterAppID(website, websiteExist, websiteExistError, handleOnChange)}
              {WebsiteOptionsTrait.renderLightButton('Next', handleSubmit(handleCreateSiteSecondStep), loading, !website.src_website_slug || !website.website_slug)}
            </Fragment>
          )}
          {createSiteCurrentStepForData === 2 && !doHideCustomerStep() && (
            <Fragment>
              {!UserHelper.isAdminOrAgent() && (
                <Fragment>
                  {WebsiteOptionsTrait.renderAssignClient(website, customers, customersTrigger, handleOnChange, setModal)}
                  {website.customer_slug ? WebsiteOptionsTrait.renderAgencyPlans(website, customerPlansList, handleOnChange) : null}
                </Fragment>
              )}
              {WebsiteOptionsTrait.renderLightButton('Next', handleSubmit(handleCreateSiteThirdStep), loading)}
            </Fragment>
          )}
          {(createSiteCurrentStepForData === 3 || (createSiteCurrentStepForData === 2 && doHideCustomerStep())) && (
            <Fragment>
              {WebsiteOptionsTrait.renderRegionsAndZones(handleOnChange)}
              <WPSForm.Row className='display-block' style={{ marginLeft: '2px' }}>
                {WebsiteOptionsTrait.renderSelectPartnerForAdmins(website, partners, errors, register, handleOnChange)}
                {WebsiteOptionsTrait.renderPricingPlansForPartner(website, partnerPlans, handleOnChange)}
                {WebsiteOptionsTrait.renderPricingPlansForAdmins(website, partnerPlans, handleOnChange)}
                {WebsiteOptionsTrait.renderServersForAdmins(website, payAsYouGoServers, selectedPlan, register, handleOnChange, websites)}
              </WPSForm.Row>
              {WebsiteOptionsTrait.renderLightButton('Clone Site', handleSubmit(onDeploySite), loading)}
            </Fragment>
          )}
        </WPSForm>
      </Content>
      {modal && WebsiteOptionsTrait.createNewClientModal(
        loading,
        customerLoading,
        customerDetails,
        errors,
        register,
        setModal,
        handleSubmit(() => WebsiteOptionsTrait.createCustomer(
          customerDetails,
          setModal,
          setCustomersTrigger,
          setLoadingCustomer,
          triggerValidation,
          setWebsite,
          dispatch
        )),
        setCustomerDetails
      )}
    </Fragment>
  );
};

export default WebsiteClone;
