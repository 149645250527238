import store from '../store';
import rules from '../accessControl';
import StringHelper from './string';
import JsxHelper from './jsx';  

/**
 * Returns the logged-in user object.
 *
 * @return {Object}
 */
const getMe = () => {
  const state = store.getState();
  return state.me;
};

/**
 * Returns whether the user has the specified integration.
 * 
 * @param {string} integration
 * @return {boolean}
 */
const hasIntegration = (integration) => {
  const integrations = getMe().integrations;
  return integrations && integrations[integration];
}

/**
 * Returns the logged-in user's email.
 *
 * @return {string}
 */
const getEmail = () => {
  return getMe().email;
};

/**
 * Returns the logged-in user's first name.
 * 
 * @return {string}
 */
const getFirstName = () => {
  return getMe().first_name;
};

/**
 * Returns the logged-in user's display name.
 * 
 * @return {string}
 */
const getDisplayName = () => {
  return getMe().display_name;
};

/**
 * Returns the logged-in user's role.
 *
 * @return {string}
 */
const getRole = () => {
  return getMe().role;
};

/**
 * Checks if the logged-in user is admin.
 *
 * @return {boolean}
 */
const isAdmin = () => {
  return getRole() === 'admin';
};

/**
 * Checks if the logged-in user is agent.
 *
 * @return {boolean}
 */
const isAgent = () => {
  return getRole() === 'agent';
};

/**
 * Checks if the logged-in user is admin or agent.
 * 
 * @return {boolean}
 */
const isAdminOrAgent = () => {
  return isAdmin() || isAgent();
};

/**
 * Checks if the logged-in user has access to beta features.
 * 
 * @return {boolean}
 */
const hasAccessToBetaFeatures = () => {
  return  isAdminOrAgent() ||
          (getEmail() || '').includes('nizar@atozmarketing.com.au') ||
          (getEmail() || '').includes('tfs@khoury.mobi') ||
          (getEmail() || '').includes('emmanuel@heywpstaq.com') ||
          (getEmail() || '').includes('digitalengine.com.au');
}

/**
 * Checks if the logged-in user is partner.
 *
 * @return {boolean}
 */
const isPartner = () => {
  return getRole() === 'partner';
};

/**
 * Checks if the logged-in user is employee.
 *
 * @return {boolean}
 */
const isEmployee = () => {
  return getRole() === 'employee';
};

/**
 * Checks if the logged-in user is partner or employee.
 * 
 * @return {boolean}
 */
const isPartnerOrEmployee = () => {
  return isPartner() || isEmployee();
}

/**
 * Checks if the logged-in user is customer.
 *
 * @return {boolean}
 */
const isCustomer = () => {
  return getRole() === 'customer';
};

/**
 * Returns the custom WP admin user.
 * 
 * @return {string}
 */
const getCustomWPAdminUser = () => {
  return getMe().custom_wp_admin_user;
};

/**
 * Returns the full permissions.
 *
 * @return {Array}
 */
const fullPermissions = () => {
  let perms = [];
  ['website', 'dns-zone', 'client-pdf-report', 'payment-method', 'partner-package'].forEach(type => {
    perms = perms.concat([ `${type}:read:*`, `${type}:update:*`, `${type}:delete:*`, `${type}:create:*` ]);
  });
  return perms;
}

/**
 * Returns the role color name.
 * 
 * @return {string}
 */
const getRoleColor = (role, user) => {
  const map = {
    // role
    admin: 'alternative',
    partner: 'primary',
    agent: 'success2',
    employee: 'overlay',
    customer: 'info',
    // sub_role
    team_member: 'alternative',
    agency_admin: 'primary',
    third_party: 'success2',
  };
  if (!role) {
    window.logHelper.error('Role is not defined.', user);
    return 'primary';
  }
  role = role.toLowerCase();
  if (!map[role]) {
    window.logHelper.error(`Role ${role} has no corresponding color.`, user);
    return 'primary';
  }
  return map[role];
}

/**
 * Determine whether the user has one of the specified permissions.
 *
 * @return {boolean}
 */
const hasPermissions = (permissions) => {
  // Support for arrays/string
  if (typeof permissions === 'string' || permissions instanceof String) {
    permissions = permissions.split(',')
  }
  // Check permissions by role
  const role = getRole();
  const staticPermissions = rules[role] ? rules[role].static : [];
  for (const p of permissions) {
    if (_validatePermission(p, staticPermissions)) {
      return true;
    }
  }
  // Otherwise; check by user assigned permissions
  const assignedPermissions = (getMe().permissions || []).map(p => `${p.type}:${p.action}:${p.scope}`);
  for (const p of permissions) {
    if (_validatePermission(p, assignedPermissions)) {
      return true;
    }
  }
  return false;
}

/**
 * Determine whether the has the specified permissions.
 *
 * @return {boolean}
 */
const _validatePermission = (p, list) => {
  // Check if exact permission is included
  if (list.includes(p)) {
    return true;
  }
  // Check if the user has a global permission if a local/scopeless provided
  if (!p.endsWith(':*')) {
    const pParts = p.split(':');
    const globalP = `${pParts[0]}:${pParts[1]}:*`;
    if (list.includes(globalP)) {
      return true;
    }
  }
  // Otherwise; return false
  return false;
}

/**
 * Renders activity table headers.
 * 
 * @return {Array}
 */
const getActivityTableHeaders = (handleCellClick, timezone, excludeUser) => [
  JsxHelper.createTableTimeHeader('created_at', timezone),
  !excludeUser ? JsxHelper.createTableTitleHeader('user_display', 'User', '20%', 'user_email') : null,
  JsxHelper.createTableTextHeaderWithCallback('title', 'Action', '15%', (row) => {
    if (row.title) {
      return StringHelper.toText(row.title);
    } else if (row.function) {
      return StringHelper.toText(row.function);
    } 
    return 'Unknown';
  }),
  JsxHelper.createTableStatusHeader(),
  {
    name: 'Payload',
    selector: 'payload',
    width: '8%',
    cell: row => JsxHelper.createIcon({
      icon: 'expand',
      color: 'info',
      customClass: 'margin-left-25-perc',
      onClick: () => handleCellClick('Payload', row.payload)
    })
  },
  {
    name: 'Output',
    selector: 'output',
    width: '8%',
    cell: row => JsxHelper.createIcon({
      icon: 'expand',
      color: 'info',
      customClass: 'margin-left-25-perc',
      onClick: () => handleCellClick('Output', row.output)
    })
  },
  {
    name: 'Duration',
    selector: 'duration_s',
    width: '20%',
    cell: row => StringHelper.secondsToStr(row.duration_s),
  },
].filter(Boolean);

const UserHelper = {
  isAdmin,
  isAgent,
  isPartner,
  isEmployee,
  isCustomer,
  hasPermissions,
  getRole,
  fullPermissions,
  getEmail,
  getFirstName,
  getDisplayName,
  isAdminOrAgent,
  isPartnerOrEmployee,
  getRoleColor,
  getActivityTableHeaders,
  getCustomWPAdminUser,
  hasAccessToBetaFeatures,
  hasIntegration,
};
export default UserHelper;
