import { postRequest, deleteRequest, getRequest } from '../config/axiosRequest';
import env from '../config/env';

/**
 * Create a new customer
 *
 * @param   {Object}  data
 * @return  {Object}
 */
const create = data => {
  return postRequest({
    url: env.buildApiEndpoint('customers'),
    data,
  });
};

/**
 * Update the specified customer.
 *
 * @param   {Object}  data
 * @return  {Object}
 */
const update = data => {
  return postRequest({
    url: env.buildApiEndpoint(`customers/${data.customer_slug}`),
    data,
  });
};

/**
 * Delete the specified customer.
 *
 * @param   {userSlug}  userSlug
 */
const del = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`customers/${data.customer_slug}`),
    data,
  });
};

// List customer invoices
const fetchInvoices = data => {
  return getRequest({
    url: env.buildApiEndpoint('customers/invoices'),
    data,
  });
};

// Send profile setup request
const sendProfileSetupRequestEmail = data => {
  return postRequest({
    url: env.buildApiEndpoint(`customers/${data.customer_slug}/send-profile-setup-request-email`),
    data,
  });
};

// Send profile setup request
const copyProfileSetupRequestUrl = data => {
  return postRequest({
    url: env.buildApiEndpoint(`customers/${data.customer_slug}/profile-setup-request-link`),
    data,
  });
};

// Get SMTP settings
const fetchSMTPSettings = () => {
  return getRequest({
    url: env.buildApiEndpoint('customers/smtp-settings'),
  });
};

// Update SMTP settings
const updateSMTPSettings = data => {
  return postRequest({
    url: env.buildApiEndpoint('customers/smtp-settings'),
    data,
  });
};

// Sends a test email using the SMTP settings
const sendSMTPTestEmail = data => {
  return postRequest({
    url: env.buildApiEndpoint('customers/smtp-test-email'),
    data,
  });
};

const CustomerService = { create, update, delete: del, fetchInvoices, sendProfileSetupRequestEmail, copyProfileSetupRequestUrl, fetchSMTPSettings, updateSMTPSettings, sendSMTPTestEmail };

export default CustomerService;
