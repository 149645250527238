import React from 'react';
import store from '../store';
import ArrayHelper from './array';
import StringHelper from './string';
import UserHelper from './user';
import JsxHelper from './jsx';
import PackageHelper from './package';
import WebsiteService from '../services/website';
import DialogHelper from './dialog';
import UrlHelper from './url';
import env from '../config/env';
import { isEmpty, isEmptyOrNull } from '.';
import { setGlobalErrorMsg } from '../store/global/globalActions';
import DateHelper from './date';

const sortingTypes = [
  {name:'Website ID (↑)',value:'slug:asc'},
  {name:'Create Time (↓)',value:'created_at:desc'},
  {name:'Go Live Time (↓)',value:'went_live_at:desc'}
]

const phpVersions = [
  {name:'8.2',value:'8.2'}, // default
  {name:'7.4',value:'7.4'},
]

const dispatchGetWPLoginToken = async (website, loadingWhileLogin, setLoadingWhileLogin, dispatch, modalDialog) => {
  if (!website || loadingWhileLogin.includes(website.slug)) {
    window.logHelper.warning(`Another login request is in progress for ${website.slug}.`);
    return;
  }
  try {
    const data = { website_slug: website.slug };
    setLoadingWhileLogin(prev => [...prev, website.slug]);
    const res = await WebsiteService.createWPLoginToken(data);
    const url = getWPLoginURL(website, 'loginToken', res.token);
    const opened = UrlHelper.open(url);
    if (!opened && modalDialog) {
      DialogHelper.warning(modalDialog, 'Oops! Popup Blocker Spotted 🚨', `Please allow popups for <b>${env.getDashboardDomain()}</b> and try again.`);
    }
  } catch (error) {
    const text = 'Failed to login. Please try again.';
    window.logHelper.error(text, error);
    dispatch && dispatch(setGlobalErrorMsg(text));
  } finally {
    setLoadingWhileLogin(prev => prev.filter(slug => slug !== website.slug));
  }
};

const createAlertIcon = (__history, website, customClass) => {
  const details = WebsiteHelper.getAlerts(website || []);
  return JsxHelper.createIcon({
    color: details.color,
    icon: details.icon,
    tooltip: details.tooltip,
    customClass: customClass || '',
    count: details.count ? details.count : null,
    html: true,
    onClick: () => goTo(__history, website.slug, 'alerts'),
  });
}

const createOneClickLoginBubble = (website, loadingWhileLogin, setLoadingWhileLogin, dispatch, modalDialog) => JsxHelper.createBubble({
  customClass: 'status-login',
  color: 'link',
  icon: 'login',
  onClick: () => dispatchGetWPLoginToken(website, loadingWhileLogin, setLoadingWhileLogin, dispatch, modalDialog),
  loading: !isEmpty(loadingWhileLogin) && loadingWhileLogin.includes(website.slug),
})

const getCustomWPAdminUser = (partner) => {
  const userlogin = UserHelper.getCustomWPAdminUser();
  if (userlogin) {
    return userlogin;
  }
  return partner ? (partner.custom_wp_admin_user || partner.slug) : '';
}

const createLink = (website, classes) => {
  return JsxHelper.createLink(`https://${website.default_domain}`, website.default_domain, `class-link no-underline ${classes}`);
}

const buildSelectOptions = (websites) => {
  const options = websites.map(website => ({label: WebsiteHelper.getLabel(website), value: website.slug}));
  return ArrayHelper.buildSelectOptions(options, 'label', 'value');
}

const buildQuickAccessOptions = (websites) => {
  const options = websites.map(w => ({ label: WebsiteHelper.getLabel(w), value: w.slug + ' ' + w.default_domain}));
  return ArrayHelper.buildSelectOptions(options, 'label', 'value');
}

const getLabel = (website) => website.label || website.slug

const goTo = (__history, slug, path) =>  __history.push(`/websites/${slug}/${path || 'overview'}`)
const goToIndex = (__history) =>  __history.push('/websites')
const goToAdvanced = (__history, slug, path, search) => __history.push({ pathname: `/websites/${slug}/advanced/${path}`, search: search || '' });
const goToStaqPanel = (__history, slug) => goTo(__history, slug)

const isActivePackage = (website, item, installed) => {
  const activesList = PackageHelper.activesList(item.type, website, installed);
  return activesList.includes(item.folder_name || item.name);
}

const getAlerts = (website) => {
  // Parse the alerts string into an array of objects.
  const alerts = [];
  let risk = null;
  let rank = 0;
  if (!isEmptyOrNull(website.alerts)) {
    for (const alertParts of website.alerts.split(',')) {
      const riskAlert = alertParts.split(':'); // "(vt|vp|up|ut):\d+" - u: update, v: vulnerability, t: theme, p: plugin
      const numOfAlerts = parseInt(riskAlert[1]);
      if (numOfAlerts > 0) {
        if (riskAlert[0][0] === 'v') {
          risk = 'h'; // High risk
        } else if (risk !== 'h' && riskAlert[0][0] === 'u') {
          risk = 'm'; // Medium risk
        }
        alerts.push({ type: riskAlert[0], count: numOfAlerts });
      }
    }
  }
  // Set the icon and color based on the highest risk level.
  let color = 'inactive';
  let icon = 'alertOff';
  if (risk === 'm' || risk === 'l') {
    icon = 'alertOn';
    color = 'warning';
  } else if (risk === 'h') {
    icon = 'alertOn';
    color = 'danger';
  }
  // Build the tooltip text.
  let total = 0;
  let lines = [];
  for (const alert of alerts) {
    let line = '';
    if (alert.type === 'vt') {
      line = `Vulnerable Themes: ${alert.count}`;
    } else if (alert.type === 'vp') {
      line = `Vulnerable Plugins: ${alert.count}`;
    } else if (alert.type === 'ut') {
      line = `Theme Updates: ${alert.count}`;
    } else if (alert.type === 'up') {
      line = `Plugin Updates: ${alert.count}`;
    }
    lines.push(`<p>${line}</p>`);
    total += alert.count;
    rank += alert.count + (alert.type.includes('v') ? 1000 : 0);
  }
  if (isEmptyOrNull(lines)) {
    lines.push('No alerts');
  }
  return {
    icon,
    color,
    tooltip: lines.join(''),
    count: total,
    rank,
  };
}

const parseSSL = (expectedDomains, ssl) => {
  let message = null;
  let tooltip = null;
  let message2 = null;
  let type = 'warning';
  let status = 'failed';
  let missingDomains = []; // Domains not covered by the SSL certificate

  // SSL certificate details
  const certName = ssl && ssl.certName ? ssl.certName : null; // Certificate name
  const issuer = ssl && ssl.issuer ? ssl.issuer.O : 'Let\'s Encrypt'; // Who issued the certificate
  const subject = ssl && ssl.subject ? ssl.subject.O : null; // To whom the certificate was issued
  const validTo = ssl && ssl.validTo ? ssl.validTo : null; // When the certificate expires
  const isCustom = ssl && ssl.isCustom; // Whether the certificate is custom
  const autoRenew = !isCustom; // Whether the certificate is auto-renewed (non-custom domains only)
  const sslProcess = ssl ? ssl.process : null; // SSL generation process (legacy for LetsEncrypt)

  // Constants
  const PROPAGATION_DELAY_NOTICE = `The domain's DNS has not been propagated yet, but ${env.getBrandShortName()} will attempt to generate the SSL automatically in the upcoming minutes.`;

  if (hasDevDomain(expectedDomains)) {
    // Default domains are assumed to have SSL enabled.
    type = 'success';
    tooltip = 'SSL Enabled (Default)';
    status = 'valid';
  } else if (isEmptyOrNull(ssl)) {
    // Unexpected case: SSL not found nor the process to create it exists.
    message = 'Oops! We could not find the SSL certificate details.';
    type = 'danger';
    status = 'not found';
    tooltip = 'SSL Not Found';
  } else if (sslProcess && sslProcess.status === 'busy') {
      // SSL is busy and being generated in the background
      message = 'An SSL certificate is being generated by a background process.';
      status = 'busy';
  } else if (!certName && (!sslProcess || sslProcess.status === 'failed')) {
    // Certificate failed to create/renew (certName is null).
    type = 'danger';
    status = 'invalid';
    message = sslProcess?.error || `Failed to generate an SSL certificate.`;
    message2 = isCustom ? '' : `${PROPAGATION_DELAY_NOTICE} You can leave this page or try generating the SSL again.`;
    missingDomains = expectedDomains;
  } else if (ssl.status !== 'valid') {
    // Unexpected case: the status is not 'valid' and not 'invalid' (no other options).
    status = ssl.status || 'invalid';
    message = `SSL certificate status is ${status}.`;
    type = 'danger';
  } else {
    status = 'valid';
    // Status is valid, let's check if it covers all expected domains.
    const sslDomains = ssl.domains;
    missingDomains = expectedDomains.filter(d => !sslDomains.includes(d));
    if (isEmpty(sslDomains)) {
      // Unexpected case but let's handle it anyway.
      message = `SSL certificate has no domains.`;
      type = 'danger';
    } else if (!isEmpty(missingDomains)) {
      message = `The following domains are not covered by the SSL certificate:<br/>${JsxHelper.createModalItems(missingDomains)}`;
      tooltip = `SSL certificate does not cover all domains`;
      message2 = isCustom ? '' : `${PROPAGATION_DELAY_NOTICE} You can leave this page or try generating the SSL again.`;
    } else {
      message = `SSL certificate has been generated successfully.`;
      tooltip = 'SSL Enabled';
      type = 'success';
    }
  }

  return {
    issuer,
    subject,
    validTo,
    autoRenew,
    status,
    isCustom,
    ssl,
    missingDomains,
    tooltip: tooltip || message,
    icon: type === 'success' ? 'closedLock' : 'openLock',
    modalParams: {
      line1: message,
      line2: message2,
      textAlign: message2 ? 'left' : 'center',
      modalCloseIcon: false,
      type,
      btnText: 'Close',
    }
  }
}

const isValidSlug = slug => {
  let error = null;
  if (isEmpty(slug)) {
    error = 'Website ID cannot be empty.';
  } else if (slug.length < 5 || slug.length > 32) {
    error = 'Website ID must be between 5 and 32 characters long.';
  } else if (/^\d/.test(slug[0])) {
    error = 'Website ID must start with a letter (a-z).'
  }
  return { valid: isEmpty(error), error: error };
}

// Check if the specified website is ready
const isReady = website => {
  // Load websites from global store.
  const state = store.getState();
  const currentWebsite = ArrayHelper.find(state.websites, 'slug', website.slug);

  return (
    !isEmpty(currentWebsite) &&
    currentWebsite.state === 'available' &&
    ['ok', 'ok-has-warning'].includes(currentWebsite.status)
  );
};

const isDevMode = website => {
  return hasDevDomain([ website.default_domain ]);
}

const hasDevDomain = domains => {
  const defaultDomain = process.env.REACT_APP_DEFAULT_HOSTING_DOMAIN;
  return !!domains.find(d => d.includes(defaultDomain));
}

const getDevDomain = (website) =>
  `${website.slug}.${website.server_slug}.${website.region_slug}.${process.env.REACT_APP_DEFAULT_HOSTING_DOMAIN}`

const getAlertLevel = (website) =>
  isEmptyOrNull(website.alerts) ? 'None' : website.alerts.includes('v') ? 'Security' : 'Update'

// Check if the specified website is busy
const isBusy = website => {
  const state = store.getState();
  const currentWebsite = ArrayHelper.find(state.websites, 'slug', website.slug);

  return (
    currentWebsite && !['available', 'not-applicable', 'failed'].includes(currentWebsite.state)
  );
};

const getMigrationStateText = (state) => {
  switch (state) {
    case "migration:pending":
      return "Pending migration";
    case "migration:downloading":
      return "Downloading migration data";
    case "migration:extracting":
      return "Extracting migration data";
    case "migration:importing-files":
      return "Importing files";
    case "migration:importing-media":
      return "Importing media";
    case "migration:importing-db":
      return "Importing database";
    case "migration:adjusting-db":
      return "Adjusting database";
    case "migration:offloading-s3":
      return "Offloading media to S3";
    case "migration:activating-plugins":
      return "Activating plugins";
    case "migration:finalizing-config":
      return "Finalizing configuration";
    default:
      return StringHelper.toText(state.replace('migration:', ''));
  }
}

const getSyncStateText = (state) => {
  switch (state) {
    case "sync:pending":
      return "Pending sync";
    case "sync:creating-backups":
      return "Creating backups";
    case "sync:pushing-db":
      return "Syncing database";
    case "sync:pushing-files":
      return "Syncing files";
    case "sync:adjusting-db":
      return "Adjusting database";
    case "sync:finalizing-config":
      return "Finalizing configuration";
    default:
      return StringHelper.toText(state.replace('sync:', ''));
  }
}

const stateToText = (state) => {
  const parts = state.split(':');
  // If the state is generic (e.g. "available", "not-applicable", ...), return as text.
  if (parts.length === 1) {
    return StringHelper.toText(state);
  }
  // If the state is specific (e.g. "migration:pending"), return the specific text.
  if (parts[0] === 'migration') {
    return getMigrationStateText(state);
  } else if (parts[0] === 'sync') {
    return getSyncStateText(state);
  }
  return StringHelper.toText(parts[1]);
}

const getStateStyle = state => {
  let colorName = '';
  let colorClass = '';
  let icon = '';
  const text = stateToText(state);
  if (['not-applicable', 'failed'].includes(state)) {
    colorName = 'danger';
    icon = 'stateStopped';
  } else if (state === 'available') {
    colorName = 'success';
    icon = 'stateRunning';
  } else {
    colorName = 'warning';
    icon = 'statePaused';
  }
  return { color: colorName, colorClass: colorClass, icon: icon, text: text };
}

const statusToText = (status, reason) => {
  if (status === 'redirect-ok') {
    status = status.replace('redirect-', '');
    reason = 'page redirect detected';
  }
  let text = StringHelper.toText(status);
  if (reason) {
    text = `${text} (${reason})`;
  }
  return text;
}

const getStatusStyle = (status, reason) => {
  const icon = 'status';
  let colorName = '';
  let colorClass = '';
  let text = statusToText(status, reason);
  if (['not-applicable', 'impaired', 'connection-error', 'redirect-impaired', 'redirect-connection-error'].includes(status)) {
    colorName = 'danger';
  } else if (status === 'ok' || status === 'redirect-ok') {
    colorName = 'success';
  } else {
    colorName = 'warning';
  }
  return { color: colorName, colorClass: colorClass, text: text, icon: icon };
}

const getCdnStatus = cdn => {
  let color = 'inactive';
  let icon = 'cloudOff';
  let defaultText = 'Not configured';
  let customClass = '';
  let text = null;
  let filter = null;
  let reason = null;

  if (!isEmptyOrNull(cdn)) {
    // Cloudflare
    if (cdn.zone_id) {
      icon = 'cloudflare';
      switch (cdn.status) {
        case 'active':
          color = 'success';
          filter = `Active (Cloudflare)`;
          reason = 'Active';
          break;
        default:
          color = 'warning';
          filter = 'Inactive (Cloudflare)';
          reason = 'Requires attention';
      }
      text = filter;
      customClass = 'cdn-cloudflare'
      return { color, icon, text, reason, filter, customClass };
    }
    // CloudFront
    switch (cdn.status) {
      case 'ok':
        color = 'success';
        icon = 'cloudOn';
        reason = cdn.status_reason;
        filter = `Available (${cdn.used_as === 'cache' ? 'Traditional' : 'Next-Gen'})`;
        text = filter;
        break;
      case 'impaired':
        color = 'danger';
        icon = 'cloudOn';
        reason = cdn.status_reason;
        break;
      case 'not-applicable':
        color = 'danger';
        icon = 'cloudOn';
        reason = cdn.status_reason;
        break;
      case 'ok-has-warning':
        color = 'warning';
        icon = 'cloudOn';
        reason = cdn.status_reason;
        filter = 'Has warning';
        break;
      default:
        color = 'inactive';
        icon = 'cloudOff';
    }
    filter = filter || StringHelper.toText(cdn.status);
    text = text ? text : (reason ? StringHelper.toText(reason) : defaultText);
  } else {
    filter = defaultText;
    text = defaultText;
  }
  return { color, icon, text, reason, filter, customClass };
}

const hasSupportedPageBuilder = (website) => {
  if (website.active_theme) {
    // If the active theme contains any substring of supported themes
    const themes = [ 'divi', 'avada', 'fusion' ];
    const activeTheme = website.active_theme.toLowerCase();
    for (const st of themes) {
      if (activeTheme.includes(st)) {
        return true;
      }
    }
  }
  // If the any of the active plugins contains any substring of the the supported plugins
  const plugins = [ 'bb-plugin', 'elementor', 'oxygen' ];
  const activePlugins = website.active_plugins.map(p => p.toLowerCase());
  return activePlugins.find(p => {
      for (const sp of plugins) {
          if (p.includes(sp)) {
              return true;
          }
      }
      return false;
  });
}

const getRedisObjectCacheConfigOptions = () => {
  return [
    {
      name: 'enabled',
      title: 'Enabled',
      desc: 'Creates a secure access to Redis and installs the object-cache.php drop-in on your WordPress installation. Enabling object cache speeds up website performance and reduces the number of database queries required for website operation.',
      options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    },
    {
      name: 'max_storage',
      title: 'Max storage size',
      desc: 'The maximum amount of memory that Redis can use to store objects of your website. If the limit is reached, Redis will start removing objects from the cache to make room for new objects.',
      options: [
        { label: '10 MB', value: '10M' },
        { label: '25 MB', value: '25M' },
        { label: '100 MB', value: '100M' },
        { label: '1 GB', value: '1024M' },
      ],
    },
  ];
}

const getPhpConfigOptions = () => {
  return [
    {
      icon: 'websiteConfig',
      name: 'php_version',
      title: 'PHP Version',
      desc: 'Sets the PHP version used to run your website.',
      options: [
        { label: '7.4', value: '7.4' },
        { label: '8.2', value: '8.2' },
      ],
    },
    {
      icon: 'websiteConfig',
      name: 'max_execution_time',
      title: 'Max Execution Timeout',
      desc: 'Sets the max time a PHP script is allowed to run.',
      default: '30',
      options: [
        { label: 'Default (30 seconds)', value: 'default' },
        { label: '30 seconds', value: '30' },
        { label: '45 seconds', value: '45' },
        { label: '1 minute', value: '60' },
        { label: '3 minutes', value: '180' },
        { label: '5 minutes', value: '300' },
        { label: '10 minutes', value: '600' }
      ],
    },
    {
      icon: 'websiteConfig',
      name: 'memory_limit',
      title: 'Request Memory Limit',
      desc: 'Sets the max memory that a PHP script is allowed to allocate.',
      default: '256M',
      options: [
        { label: 'Default (256 MB)', value: 'default' },
        { label: '30 MB', value: '30M' },
        { label: '64 MB', value: '64M' },
        { label: '128 MB', value: '128M' },
        { label: '256 MB', value: '256M' },
        { label: '512 MB', value: '512M' },
        { label: '1 GB', value: '1024M' },
        { label: '2 GB', value: '2048M' }
      ],
    },
    {
      icon: 'websiteConfig',
      title: 'Max Post Size',
      name: 'post_max_size',
      desc: 'Sets the max size of post data allowed.',
      default: '25M',
      options: [
        { label: 'Default (25 MB)', value: 'default' },
        { label: '5 MB', value: '5M' },
        { label: '10 MB', value: '10M' },
        { label: '15 MB', value: '15M' },
        { label: '25 MB', value: '25M' },
        { label: '50 MB', value: '50M' },
        { label: '200 MB', value: '200M' },
        { label: '500 MB', value: '500M' },
        { label: '1 GB', value: '1000M' },
      ],
    },
    {
      icon: 'websiteConfig',
      name: 'upload_max_filesize',
      title: 'Max File Upload',
      desc: 'Sets the max size of an uploaded file.',
      default: '25M',
      options: [
        { label: 'Default (25 MB)', value: 'default' },
        { label: '5 MB', value: '5M' },
        { label: '10 MB', value: '10M' },
        { label: '15 MB', value: '15M' },
        { label: '25 MB', value: '25M' },
        { label: '50 MB', value: '50M' },
        { label: '200 MB', value: '200M' },
        { label: '500 MB', value: '500M' },
        { label: '1 GB', value: '1000M' },
      ],
    },
    {
      icon: 'websiteConfig',
      name: 'max_input_vars',
      title: 'Max Input Vars',
      desc: 'Sets the number of input variables may be accepted (limit is applied to $_GET, $_POST and $_COOKIE superglobal separately).',
      default: '4000',
      options: [
        { label: 'Default (4000)', value: 'default' },
        { label: '1000', value: '1000' },
        { label: '2000', value: '2000' },
        { label: '3000', value: '3000' },
        { label: '4000', value: '4000' },
        { label: '5000', value: '5000' },
      ],
    },
    {
      icon: 'websiteConfig',
      name: 'pm_max_children',
      title: 'Max PHP Workers',
      desc: 'The maximum number of child processes allowed to be spawned.',
      default: '10',
      options: [
        { label: 'Default (10)', value: 'default' },
        { label: '10', value: '10' },
        { label: '15', value: '15' },
        { label: '20', value: '20' },
        { label: '25', value: '25' },
        { label: '50', value: '50' },
        { label: '75', value: '75' },
        { label: '100', value: '100' },
        { label: '200', value: '200' },
        { label: '350', value: '350' },
        { label: '500', value: '500' },
      ],
    },
    {
      icon: 'websiteConfig',
      name: 'pm_process_idle_timeout',
      title: 'Process Idle Timeout',
      desc: 'The idle time, in seconds, a child process will wait for new requests before it is terminated. This setting does not affect the script execution in any way.',
      default: '5',
      options: [
        { label: 'Default (5 seconds)', value: 'default' },
        { label: '5 seconds', value: '5' },
        { label: '15 seconds', value: '15' },
        { label: '30 seconds', value: '30' },
        { label: '45 seconds', value: '45' },
        { label: '60 seconds', value: '60' },
      ],
    }
  ]
}

const getAutoUpdaterConfigOptions = () => {
  return [
    {
      name: 'vulnerability_updates',
      title: <span>Automatic Updates for <b>Vulnerable</b> Plugins / Themes</span>,
      desc: 'Automatically update vulnerable plugins and themes as soon as a new version is available. This bypasses any scheduled update settings.',
      default: 'none',
      options: [
        { label: 'Apply to all sites, including global plugins / themes', value: 'all' },
        { label: 'Apply to selected sites only', value: 'selected' },
        { label: 'Disabled', value: 'none' },
      ],
    },
    {
      name: 'websites_update_status',
      title: <span>Automatic Updates for <b>All</b> Plugins / Themes</span>,
      desc: `Schedule automatic updates for all plugins and themes on your websites. This setting will override individual website settings.`,
      default: 'disabled',
      options: [
        { label: 'Enabled', value: 'enabled' },
        { label: 'Disabled', value: 'disabled' },
      ],
    },
    {
      name: 'schedule_mode',
      title: 'Update Schedule Mode',
      desc: `Choose 'Custom' to set a specific schedule for updates, or select 'Automatic' to enable ${env.getBrandShortName()} to select the best time for updates.`,
      default: 'custom',
      options: [
        { label: 'Custom', value: 'custom' },
        { label: 'Automatic', value: 'auto' },
      ],
    },
    {
      name: 'schedule_interval',
      title: 'Schedule Interval',
      desc: 'Set the interval for automatic updates.',
      default: 'daily',
      options: [
        { label: 'Daily', value: 'daily' },
        { label: 'Weekly', value: 'weekly' },
        { label: 'Fortnightly', value: 'fortnightly' },
        { label: 'Monthly', value: 'monthly' },
      ],
    },
    {
      name: 'schedule_day',
      title: 'Schedule Day of Week',
      desc: 'Set the day of the week for automatic updates. If a daily interval is selected, this setting is ignored.',
      default: 'sunday',
      options: [
        { label: 'Sunday', value: 'sunday' },
        { label: 'Monday', value: 'monday' },
        { label: 'Tuesday', value: 'tuesday' },
        { label: 'Wednesday', value: 'wednesday' },
        { label: 'Thursday', value: 'thursday' },
        { label: 'Friday', value: 'friday' },
        { label: 'Saturday', value: 'saturday' },
      ],
    },
    {
      name: 'schedule_time',
      title: 'Schedule Time of Day',
      desc: 'Set the time of the day for automatic updates in your local time.',
      default: '00:00',
      options: [
        { label: '12 AM (Midnight)', value: '00:00' },
        { label: '1 AM', value: '01:00' },
        { label: '2 AM', value: '02:00' },
        { label: '3 AM', value: '03:00' },
        { label: '4 AM', value: '04:00' },
        { label: '5 AM', value: '05:00' },
        { label: '6 AM', value: '06:00' },
        { label: '7 AM', value: '07:00' },
        { label: '8 AM', value: '08:00' },
        { label: '9 AM', value: '09:00' },
        { label: '10 AM', value: '10:00' },
        { label: '11 AM', value: '11:00' },
        { label: '12 PM (Midday)', value: '12:00' },
        { label: '1 PM', value: '13:00' },
        { label: '2 PM', value: '14:00' },
        { label: '3 PM', value: '15:00' },
        { label: '4 PM', value: '16:00' },
        { label: '5 PM', value: '17:00' },
        { label: '6 PM', value: '18:00' },
        { label: '7 PM', value: '19:00' },
        { label: '8 PM', value: '20:00' },
        { label: '9 PM', value: '21:00' },
        { label: '10 PM', value: '22:00' },
        { label: '11 PM', value: '23:00' },
      ],
    },
    {
      name: 'schedule_timezone',
      title: 'Schedule Timezone',
      desc: 'Set the timezone for automatic updates.',
      default: DateHelper.localTimezone,
      options: DateHelper.timezoneSelectOptions,
      isSearchable: true,
    },
    {
      name: 'include_websites',
      title: 'Include Websites in Auto-Update',
      desc: `Enable Auto-Updates for all websites and their plugins/themes with a single selection, or pick specific websites for automatic updates. This removes the need for manual activation in the ${env.getBrandShortName()} Panel for individual plugins or themes.`,
      default: [ 'all' ],
      multiSelect: true,
      options: [
        { label: 'All Websites', value: 'all' },
      ],
    },
    {
      name: 'exclude_websites',
      title: 'Exclude Websites from Auto-Update',
      desc: 'Select websites to exclude from automatic plugin and theme updates, especially if "All Websites" is selected in the previous setting.',
      multiSelect: true,
      options: [],
    },
  ]
}

const getHideLoginConfigOptions = () => {
  return [
    {
      name: 'wp_login_alias',
      title: 'Login Page URL',
      desc: 'Protect your WordPress login page by changing the default URL to a custom alias. The default is /wp-admin.',
      default: 'wp-admin',
      type: 'text',
    },
    // {
    //   name: 'wp_login_file',
    //   title: 'Login PHP File',
    //   desc: `Rename the WordPress login file to a custom name.`,
    //   default: 'login.php',
    //   type: 'text',
    // },
    {
      name: 'invalid_login_behaviour',
      title: 'Invalid Access Behaviour',
      desc: `Select the action to take when a user attempts to access the /wp-admin page if you've changed its location. Choose "Show Restrictive Page" to block access, or "Redirect To Custom Page" to send users to a specific URL of your choice.`,
      default: 'redirect',
      options: [
        { label: 'Show Restrictive Page', value: 'deny' }, // A special value to indicate that the user should be denied access.
        { label: 'Redirect To Custom Page', value: 'redirect' },
      ],
    },
    {
      name: 'invalid_login_slug',
      title: 'Custom Redirect URL',
      desc: 'Specify a custom URL to redirect users attempting to access the /wp-admin page if you have changed its location.',
      default: '404',
      type: 'text',
    },
  ]
}

const getNginxConfigOptions = () => {
  const optionToDesc = {
    'x_frame_options': 'The X-Frame-Options HTTP response header can be used to indicate whether or not a browser should be allowed to render a page in a <frame>, <iframe>, <embed> or <object>.',
    'x_xss_protection': 'The HTTP X-XSS-Protection response header is a feature of Internet Explorer, Chrome and Safari that stops pages from loading when they detect reflected cross-site scripting (XSS) attacks.',
    'content_security_policy': 'Content Security Policy (CSP) is an added layer of security that helps to detect and mitigate certain types of attacks, including Cross-Site Scripting (XSS) and data injection attacks. <br/><br/>All rules include unsafe-inline and unsafe-eval unless excluded.',
    'referrer_policy': 'The Referrer-Policy controls how much referrer information (sent with the Referer header) should be included with requests.',
    'ssl_buffer_size': 'Sets the size of the buffer used for sending data. By default, the buffer size is 8k, which corresponds to minimal overhead when sending medium size responses. To minimize TTFB (Time To First Byte) it may be beneficial to use smaller values.',
    'fastcgi_buffers': 'Sets the number and size of the buffers used for reading a response from the FastCGI server, for a single connection.',
    'fastcgi_buffer_size': 'Sets the size of the buffer used for reading the first part of the response received from the FastCGI server. This part usually contains a small response header.',
    'allow_seo_robots': 'Allows known SEO robots such as Semrush, Ahrefs, Screaming, etc. to crawl your website. The default is disabled unless overridden by the global setting.',
    'allow_xmlrpc': 'Allows XML-RPC requests to be sent to your website. This is required for Jetpack and other plugins to work properly. The default is disabled unless overridden by the global setting.',
    'trailing_slash': 'Enables or disables trailing slashes on the end of URLs. This setting is useful when the website static cache is enabled and being served directly by Nginx without going through WordPress. The default is disabled. IMPORTANT: You must modify the permalinks in WordPress to add trailing slashes to URLs, otherwise, the website might not work properly.',
  };
  return [
    {
      name: 'x_frame_options',
      title: 'X-Frame-Options',
      desc: optionToDesc['x_frame_options'],
      default: 'SAMEORIGIN',
      category: 'headers',
      options: [
        { label: 'Same origin', value: 'SAMEORIGIN' },
        { label: 'Deny', value: 'DENY' },
        { label: 'Exclude', value: 'disable' },
      ],
    },
    {
      name: 'x_xss_protection',
      title: 'X-XSS-Protection',
      desc: optionToDesc['x_xss_protection'],
      default: '1; mode=block',
      category: 'headers',
      options: [
        { label: 'Enables XSS filtering & block page on attack', value: '1; mode=block' },
        { label: 'Disable XSS filtering', value: '0' },
        { label: 'Enables XSS filtering & sanitize page on attack', value: '1' },
      ],
    },
    {
      name: 'content_security_policy',
      title: 'Content-Security-Policy',
      desc: optionToDesc['content_security_policy'],
      default: 'default-relaxed-security',
      category: 'headers',
      options: [
        { label: 'Default Relaxed Security', value: 'default-relaxed-security' },
        { label: 'Strict Security', value: 'strict-security' },
        { label: 'Inline and Eval Allowed', value: 'inline-eval-allowed' },
        { label: 'Strict Dynamic Security', value: 'strict-dynamic-security' },
        { label: 'No Security', value: 'disable' },
      ],
    },
    {
      name: 'referrer_policy',
      title: 'Referrer-Policy',
      desc: optionToDesc['referrer_policy'],
      default: 'strict-origin-when-cross-origin',
      category: 'headers',
      options: [
        { label: 'Origin', value: 'origin' },
        { label: 'Origin when cross origin', value: 'origin-when-cross-origin' },
        { label: 'Same origin', value: 'same-origin' },
        { label: 'Strict origin', value: 'strict-origin' },
        { label: 'Strict origin when cross origin', value: 'strict-origin-when-cross-origin' },
        { label: 'No referrer', value: 'no-referrer' },
      ],
    },
    {
      name: 'ssl_buffer_size',
      title: 'SSL buffer size',
      desc: optionToDesc['ssl_buffer_size'],
      default: '16k',
      category: 'advanced',
      options: [
        { label: '2k', value: '2k' },
        { label: '4k', value: '4k' },
        { label: '8k', value: '8k' },
        { label: '16k', value: '16k' },
      ],
    },
    {
      name: 'fastcgi_buffers',
      title: 'FastCGI buffers',
      desc: optionToDesc['fastcgi_buffers'],
      default: '16 16k',
      category: 'advanced',
      options: [
        { label: '8 16k', value: '8 16k' },
        { label: '16 16k', value: '16 16k' },
        { label: '16 32k', value: '16 32k' },
        { label: '16 64k', value: '16 64k' },
        { label: '16 128k', value: '16 128k' },
      ],
    },
    {
      name: 'fastcgi_buffer_size',
      title: 'FastCGI buffer size',
      desc: optionToDesc['fastcgi_buffer_size'],
      default: '32k',
      category: 'advanced',
      options: [
        { label: '16k', value: '16k' },
        { label: '32k', value: '32k' },
        { label: '64k', value: '64k' },
        { label: '128k', value: '128k' },
      ],
    },
    {
      name: 'trailing_slash',
      title: 'Trailing Slash',
      desc: optionToDesc['trailing_slash'],
      default: 'default',
      category: 'advanced',
      options: [
        { label: 'Default', value: 'default' },
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    },
    {
      name: 'allow_seo_robots',
      title: 'Allow SEO robots',
      desc: optionToDesc['allow_seo_robots'],
      default: 'default',
      category: 'access',
      options: [
        { label: 'Default', value: 'default'},
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    },
    {
      name: 'allow_xmlrpc',
      title: 'Allow XML-RPC',
      desc: optionToDesc['allow_xmlrpc'],
      default: 'default',
      category: 'access',
      options: [
        { label: 'Default', value: 'default'},
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    },
  ]
}

const _getConfig = (website, key, defaultValue) =>
  isEmptyOrNull(website) || isEmptyOrNull(website[key]) ? defaultValue : website[key];

const getCronJobs = website => _getConfig(website, 'cron_jobs', []);
const getWPDefines = website => _getConfig(website, 'wp_defines', []);
const getURLRedirects = website => _getConfig(website, 'url_redirects', []);
const getPHPConfig = website => _getConfig(website, 'php_config', {});
const getNginxConfig = website => _getConfig(website, 'nginx_config', {});
const getHideLoginConfig = website => _getConfig(website, 'hide_login_config', {});
const getSMTPMailerConfig = website => _getConfig(website, 'smtp_mailer_config', {});
const getObjectCacheConfig = website => _getConfig(website, 'objectcache_config', {});
const getWPLoginURL = (website, queryName, queryValue) => {
  const _alias = website.hide_login_config ? website.hide_login_config.wp_login_alias : null;
  const url = `https://${website.default_domain}/${_alias || 'login.php'}`;
  return queryName && queryValue ? `${url}?${queryName}=${queryValue}` : url;
}

const getCdnProxySsl = website => {
  if (isEmptyOrNull(website) || isEmptyOrNull(website.cdn_ssl_certificates)) {
    return null;
  }
  return website.cdn_ssl_certificates.find(cert => cert.cdn_type === 'proxy');
}

const getCdnCacheSsl = website => {
  if (isEmptyOrNull(website) || isEmptyOrNull(website.cdn_ssl_certificates)) {
    return null;
  }
  return website.cdn_ssl_certificates.find(cert => cert.cdn_type === 'cache');
}

const getCdnCache = website => {
  if (isEmptyOrNull(website) || isEmptyOrNull(website.cdns)) {
    return null;
  }
  return website.cdns.find(cdn => cdn.used_as === 'cache');
}

const getCdnProxy = website => {
  if (isEmptyOrNull(website) || isEmptyOrNull(website.cdns)) {
    return null;
  }
  return website.cdns.find(cdn => cdn.used_as === 'proxy');
}

const getCloudflareZone = (website) => website.cloudflare_zone || null;

const findWebsite = (websites, slug) => {
  return ArrayHelper.find(websites, 'slug', slug);
}

const urlFormats = website => {
  let formats = {};

  // site url
  formats['Site-URL'] = `//${website.default_domain}`;

  // dev-url
  const devURL = `//${getDevDomain(website)}`;
  if (formats['Site-URL'] !== devURL) {
    formats['Dev-URL'] = devURL;
  }
  
  // site media
  formats['Site-Media-URL'] = `//${website.default_domain}/wp-content/uploads/`;

  // s3 media
  const prefix = `${website.slug}/wp-content/uploads/media`;
  formats['S3-Media-URL'] = `//${env.getS3MediaBucket(website.region_slug)}.s3.amazonaws.com/${prefix}/`;

  // cdn media (traditional only)
  const cdnCache = getCdnCache(website);
  if (cdnCache) {
    formats['CDN-Media-URL'] = `//${cdnCache.domain}/wp-content/uploads/media/`
  }

  return formats;
}

const hasSslConnectionError = website => {
  return website.status_reason && website.status_reason.includes('SSH remote key was not OK');
}

const getEnvId = website => website.is_staging ? 'staging' : (website.is_live ? 'live' : 'dev');
const getEnvName = website => website.is_staging ? 'Staging' : (website.is_live ? 'Live' : 'Development');

// List of common tooltips
const tooltips = {
  region_id: `A website Region is a physical location where data centers are clustered, on which the WordPress site will be installed. It is best to choose the region that will receive the most traffic. You can scale traffic to global regions via the CDN when you take your website live.`,
  website_id: `A website ${env.getBrandShortName()} ID is a unique identifier for your website in ${env.getBrandShortName()}. For example, if the domain is www.example.com, a good ID would be "example" or "myexample".`,
  website_plan_id: `A pricing plan is what you pay to ${env.getBrandShortName()} on a recurring basis every month. It also determines the features, resources, limits and the excess charges included in this WordPress installation.`,
  request_customer_profile_setup: `By checking this box your client will receive an email advising them to review their profile details.
  In addition, the client may add a credit card on file which will automatically be charged for services.<br/><br/>
  You can add your own Terms & Conditions page through Settings > Terms & Conditions > Billing.<br/><br/>
  If you decide to leave this box unchecked then no email will be sent.<br/><br/>
  The client details may be edited at anytime through Clients > Client List > Update.`,
  db_exclude_tables: 'Select the specific tables that should be skipped from exporting. Excluding this option will export all tables in the database.',
  db_include_tables: 'Select the specific tables to export. Excluding this option will export all tables in the database (except the ones specified in the exclude option, if any).',
  is_temp_manual: 'Choose whether to automatically delete the manual backup after the retention period or keep it indefinitely.',
}

// Returns the staq panel items and sub-items
const staqPanelItems = [
  {
    title: 'Files',
    color: 'color-orange',
    items: [
      {
        name: 'Secure FTP',
        icon: 'filemanager',
        link: '/sftp-access',
        hoverText: 'Manage website files via secure SFTP',
      },
      {
        name: 'Plugins',
        icon: 'plugins',
        link: '/plugins',
        hoverText: 'Manage website plugins',
      },
      {
        name: 'Themes',
        icon: 'themes',
        link: '/themes',
        hoverText: 'Manage website themes',
      },
      {
        name: 'Backups',
        icon: 'backup',
        link: '/backups',
        hoverText: 'Backup & restore website versions',
      },
      {
        name: 'Cache',
        icon: 'cache',
        link: '/cache',
        hoverText: 'Manage website cache',
      },
      {
        name: 'WordPress Version',
        icon: 'wordpress',
        link: '/upgrade-wordpress',
        hoverText: 'Update WordPress version',
      },
    ],
  },
  {
    title: 'Domains',
    color: 'color-green',
    items: [
      {
        name: 'Domains',
        icon: 'domain',
        link: '/domains',
        hoverText: 'Manager your website domains',
        doDisable: w => w.is_staging,
        disableText: () => 'Not available for staging environments',
      },
      {
        name: 'Go Live',
        icon: 'liveSite',
        link: '/go-live',
        hoverText: 'Take your website live',
        doHide: w => w.is_live,
        doDisable: w => w.is_staging,
        disableText: () => 'Not available for staging environments',
      },
      {
        name: 'DNS Zone',
        icon: 'dns_zones',
        link: w => !w.dns_zone_slug ? '/dns-zone' : `/dns-zones/${w.dns_zone_slug}/records`,
        isAbsolute: w => !!w.dns_zone_slug,
        hoverText: 'Manage your website DNS records',
        doDisable: w => w.is_staging || !!getCloudflareZone(w),
        disableText: (w) => `Not available for ${w.is_staging ? 'staging environments' : 'Cloudflare managed domains'}`,
      },
      {
        name: 'CDN',
        icon: 'cdn',
        link: '/cdn',
        hoverText: 'Set up CDN to improve the performance, speed, and security of your website',
        doDisable: w => w.is_staging,
        disableText: () => 'Not available for staging environments',
      },
      {
        name: 'Redirects',
        icon: 'redirect',
        link: '/advanced/redirects',
        hoverText: 'Perform safe and performant redirects at the server level via Nginx http server',
      },
      {
        name: 'SSL',
        icon: 'lock',
        link: '/advanced/ssl',
        hoverText: 'Manage your website SSL certificates',
        doDisable: w => w.is_staging || !w.is_live,
        disableText: () => `Not available for non-live environments`,
      },
      {
        name: 'Review',
        icon: 'checklist',
        link: '/advanced/review',
        hoverText: 'Check your website status and review the checklist for general improvements.',
        doHide: w => !w.is_live
      },
    ],
  },
  {
    title: 'Advanced',
    color: 'color-purple',
    items: [
      {
        name: 'WP CLI',
        icon: 'commandLine',
        link: '/advanced/wp-cli',
        hoverText: 'Run a custom WP CLI command securely',
      },
      {
        name: 'WP Config File',
        icon: 'wpConfig',
        link: '/advanced/wp-config',
        hoverText: 'Define PHP constants inside the wp-config.php file',
      },
      {
        name: 'PHP Config',
        icon: 'php',
        link: '/advanced/php-config',
        hoverText: 'Control the PHP runtime configuration at the server level',
      },
      {
        name: 'Debug',
        icon: 'bug',
        link: '/debug',
        hoverText: 'Debug your website with various tools',
      },
      {
        name: 'Cron Manager',
        icon: 'cronJobs',
        link: '/advanced/cron-jobs',
        hoverText: 'Set up cron jobs at the server level',
      },
      {
        name: 'Nginx Config',
        icon: 'nginx',
        link: '/advanced/nginx-config',
        hoverText: 'Control the Nginx configuration at the server level',
      },
    ],
  },
  {
    title: 'Databases',
    color: 'color-reddish',
    items: [
      {
        name: 'Redis Object Cache',
        icon: 'redis',
        link: '/database/object-cache',
        hoverText: 'Speed up your website and reduce the number of database queries using Redis',
      },
      {
        name: 'Search & Replace',
        icon: 'databaseSearchReplace',
        link: '/advanced/search-replace',
        hoverText: 'Search and replace strings in the database',
      },
      {
        name: 'Search Only',
        icon: 'databaseSearch',
        link: '/advanced/search',
        hoverText: 'Find a string in the database',
      },
      {
        name: 'Tables',
        icon: 'databaseTable',
        link: '/advanced/tables',
        hoverText: 'View website database tables and their sizes',
      },
      {
        name: 'Run SQL Query',
        icon: 'SQLQueryRunner',
        link: '/database/run-sql-query',
        hoverText: 'Run SQL Queries in the database',
      },
    ],
  },
  {
    title: 'Metrics',
    color: 'color-blue',
    items: [
      {
        name: 'Traffic & Storage',
        icon: 'trafficAnalytics',
        link: '/analytics-traffic',
        hoverText: 'Track your website traffic and storage usage',
      },
      {
        name: 'PHP Workers',
        icon: 'systemAnalytics',
        link: '/analytics-php',
        hoverText: 'Track your website CPU and memory usage',
      },
      {
        name: 'User Activity',
        icon: 'activity',
        link: '/activity-history',
        hoverText: 'Track your team members activity on your website',
      },
      {
        name: 'Google Analytics',
        icon: 'googleAnalytics',
        link: '/google-analytics',
        doHide: () => !UserHelper.hasAccessToBetaFeatures(),
        doDisable: () => !UserHelper.isPartnerOrEmployee(),
        disableText: () => 'Not available for admin users',
        hoverText: 'Connect your website to Google Analytics',
      }
    ],
  },
  {
    title: 'Security',
    color: 'color-orange',
    items: [
      {
        name: 'Firewall',
        icon: 'security',
        link: '/banned-ips',
        hoverText: `Manage and view the IPs that have been blocked by the ${env.getBrandShortName()} firewalls`,
      },
      {
        name: 'Hide Login Page',
        icon: 'hide',
        link: '/hide-login',
        hoverText: 'Hide your website login page to prevent robots from trying to login to your website',
      },
      {
        name: 'Malware Scanner',
        icon: 'virusSearch',
        link: '/malware-scanner',
        hoverText: 'Scan your website for malware and clean it up',
        doDisable: () => true,
        disableText: () => 'Coming soon',
      },
    ],
  },
  {
    title: 'Account',
    color: 'color-green',
    items: [
      {
        name: 'Website Users',
        icon: 'users',
        link: '/users',
        hoverText: 'Manage team members access to your website',
        doHide: () => !UserHelper.isPartner(),
      },
      {
        name: 'Account / Billing',
        icon: 'billing',
        link: '/account-billing',
        hoverText: 'View billing profiles associated with your website',
        doHide: w => w.is_staging,
      },
      {
        name: 'SMTP',
        icon: 'email',
        link: '/advanced/smtp-mailer',
        hoverText: 'Configure SMTP settings for your website',
      },
    ],
  }
]

const WebsiteHelper = {
  isReady,
  isBusy,
  isDevMode,
  getDevDomain,
  getAlertLevel,
  staqPanelItems,
  getStateStyle,
  getStatusStyle,
  getCdnStatus,
  isValidSlug,
  getCdnCacheSsl,
  getCdnProxySsl,
  getCdnCache,
  getCdnProxy,
  getCloudflareZone,
  parseSSL,
  hasSupportedPageBuilder,
  urlFormats,
  sortingTypes,
  hasSslConnectionError,
  phpVersions,
  getAlerts,
  getLabel,
  findWebsite,
  tooltips,
  getCustomWPAdminUser,
  getPHPConfig,
  getNginxConfig,
  getHideLoginConfig,
  getCronJobs,
  getWPDefines,
  getURLRedirects,
  getSMTPMailerConfig,
  getObjectCacheConfig,
  getAutoUpdaterConfigOptions,
  goTo,
  goToStaqPanel,
  goToIndex,
  goToAdvanced,
  isActivePackage,
  getEnvName,
  getEnvId,
  getNginxConfigOptions,
  getHideLoginConfigOptions,
  getPhpConfigOptions,
  getRedisObjectCacheConfigOptions,
  getWPLoginURL,
  buildSelectOptions,
  buildQuickAccessOptions,
  createLink,
  createOneClickLoginBubble,
  createAlertIcon,
  dispatchGetWPLoginToken,
};

export default WebsiteHelper;
