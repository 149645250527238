import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { getOPcacheStats } from 'store/server/serverActions';
import { Content } from 'styles/globalStyles';
import { WPSButton } from 'styles/layout/buttons';
import { TitleBar, GroupTitle } from 'styles/layout/titlebar';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { serverBySlug } from 'store/server/serverSelectors';
import JsxHelper from 'helpers/jsx';
import StringHelper from 'helpers/string';
import WebsiteHelper from 'helpers/website';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { websitesByServer } from 'store/website/websiteSelectors';

const ServerOPcacheStats = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { params } = useRouteMatch();
  const [currentOPcacheName, setCurrentOPcacheName] = useState(false);
  const [currentOPcacheStatus, setCurrentOPcacheStatus] = useState(false);
  const [currentClusterConfig, setCurrentClusterConfig] = useState(false);
  const [currentPhpVersionWebsites, setCurrentPhpVersionWebsites] = useState([]);
  const [currentCachedWebsites, setCurrentCachedWebsites] = useState([]);
  const [avgWebsiteMemory, setAvgWebsiteMemory] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [statsData, setStatsData] = useState([]);
  const websites = useSelector(websitesByServer(params.slug));
  const server = useSelector(serverBySlug(params.slug));
  const mounted = useRef(true);
  const cardStyle = {marginBottom:'25px', justifyContent: 'center', fontSize:'12px'};
  const warningClasses = 'danger-font-color font-bold';

  const parseStatsData = (stats) => {
    setStatsData(stats);
    setActiveOPcache(Object.keys(stats)[0], stats);
  }

  const setActiveOPcache = (key, stats) => {
    if (!mounted.current) return;
    if (!key) return;
    stats = stats || statsData;
    setCurrentOPcacheName(key);
    let _tableData = [];
    const currentPhpVersion = stats[key].All.php_version;
    const phpVersionWebsites = websites.filter(w => currentPhpVersion.startsWith(w.php_version));
    let cachedWebsites = 0;
    let totalWebsiteMemory = 0;
    Object.keys(stats[key]).forEach((resource) => {
      if (['OS', 'All'].includes(resource)) return;
      if (phpVersionWebsites.find(w => w.slug === resource)) {
        cachedWebsites++;
        totalWebsiteMemory += stats[key][resource].used_memory;
      }
      let row = stats[key][resource];
      row.resource = resource;
      _tableData.push(row);
    });
    for (const w of phpVersionWebsites) {
      if (!stats[key][w.slug]) {
        window.logHelper.info(`No stats for ${w.slug} on ${currentPhpVersion}.`);
      }
    }
    setCurrentPhpVersionWebsites(phpVersionWebsites.length);
    setCurrentCachedWebsites(cachedWebsites)
    setCurrentOPcacheStatus(stats[key].All);
    setCurrentClusterConfig(stats[key].OS);
    setAvgWebsiteMemory(totalWebsiteMemory / cachedWebsites);
    setTableData(_tableData);
  }

  const fetchData = () => {
    setLoading(true);
    const data = { 
      server_slug: server.slug
    }
    dispatch(getOPcacheStats(data))
      .then(stats => parseStatsData(stats))
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchData();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const headers = [
    JsxHelper.createTableTextHeaderWithCallback(
      'resource',
      'Resource',
      '25%',
      (row) => {
        if (row.resource.includes(':')) {
          return row.resource;
        }
        return (
          <span className='class-link' onClick={() => WebsiteHelper.goTo(history, row.resource)}>
            {row.resource}
          </span>
        );
      },
    ),
    JsxHelper.createTableTextHeader('used_memory_nice', 'Used Memory', '20%'),
    JsxHelper.createTableTextHeaderWithCallback(
      'used_memory_nice',
      'Usage Rate',
      '20%',
      (row) => <span>{(row.used_memory / currentOPcacheStatus.used_memory * 100).toFixed(2)}%</span>
    ),
    JsxHelper.createTableTextHeader('num_of_scripts', 'Number of Scripts', '20%'),
    JsxHelper.createTableTextHeader('hits', 'Hits', '15%'),
  ];

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Servers',
      link: '/servers',
    },
    {
      text: params.slug,
    },
  ];

  return (
    <Fragment>
        <TitleBar>
          <TitleBar.Title breadcrumbs={breadcrumbs}>OPcache Statistics</TitleBar.Title>
          <TitleBar.Actions>
            <WPSButton
              className='refresh--btn'
              onClick={fetchData}
              disabled={loading}>
              Refresh
            </WPSButton>
            {JsxHelper.createBackButton()}
          </TitleBar.Actions>
        </TitleBar>
        <Content>
          <GroupTitle>
            <h3 style={{ textTransform: 'none' }}>{currentOPcacheName}</h3>
            <GroupTitle.Filters>
              {statsData && Object.keys(statsData).map((key) => (
                <button
                  key={key}
                  className={key === currentOPcacheName ? 'active' : ''}
                  onClick={() => setActiveOPcache(key)}>
                  {key}
                </button>
              ))}
            </GroupTitle.Filters>
          </GroupTitle>
          {currentOPcacheStatus && !loading && JsxHelper.createAnalyticsCards({
            customStyle: cardStyle,
            items: [{
              title: 'Status',
              value: currentOPcacheStatus.cache_full ? 'Full' : 'Available',
              className: currentOPcacheStatus.cache_full ? warningClasses : '',
            }, {
              title: 'Total Memory',
              value: currentOPcacheStatus.total_memory_nice,
            }, {
              title: 'Used Memory',
              value: `${currentOPcacheStatus.used_memory_nice} (${currentOPcacheStatus.used_memory_percentage_nice})`,
              className: currentOPcacheStatus.used_memory_percentage >= 99.99 ? warningClasses : '',
            }, {
              title: 'Wasted Memory',
              value: `${currentOPcacheStatus.wasted_memory_nice} (${currentOPcacheStatus.current_wasted_percentage_nice})`,
            }, {
              title: 'Cached Keys',
              value: `${currentOPcacheStatus.num_cached_keys} / ${currentOPcacheStatus.max_cached_keys}`,
              className: currentOPcacheStatus.num_cached_keys === currentOPcacheStatus.max_cached_keys ? warningClasses : '',
            }, {
              title: 'Total Interned Strings',
              value: `${currentOPcacheStatus.total_interned_strings_memory_nice} (${currentClusterConfig.opcache_interned_strings_buffer} MB)`,
            }, {
              title: 'Used Interned Strings',
              value: `${currentOPcacheStatus.used_interned_strings_memory_nice} (${currentOPcacheStatus.used_interned_strings_percentage_nice})`,
            }]
          })}
          {currentOPcacheStatus && !loading && JsxHelper.createAnalyticsCards({
            customStyle: cardStyle,
            items: [{
              title: 'Last Restart Time',
              value: currentOPcacheStatus.last_restart_time_nice === 'never' ? 'Never' : currentOPcacheStatus.last_restart_time_nice,
            }, {
              title: 'Start Time',
              value: currentOPcacheStatus.start_time_nice + ' UTC',
            }, {
              title: 'Hits',
              value: `${currentOPcacheStatus.hits} (${currentOPcacheStatus.opcache_hit_rate.toFixed(2)}%)`,
            }, {
              title: 'Misses',
              value: currentOPcacheStatus.misses,
            }, {
              title: 'Average Website Memory',
              value: avgWebsiteMemory > 0 ? StringHelper.convertBytes(avgWebsiteMemory) : 0,
            }, {
              title: 'Cached Websites',
              value: `${currentCachedWebsites} / ${currentPhpVersionWebsites} (${currentPhpVersionWebsites ? (currentCachedWebsites / currentPhpVersionWebsites * 100).toFixed(2) : 0}%)`,
            }, {
              title: 'System Memory',
              value: currentClusterConfig.total_memory_nice,
            }]
          })}
          <WPSDataTable
            dataKey='id'
            loading={loading}
            columns={headers}
            noSearchOnTable={false}
            body={tableData}
            rowsPerPage={100}
          />
        </Content>
    </Fragment>
  );
};

export default ServerOPcacheStats;
