import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { googleAnalyticsClientIdSelector, googleAnalyticsRedirectUri  } from 'store/platform/platformSelectors';
import { disconnectWebsiteGoogleAnalytics } from 'store/website/websiteActions';
import { useDispatch } from 'react-redux';
import JsxHelper from 'helpers/jsx';

const GoogleAnalyticsConnect = ({ website }) => {

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isConnected = website.ga_connected;
  const GA_CLIENT_ID = useSelector(googleAnalyticsClientIdSelector);
  const GA_REDIRECT_URI = useSelector(googleAnalyticsRedirectUri); // https://localhost:3000/oauth/google-analytics
  
  const redirectUri = (clientID, redirectURI) => {
    const scope = 'https://www.googleapis.com/auth/analytics.readonly';
    return `https://accounts.google.com/o/oauth2/v2/auth?scope=${scope}&access_type=offline&redirect_uri=${redirectURI}&response_type=code&client_id=${clientID}&state=${website.slug}`;
  }

  const disconnectAccount = () => {
    const data = { website_slug: website.slug };
    setLoading(true);
    dispatch(disconnectWebsiteGoogleAnalytics(data))
      .finally(() => setLoading(false));
  }

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Google Analytics</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader'>
      Connect your Google Analytics account to view your website's traffic data in the monthly generated reports.
      </p>
      <Content>
        {isConnected ? <Fragment>
          {/* Connected */}
          {JsxHelper.createButton({
            label: 'Disconnect Google Analytics',
            onClick: disconnectAccount,
            loading
          })}
        </Fragment> : <Fragment>
        {/* Disconnected */}
        {JsxHelper.createButton({
          label: 'Connect Google Analytics',
          onClick: () => window.location.href = redirectUri(GA_CLIENT_ID, GA_REDIRECT_URI)
        })}
        </Fragment>}
      </Content>
    </Container>
  );
};

export default GoogleAnalyticsConnect;
