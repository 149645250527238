import React, { useState } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSForm  } from 'styles/layout/forms';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { websitesSelector } from 'store/website/websiteSelectors';
import { globalWhiteLabelConfig } from 'store/setting/settingSelectors';
import { updateGlobalWhiteLabelConfig } from 'store/setting/settingActions';
import { partnerSelector } from 'store/me/meSelectors';
import JsxHelper from 'helpers/jsx';
import env from 'config/env';
import WebsiteHelper from 'helpers/website';
import DialogHelper from 'helpers/dialog';
import useConfirm from 'hooks/useConfirm';
import { isEmptyOrNull } from 'helpers';
import useModal from 'hooks/useModal';

const WhiteLabel = () => {
  useTitle('White Label');
  const BREADCRUMBS = JsxHelper.createBreadcrumbs('White Label', 'settings');
  const allWebsites = useSelector(websitesSelector);
  const WEBSITE_OPTIONS = WebsiteHelper.buildSelectOptions(allWebsites);
  const globalConfig = useSelector(globalWhiteLabelConfig);
  const partnerAccount = useSelector(partnerSelector);
  const [loading, setLoading] = useState(false);
  const confirm = useConfirm();
  const modalDialog = useModal();
  const dispatch = useDispatch();

  const [details, setDetails] = useState({
    primary_color: globalConfig.primary_color || '#002B94',
    secondary_color: globalConfig.secondary_color || '#03a9f4',
    plugin_name: globalConfig.plugin_name || '',
    include_websites: globalConfig.include_websites || [],
    exclude_websites: globalConfig.exclude_websites || null,
    main_logo_url: globalConfig.main_logo_url || null,
    admin_menu_icon_url: globalConfig.admin_menu_icon_url || null,
    // States for local image preview and file upload
    main_logo_name: globalConfig.main_logo_name || '',
    main_logo_file: '',
    admin_menu_icon_name: globalConfig.admin_menu_icon_name || '',
    admin_menu_icon_file: '',
  });

  const handleOnChange = e => {
    const { name } = e.target;
    if (['include_websites', 'exclude_websites'].includes(name)) {
      const selectedOptions = (e.target.values || []).map(item => item.value);
      setDetails(prev => ({
        ...prev,
        [name]: selectedOptions.includes('all') ? ['all'] : selectedOptions,
      }));
      return;
    }
    setDetails(prev => ({ ...prev, [name]: e.target.value }));
  };

  const isImageWidthValid = (file, width) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width > width) {
          reject(`Width must be under ${width}px (current: ${img.width}px)`);
        } else {
          resolve();
        }
      };
      img.src = URL.createObjectURL(file);
    });
  };

  // Handle logo upload
  const handleFileUpload = async (e, maxWidth) => {
    const { files, name } = e.target;
    if (files[0] !== undefined) {
      try {
        await isImageWidthValid(files[0], maxWidth);
      } catch (error) {
        DialogHelper.error(
          modalDialog,
          error,
          `Resize your images easily with free tools like <a class='box-link' href="https://imageresizer.com" target="_blank">Image Resizer</a>.`,
        );
        return;
      }

      // Save file to state
      setDetails(prev => ({ ...prev, [`${name}_name`]: files[0].name }));
      setDetails(prev => ({ ...prev, [`${name}_file`]: files[0] }));

      // Display selected image.
      const fr = new FileReader();
      fr.readAsDataURL(files[0]);
      fr.addEventListener('load', () => setDetails(prev => ({ ...prev, [`${name}_url`]: fr.result })));
    } else {
      setDetails(prev => ({ ...prev, [`${name}_name`]: '' }));
      setDetails(prev => ({ ...prev, [`${name}_file`]: '' }));
    }
  };

  const saveWithConfirmation = () => {
    if (details.include_websites.includes('all')) {
      DialogHelper.confirmAction(
        confirm,
        'save',
        '',
        'Settings',
        <p>You are about to enable white label for all websites. We strongly recommend testing the changes on a single website first before applying them across all websites.<br/><br/>Please note that the process may take a few minutes to complete.</p>,
      ).then(submitGlobalSettings)
    } else {
      submitGlobalSettings();
    }
  }

  const submitGlobalSettings = () => {
    let formData = new FormData();
    const _details = { ...details };
    if (isEmptyOrNull(_details.exclude_websites)) {
      delete _details.exclude_websites;
    }
    formData.append('partner_slug', partnerAccount.slug);
    for (let key in _details) {
      if (Array.isArray(_details[key])) {
        for (let value of _details[key]) {
          formData.append(`config[${key}][]`, value);
        }
      } else {
        formData.append(`config[${key}]`, _details[key]);
      }
    }
    setLoading(true);
    dispatch(updateGlobalWhiteLabelConfig(formData))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'White label settings', action: 'updated' })))
      .finally(() => setLoading(false));
  };

  return (
  <div className='global-settings'>
    <TitleBar>
      <TitleBar.Title breadcrumbs={BREADCRUMBS}>White Label</TitleBar.Title>
      <TitleBar.Actions>{JsxHelper.createBackButton()}</TitleBar.Actions>
    </TitleBar>
    <p className='color-primary subheader'>
      {env.getBrandShortName()} allows you to customize the branding of the built-in hosting plugin on your clients' websites.
    </p>
    <Content>
      <WPSForm style={{maxWidth: '35%'}}>
        <WPSForm.Row>
        <WPSForm.RowItem style={{minWidth: '270px'}}>
            {JsxHelper.createFileInput({
              name: 'main_logo',
              label: 'Main Logo',
              tooltip: `Replaces the ${env.getBrandShortName()} logo with your own logo in the hosting plugin management area.`,
              image_url: details.main_logo_url,
              onChange: handleFileUpload,
              style: { marginBottom: '6px' },
            })}
          </WPSForm.RowItem>
          <WPSForm.RowItem style={{minWidth: '270px'}}>
            {JsxHelper.createFileInput({
              name: 'admin_menu_icon',
              label: 'Menu Icon (20x20)', 
              tooltip: `Replaces the ${env.getBrandShortName()} icon with your own icon in the WordPress admin menu.`,
              image_url: details.admin_menu_icon_url,
              onChange: (e) => handleFileUpload(e, 20),
              style: { marginBottom: '6px' },
            })}
          </WPSForm.RowItem>
        </WPSForm.Row>
        <WPSForm.Row>
          <WPSForm.RowItem style={{minWidth: '270px'}}>
            {JsxHelper.createColorInput({
              label: 'Primary Color',
              name: 'primary_color',
              color: details.primary_color,
              onChange: (color) => setDetails(prev => ({ ...prev, primary_color: color })),
              tooltip: `Replaces the primary color of the ${env.getBrandShortName()} Hosting plugin in the management area.`,
            })}
          </WPSForm.RowItem>
          <WPSForm.RowItem style={{minWidth: '270px'}}>
            {JsxHelper.createColorInput({
              label: 'Secondary Color',
              name: 'secondary_color',
              color: details.secondary_color,
              onChange: (color) => setDetails(prev => ({ ...prev, secondary_color: color })),
              tooltip: `Replaces the secondary color of the ${env.getBrandShortName()} Hosting plugin in the management area.`,
            })}
          </WPSForm.RowItem>
        </WPSForm.Row>
        <WPSForm.Row>
          <WPSForm.RowItem>
            {JsxHelper.createTextInput({
              name: 'plugin_name',
              label: 'Plugin Name',
              value: details.plugin_name,
              placeholder: `Replace "${env.getBrandShortName()} Hosting" with your own plugin name`,
              onChange: handleOnChange,
              tooltip: `Replace the plugin name in the ${env.getBrandShortName()} Hosting plugin in the management area.`,
            })}
          </WPSForm.RowItem>
        </WPSForm.Row>
        <WPSForm.Row>
          <WPSForm.RowItem>
            {JsxHelper.createSelectInput({
              label: 'Enable for Websites',
              tooltip: `Enable white label for specific websites. Select "All Websites" to enable white label for all websites.`,
              name: 'include_websites',
              value: details.include_websites,
              options: [{ label: 'All Websites', value: 'all' }, ...WEBSITE_OPTIONS],
              onChange: handleOnChange,
              sortOff: true,
              multiSelect: true,
            })}
          </WPSForm.RowItem>
        </WPSForm.Row>
        <WPSForm.Row>
          <WPSForm.RowItem>
            {JsxHelper.createSelectInput({
              label: 'Disable for Websites',
              tooltip: 'Disable white label for specific websites if "All Websites" is selected in the previous setting.',
              name: 'exclude_websites',
              value: details.exclude_websites,
              onChange: handleOnChange,
              options: WEBSITE_OPTIONS,
              multiSelect: true,
            })}
          </WPSForm.RowItem>
        </WPSForm.Row>
        <div className='action-buttons display-flex-nowrap'>
          {JsxHelper.createButton({ label: 'Save', loading, onClick: saveWithConfirmation, })}
        </div>
      </WPSForm>
    </Content>
  </div>
  );
};

export default WhiteLabel;
