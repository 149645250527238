import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { websitesSelector } from 'store/website/websiteSelectors';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { useHistory } from 'react-router-dom';
import { Content } from 'styles/globalStyles';
import ReportService from 'services/report';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import TableHelper from 'helpers/table';
import PackageHelper from 'helpers/package';
import WebsiteHelper from 'helpers/website';
import { setGlobalInfoMsg } from 'store/global/globalActions';
import ObjectHelper from 'helpers/object';
import JsxHelper from 'helpers/jsx';
import { installPackage } from 'store/website/websiteActions';
import { isEmpty, getErrorMsg } from 'helpers';
import useModal from 'hooks/useModal';

const BulkUpdatePackages = () => {
  const breadcrumbs = JsxHelper.createBreadcrumbs('Bulk Plugin / Theme Updates', 'tools');
  const dispatch = useDispatch();
  const history = useHistory();
  const modalDialog = useModal();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingWhileLogin, setLoadingWhileLogin] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updatesQueue, setUpdatesQueue] = useState([]);
  const [updatingAll, setUpdatingAll] = useState(false);
  const allWebsites = useSelector(websitesSelector);
  const mounted = useRef(true)

  useEffect(() => {
    if (updatingAll) {
      updateQueueItem();
    }
    // eslint-disable-next-line
  }, [updatesQueue]);

  useEffect(() => {
    fetchPackages();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const normalizeRow = (row) => {
    const websiteSlug = row.website_slug;
    const _row = PackageHelper.normalizeData(row, []);
    return {
      ..._row,
      website_slug: websiteSlug,
      url: _row.latest_version_url,
      update_status: null,
      _from: _row.version, // Copy in order to keep the original from-to values after updates.
      _to: _row.latest_version,
    };
  }

  const fetchPackages = () => {
    const data = {
        limit: 1000,
        search: null,
        updatable_only: true,
    }
    setLoading(true);
    ReportService.filterAppsInstalledPacakges(data)
      .then((res) => setTableData(res.map(normalizeRow).filter(p => PackageHelper.isUnmanaged(p) && p.url)))
      .finally(() => setLoading(false));
  }

  const headers = [
    PackageHelper.renderTypeHeader(' '),
    {
      name: 'Name',
      selector: 'display_name',
      searchable: true,
      width: '25%',
      cell: row => {
        TableHelper.customizeCellValue(row, 'display_name', row.display_name + ':' + (row.website_slug || ''));
        const website = WebsiteHelper.findWebsite(allWebsites, row.website_slug);
        return JsxHelper.createTableMultiLineCell({
          header: row.display_name || row.name,
          subheader: row.website_slug ? row.website_slug : `Global ${row.type}`,
          subheaderLink: row.website_slug ? `/websites/${row.website_slug}` : `/${row.type}s`,
          subheaderClass: 'inactive-font-color',
          subheaderPostHTML: WebsiteHelper.createOneClickLoginBubble(website, loadingWhileLogin, setLoadingWhileLogin, dispatch, modalDialog),
          mainClass: 'website-display-actions',
        })
      }
    },
    PackageHelper.renderVersionUpdateHeader(),
    PackageHelper.renderUpdateStatusHeader(),
    {
      name: '',
      selector: 'version',
      width: '43%',
      cell: (row) => !row.update_status
      ? TableHelper.createActionIcon('close', () => setTableData(prev => prev.filter(p => p.slug !== row.slug)))
      : (row.update_status === PackageHelper.STATUS_FAILED ? TableHelper.createActionIcon('refresh', () => runPackageUpdater(row)) : null),
    },
  ];

  // ------------------------------
  // UPDATE PACKAGE
  // ------------------------------

  const runPackageUpdater = (item, callback) => {
    // Get the website for the package to update.
    const website = WebsiteHelper.findWebsite(allWebsites, item.website_slug);
    if (!website) {
      callback && callback('Website not found.');
      return;
    }
    // Call the API to update the package.
    const data = {
      website_slug: website.slug,
      type: item.type,
      src: item.latest_version_url,
      folder_name: item.folder_name,
    };
    setUpdateLoading(true);
    dispatch(installPackage(data))
      .then((_package) => callback && callback(null, _package))
      .catch(err => callback && callback(getErrorMsg(err)))
      .finally(() => setUpdateLoading(false));
  }

  // ------------------------------
  // UPDATE MANAGER
  // ------------------------------

  // Callback when the update is finished.
  const updateQueueItemStatus = (item, error) => {
    setTableData(prev => PackageHelper.setUpdaterItemStatus(prev, item, error));
    setUpdatesQueue(prev => prev.slice(1));
  }

  const updateQueueItem = (item) => {
    item = item || ObjectHelper.first(updatesQueue);
    if (item) {
      // Update status to updating
      window.logHelper.info(`Updating "${item.folder_name}" ${item.type}...`, item);
      setTableData(prev => prev.map(p => {
        if (p.slug === item.slug) {
          p.update_status = PackageHelper.STATUS_UPDATING;
        }
        return p;
      }));
      // Run the updater.
      runPackageUpdater(item, (error) => updateQueueItemStatus(item, error));
    } else {
      setUpdatingAll(false);
    }
  }

  const updateAll = () => {
    // If updating all, then check if there is an update in progress.
    if (tableData.find(p => p.update_status === PackageHelper.STATUS_UPDATING)) {
      dispatch(setGlobalInfoMsg('Please wait until the current update finishes.'));
      return;
    }
    setUpdatingAll(true);
    setUpdatesQueue(tableData.filter(p => p.latest_version && p.status !== PackageHelper.STATUS_UPDATED));
    // Set all packages that are not updated or updating to queued.
    setTableData(prev => prev.filter(p => p.latest_version).map(p => {
      if (p.latest_version && p.update_status !== PackageHelper.STATUS_UPDATED && p.update_status !== PackageHelper.STATUS_UPDATING) {
        p.update_status = PackageHelper.STATUS_QUEUED;
      }
      return p;
    }));
  }

  const stopUpdates = () => {
    setUpdatesQueue([]);
    setUpdatingAll(false);
    setTableData(prev => prev.map(p => {
      if (p.update_status === PackageHelper.STATUS_QUEUED) {
        p.update_status = null;
        p.update_error = null;
      }
      return p;
    }));
    window.logHelper.info(`Stopped updating website packages.`);
  }

  // ------------------------------
  // RENDER
  // ------------------------------

  return (
    <Container className='margin-0'>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Bulk Plugin / Theme Updates</TitleBar.Title>
        <TitleBar.Actions>
          {updatingAll && JsxHelper.createButton({
            label: 'Stop',
            onClick: stopUpdates,
            disabled: !tableData.some(p => p.update_status === PackageHelper.STATUS_QUEUED), // Disable if no queued items.
          })}
          {!updatingAll && !isEmpty(tableData) && JsxHelper.createButton({
            label: 'Update All',
            onClick: updateAll,
            disabled: updateLoading,
          })}
          {JsxHelper.createButton({
            label: 'Back',
            onClick: history.goBack,
          })}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable
          customClass='package-history-table'
          columns={headers}
          body={tableData}
          loading={loading}
          rowsPerPage={100}
          dataKey={'key'}
        />
      </Content>
    </Container>
  );
};

export default BulkUpdatePackages;
