import React, { useEffect, useState } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { useQueryResult } from 'hooks/useQuery';
import { useDispatch } from 'react-redux';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { useHistory } from 'react-router-dom';
import { connectWebsiteGoogleAnalytics } from 'store/website/websiteActions';
import WebsiteHelper from 'helpers/website';
import JsxHelper from 'helpers/jsx';

const GoogleAnalyticsCallback = () => {
  const query = useQueryResult();
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState(null);

  const connectAccount = async (code, websiteSlug) => {
    const data = { code, website_slug: websiteSlug };
    dispatch(connectWebsiteGoogleAnalytics(data))
      .then(() => WebsiteHelper.goTo(history, websiteSlug, 'google-analytics'))
      .catch(err => {
        dispatch(setGlobalErrorMsg(err))
        setError(err);
      })
  };

  useEffect(() => {
    if (query.scope && query.code) {
        connectAccount(query.code, query.state);
    }
  }, []); //eslint-disable-line

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Google Analytics</TitleBar.Title>
      </TitleBar>
      <Content>
        {error ? JsxHelper.createBox({
          level: 'error',
          content: 'An error occurred while connecting your Google Analytics account. Please try again.'
        }) : <p>Connecting your Google Analytics account...</p>}
      </Content>
    </Container>
  );
};

export default GoogleAnalyticsCallback;
