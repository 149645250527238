import React from 'react';
import GoogleAnalyticsConnect from 'components/googleAnalytics/GoogleAnalyticsConnect';

const GoogleAnalytics = ({ website }) => {
  return (
    <GoogleAnalyticsConnect website={website} />
  );
};

export default GoogleAnalytics;
