import React, { useState, Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { WPSTextArea, WPSLabel, WPSForm, ErrorMsg } from 'styles/layout/forms';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy } from 'lodash';
import { globalThemesSelector, globalCoresSelector } from 'store/globalPackage/globalPackageSelector';
import { partnerThemesSelector } from 'store/partnerPackage/partnerPackageSelector';
import { globalRegionsSelector } from 'store/region/regionSelectors';
import { partnerSelector } from 'store/me/meSelectors';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { customersSelector } from 'store/customer/customerSelectors';
import { activeSubscriptionsByType, activeSubscriptions, plansByCategoryType } from 'store/billing/billingSelectors';
import { customerWebsitePlansSelector, nonAssignedCustomerWebsitePlans } from 'store/customerBilling/customerBillingSelectors';
import { useHistory } from 'react-router-dom';
import { isEmpty, isEmptyOrNull } from 'helpers';
import UserHelper from 'helpers/user';
import PlanHelper from 'helpers/plan';
import WebsiteHelper from 'helpers/website';
import { OrderedList } from 'styles/layout/lists';
import { useRef } from 'react';
import MigratorService from 'services/migrator';
import useTitle from 'hooks/useTitle';
import { migratorPluginUrlSelector } from 'store/platform/platformSelectors';
import { partnersSelector } from 'store/user/userSelectors';
import { integrationSelector } from 'store/me/meSelectors';
import JsxHelper from 'helpers/jsx';
import { sharedServersSelector } from 'store/server/serverSelectors';
import 'components/stepper/stepper.css';
import env from 'config/env';
import WebsiteOptionsTrait from './WebsiteOptionsTrait';
import useModal from 'hooks/useModal';
import { websitesSelector } from 'store/website/websiteSelectors';

const WebsiteMigrate = () => {
  useTitle('Website Migrate');
  const dispatch = useDispatch();
  const history = useHistory();
  const modalDialog = useModal();
  const STEPS_NUM = 3;
  const stepperMigrateSiteRef = useRef();
  const { register, handleSubmit, errors, triggerValidation } = useForm({ reValidateMode: 'onSubmit' });
  const [modal, setModal] = useState(false);
  const integrations = useSelector(integrationSelector);
  const isStripeConnected = integrations.stripe ? true : false;
  const [customerDetails, setCustomerDetails] = useState(WebsiteOptionsTrait.EMPTY_CUSTOMER_DETAILS);
  const [customerLoading, setLoadingCustomer] = useState(false);

  // If two or more folder_names are the same then the priority order is maintained
  // (private > partner > global) but the appearance order is reversed (global first).
  const globalThemes = useSelector(globalThemesSelector);
  const partnerThemes = useSelector(partnerThemesSelector);
  const globalCores = sortBy(useSelector(globalCoresSelector), p => p.created_at).reverse();
  const regionSelectOptions = useSelector(globalRegionsSelector);
  const partner = useSelector(partnerSelector);
  const partnerWebsitePlans = useSelector(plansByCategoryType('website'));
  const customers = useSelector(customersSelector);
  const customerPlans = useSelector(customerWebsitePlansSelector);
  const nonAssignedPlans = useSelector(nonAssignedCustomerWebsitePlans);
  const websiteActiveSubscriptions = useSelector(activeSubscriptionsByType('website'));
  const partnersSubscriptions = useSelector(activeSubscriptions());
  const migratorPluginUrl = useSelector(migratorPluginUrlSelector);
  const [partnerPlans, setPartnerPlans] = useState([]);
  const partners = useSelector(partnersSelector);
  const [customersTrigger, setCustomersTrigger] = useState(1);
  const [siteURLError, setSiteURLError] = useState(false);
  const [siteURLErrorMsg, setSiteURLErrorMsg] = useState('');
  const [generatedToken, setGeneratedToken] = useState('');
  const [tokenLoading, setTokenLoading] = useState(false);
  const payAsYouGoServers = useSelector(sharedServersSelector);
  const [selectedPlan, setSelectedPlan] = useState(false);
  const allWebsites = useSelector(websitesSelector);

  // Admin: select the available partner plans when a partner is selected
  const initSelectedPartnerInputs = partnerSlug => {
    // Find partner
    const partner = partners.find(p => p.slug === partnerSlug);
    if (isEmptyOrNull(partner)) {
      setPartnerPlans([]);
      return;
    }
    // Get all partner subscriptions
    const partnerSubscriptions = partnersSubscriptions ? partnersSubscriptions.filter(
      s => s.subscriber_slug === partnerSlug,
    ) : [];
    // Filter subscribable plans
    const _partnerPlans = PlanHelper.filterSubscribableWebsitePlans(partnerWebsitePlans, partnerSubscriptions, partnerSlug);
    setPartnerPlans(_partnerPlans);
    // Set website partner
    setWebsite(prev => ({
      ...prev,
      wp_admin_user: WebsiteHelper.getCustomWPAdminUser(partner),
      wp_admin_user_email: partner.email,
    }));
  };

  const doHideToCustomerStep = () => UserHelper.isAdminOrAgent() || !isStripeConnected;

  const initStep = stepNum => {
    const stepsCount = STEPS_NUM - (doHideToCustomerStep() ? 1 : 0);
    WebsiteOptionsTrait.initStep(stepperMigrateSiteRef, refDisabledSteps, stepNum, stepsCount, setCurrentStepForData);
  };

  // migrate site stepper
  /* eslint-disable no-unused-vars */
  const [stepperSteps, setStepperSteps] = useState(
    [
      {
        title: 'Connect To Site',
        onClick: () => initStep(1),
      },
      {
        title: 'Choose Plan',
        onClick: () => initStep(2),
      },
      !doHideToCustomerStep() && {
        title: 'Assign Client',
        onClick: () => initStep(3),
      },
    ].filter(Boolean)
  );
  const [currentStep, setCurrentStep] = useState(1);
  const CustomerStatusSteps = doHideToCustomerStep() ? [1] : [1, 2];
  const [disabledStepsData, setDisabledStepsData] = useState([...CustomerStatusSteps]);
  const [currentStepForData, setCurrentStepForData] = useState(1);
  const refDisabledSteps = useRef([...CustomerStatusSteps]);

  const defaultWebsiteData = WebsiteOptionsTrait.initWebsiteData(
    partner,
    regionSelectOptions,
    customerPlans,
    partnerThemes,
    globalThemes,
    globalCores
  );
  const [website, setWebsite] = useState(defaultWebsiteData);
  const [customerPlansList, setCustomerPlansList] = useState();

  // Website migration states.
  const [websiteExist, setWebsiteExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [websiteExistError, setWebsiteExistError] = useState(false);
  const [websiteExistLoading, setWebsiteExistLoading] = useState(false);
  const [exportOptions, setExportOptions] = useState({
    copy_link: '',
    export_config: {},
  });

  // Set the free trial notice for partner whenever a new subscription is set
  useEffect(() => {
    WebsiteOptionsTrait.onPartnerPlanSelection(
      website.partner_plan_slug,
      websiteActiveSubscriptions,
      partnerPlans,
      setSelectedPlan,
      setWebsite
    );
    // eslint-disable-next-line
  }, [website.partner_plan_slug, partnerPlans]);

  // Fetch all partners subscriptions on component mount.
  useEffect(() => {
    if (UserHelper.isAdminOrAgent()) {
      WebsiteOptionsTrait.fetchPartnerSubscriptionsForAdmins(partnersSubscriptions, dispatch);
    } else {
      const _partnerPlans = PlanHelper.filterSubscribableWebsitePlans(partnerWebsitePlans, websiteActiveSubscriptions);
      setPartnerPlans(_partnerPlans);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!UserHelper.isAdminOrAgent()) {
      if (website.customer_slug) {
        // Selected customer plans
        const selectedCustomerPlans = customerPlans.filter(
          plan => plan.assignee_slug === website.customer_slug,
        );
        const allPlans = [...nonAssignedPlans, ...selectedCustomerPlans];
        setCustomerPlansList(allPlans);
      } else {
        setCustomerPlansList(nonAssignedPlans);
      }
    }
  }, [customerPlans, nonAssignedPlans, website.customer_slug]);

  useEffect(() => {
    // Admins must select the partner before generating tokens
    if (!generatedToken && !UserHelper.isAdminOrAgent()) {
      generateUserToken();
    }
    // eslint-disable-next-line
  }, []);

  const handleOnChange = e => {
    const { name } = e.target;
    let value = '';
    if (name === 'partner_slug') {
      // If an admin changes partner then the selected partner's subscriptions
      // must be updated and the token has to be regenerated
      value = e.target.value;
      initSelectedPartnerInputs(value);
      generateUserToken(value);
    } else if (name === 'region_slug') {
      value = e.target.id;
    } else if (name === 'copy_link') {
      value = e.target.value;
      setExportOptions(prev => ({ ...prev, copy_link: value.trim() }));
      return; // Has nothing to do with setWebsite
    } else {
      if (name === 'website_slug') {
        value = e.target.value.toLowerCase();
      } else {
        value = e.target.value;
      }
    }
    setWebsite(prevState => ({ ...prevState, [name]: value }));
  };

  const onDeploySite = () => WebsiteOptionsTrait.onMigrate(websiteExist, website, history, dispatch, setLoading, exportOptions);

  const handleWebsiteDeployStep = (e) => {
    e.preventDefault();
    WebsiteOptionsTrait.onWebsiteIdBlur(website, setWebsiteExistLoading, websiteExistLoading, setWebsiteExistError, setWebsiteExist, moveToThirdStep, modalDialog);
  };

  const moveToSecondStep = () => {
    if (!isEmpty(refDisabledSteps.current)) {
      changeSteps(2);
      refDisabledSteps.current = [2];
      setCurrentStepForData(2);
      setDisabledStepsData([2]);
    }
    initStep(2);
  };

  const moveToThirdStep = () => {
    if (!isEmpty(refDisabledSteps.current)) {
      changeSteps(3);
      refDisabledSteps.current = [3];
      setCurrentStepForData(3);
      setDisabledStepsData([3]);
    }
    initStep(3);
  };

  const handleMigrateWebsiteFirstStep = () => {
    setSiteURLError(false);
    setSiteURLErrorMsg('');

    // Check if link includes "wpstaq_" and "dbTablePrefix"
    if (isEmptyOrNull(exportOptions.copy_link) || exportOptions.copy_link.length < 10) {
      setSiteURLError(true);
      setSiteURLErrorMsg('The code is invalid.');
      return;
    }
    moveToSecondStep();
  };

  const changeSteps = newIndex => {
    setCurrentStep(newIndex);
    let data = disabledStepsData;
    data.push(currentStep - 1);
    let fitleredSteps = data.filter(el => el !== newIndex - 1);
    setDisabledStepsData(fitleredSteps);
  };

  const generateUserToken = (partnerSlug) => {
    let data = {};
    if (UserHelper.isAdminOrAgent()) {
      if (!partnerSlug) {
        setGeneratedToken('');
        return; // Admins must select partner before generating token
      }
      data.partner_slug = partnerSlug;
    }
    setTokenLoading(true);
    MigratorService.generateToken(data)
      .then(res => {
        setGeneratedToken(res.token);
      })
      .catch(() => {
        dispatch(setGlobalErrorMsg('Failed to generate the token.'));
      })
      .finally(() => {
        setTokenLoading(false);
      });
  };

  const breadcrumbs = JsxHelper.createBreadcrumbs('Migrate Website', 'website');

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Migrate Website</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content style={{ position: 'relative' }}>
        <div
          className='create-site-stepper'
          style={
            doHideToCustomerStep()
              ? { left: '-110px', maxWidth: '800px' }
              : { left: '-45px', maxWidth: '800px' }
          }
          ref={stepperMigrateSiteRef}>
          {JsxHelper.createStepper(stepperSteps, currentStep, disabledStepsData)}
        </div>
        <div style={{ maxWidth: '1000px', marginTop: '130px' }}>
          {currentStepForData === 1 && (
            <Fragment>
              <WPSForm.Fieldset>
                <legend>Instructions</legend>
                <WPSForm.Row>
                  <OrderedList className='ol-instructions'>
                    <li>
                      <span>1.</span> Download&nbsp;
                      <a className='link' href={migratorPluginUrl}>
                        {env.getBrandShortName()} Migrator Plugin
                      </a>
                      &nbsp; and upload it to your original site.
                    </li>
                    <li>
                      <span>2.</span>
                      Install the plugin and activate it.
                    </li>
                    <li>
                      <span>3.</span>
                      Click '{env.getBrandShortName()} Migrator' menu item.
                    </li>
                    <li>
                      <span>4.</span>
                      Copy the export token below and paste it into the plugin's input.
                    </li>
                    <li>
                      <span>5.</span>
                      Wait until the export process is finished.
                    </li>
                    <li>
                      <span>6.</span>
                      Click "Copy Code" button and paste the copied text in the input below.
                    </li>
                  </OrderedList>
                </WPSForm.Row>
              </WPSForm.Fieldset>
              {WebsiteOptionsTrait.renderSelectPartnerForAdmins(website, partners, errors, register, handleOnChange)}
              <WPSForm.Fieldset>
                <legend>Export Token</legend>
                <div style={{ position: 'relative', width: '52%' }}>
                  <WPSTextArea
                    id='generated-token'
                    rows='6'
                    disabled
                    style={{ width: '100%', marginBottom: '15px', resize: 'none', padding: '5px' }}
                    value={generatedToken}
                    onChange={e => setGeneratedToken(e.target.value)}></WPSTextArea>
                  {tokenLoading && (
                    <div className='center-spinner-loader'>
                      <div className='loadingspinner'></div>
                    </div>
                  )}
                </div>
                {generatedToken && !tokenLoading ? JsxHelper.createCopyButton({
                  label: 'Copy Token',
                  type: 'token',
                  value: generatedToken,
                  dispatch,
                }) : null}
              </WPSForm.Fieldset>
              <WPSForm.Fieldset>
                <legend>Import Token</legend>
                <WPSForm.Row>
                  <WPSForm.RowItem className='custom-form-row-item'>
                    <WPSLabel
                      htmlFor='copy_link'
                      className='margin-bottom-12'
                      style={{ marginTop: '0' }}>
                      Enter the copied text from WordPress admin dashboard:
                    </WPSLabel>
                    <WPSTextArea
                      name='copy_link'
                      id='copy_link'
                      placeholder='Paste the copied text here'
                      rows='6'
                      style={{
                        resize: 'none',
                        padding: '8px',
                      }}
                      value={exportOptions.copy_link}
                      onChange={handleOnChange}></WPSTextArea>
                    {siteURLError && (
                      <ErrorMsg className='margin-top-5'>{siteURLErrorMsg}</ErrorMsg>
                    )}
                  </WPSForm.RowItem>
                </WPSForm.Row>
              </WPSForm.Fieldset>
              <div className='display-flex-center-start'>
                {WebsiteOptionsTrait.renderLightButton(
                  'Next',
                  handleMigrateWebsiteFirstStep,
                  loading,
                  isEmpty(generatedToken) || exportOptions.copy_link.length < 10
                )}
              </div>
            </Fragment>
          )}
          {currentStepForData === 2 && (
            <WPSForm noValidate style={{ maxWidth: '1000px' }}>
              <Fragment>
                {WebsiteOptionsTrait.renderRegionsAndZones(handleOnChange)}
                <WPSForm.Row className='display-block' style={{ marginLeft: '2px' }}>
                  {WebsiteOptionsTrait.renderEnterAppID(website, websiteExist, websiteExistError, handleOnChange)}
                  {WebsiteOptionsTrait.renderPricingPlansForPartner(website, partnerPlans, handleOnChange)}
                  {WebsiteOptionsTrait.renderPricingPlansForAdmins(website, partnerPlans, handleOnChange)}
                  {WebsiteOptionsTrait.renderServersForAdmins(website, payAsYouGoServers, selectedPlan, register, handleOnChange, allWebsites)}
                </WPSForm.Row>
              </Fragment>
              {!UserHelper.isAdminOrAgent() && !doHideToCustomerStep()
                ? WebsiteOptionsTrait.renderLightButton('Next', handleWebsiteDeployStep, websiteExistLoading)
                : WebsiteOptionsTrait.renderLightButton('Deploy', handleSubmit(onDeploySite), loading)
              }
            </WPSForm>
          )}
          {currentStepForData === 3 && (
            <Fragment>
              {!UserHelper.isAdminOrAgent() && (
                <Fragment>
                  {WebsiteOptionsTrait.renderAssignClient(
                    website,
                    customers,
                    customersTrigger,
                    handleOnChange,
                    setModal
                  )}
                  {website.customer_slug ? WebsiteOptionsTrait.renderAgencyPlans(
                    website,
                    customerPlansList,
                    handleOnChange
                  ) : null}
                </Fragment>
              )}
              {WebsiteOptionsTrait.renderLightButton('Deploy', handleSubmit(onDeploySite), loading)}
            </Fragment>
          )}
        </div>
      </Content>
      {modal && WebsiteOptionsTrait.createNewClientModal(
        loading,
        customerLoading,
        customerDetails,
        errors,
        register,
        setModal,
        handleSubmit(() => WebsiteOptionsTrait.createCustomer(
          customerDetails,
          setModal,
          setCustomersTrigger,
          setLoadingCustomer,
          triggerValidation,
          setWebsite,
          dispatch
        )),
        setCustomerDetails
      )}
    </Fragment>
  );
};

export default WebsiteMigrate;
