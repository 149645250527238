import React, { useEffect, useState, useRef, Fragment } from 'react';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch } from 'react-redux';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { useHistory } from 'react-router-dom';
import PackageHelper from 'helpers/package';
import JsxHelper from 'helpers/jsx';
import ReportService from 'services/report';
import DialogHelper from 'helpers/dialog';
import StringHelper from 'helpers/string';
import UserHelper from 'helpers/user';
import useConfirm from 'hooks/useConfirm';
import env from 'config/env';
import 'components/package/packages.css';
import WebsiteHelper from 'helpers/website';
import { setGlobalSuccessMsg, setGlobalInfoMsg, setGlobalPleaseWaitMsg } from 'store/global/globalActions';
import { installPackage, uninstallPackage } from 'store/website/websiteActions';

const WebsiteAlerts = ({ website }) => {
  useTitle('Website Alerts');
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [updatingPackages, setUpdatingPackages] = useState([]);
  const [deletingPackages, setDeletingPackages] = useState([]);
  const [tablePackages, setTablePackages] = useState(null);
  const confirm = useConfirm();
  const mounted = useRef(true);

  useEffect(() => {
    fetchWebsiteAlerts();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  // --- API Calls ---

  const fetchWebsiteAlerts = () => {
    const data = {
      website_slugs: [website.slug],
      include_vulnerability: true,
    }
    setLoading(true);
    ReportService.filterAppsInstalledPacakges(data).then(packages => {
      // Filter out packages that don't have vulnerabilities or updates and set type.
      const _packages = packages
        .filter(p => p.vulnerability || p.update)
        .map(p => ({...p, level: p.vulnerability ? 'error' : 'warning' }))
        .sort((a, b) => a.level === 'error' ? -1 : 1)
      setTablePackages(_packages);
    }).finally(() => setLoading(false));
  }

  const deletePackage = item => {
    // If the package is being deleted, do not allow the delete.
    if (deletingPackages.includes(item.name)) {
      dispatch(setGlobalInfoMsg(`${StringHelper.capitalizeFirstLetter(item.type)} is being deleted.`));
      return;
    }
    // If the package is managed, do not allow the update.
    if (PackageHelper.isManaged(item)) {
      dispatch(setGlobalInfoMsg(`${StringHelper.capitalizeFirstLetter(item.type)} cannot be deleted from here.`));
      return;
    }
    // Call the API to delete the package.
    const data = {
      website_slug: website.slug,
      type: item.type,
      folder_name: item.name,
    };
    dispatch(setGlobalPleaseWaitMsg({ model: item.type, action: 'deleted' }));
    setDeletingPackages([...deletingPackages, item.name]);
    dispatch(uninstallPackage(data)).then(() => {
      dispatch(setGlobalSuccessMsg({ id: item.display_name, model: item.type, action: 'deleted', fromId: WebsiteHelper.getLabel(website) }));
      setTablePackages(tablePackages.filter(p => p.name !== item.name && p.type !== item.type));
    }).finally(() => setDeletingPackages(deletingPackages.filter(p => p !== item.name)));
  };
  
  const updatePackage = (item) => {
    // If the package is managed, do not allow the update.
    if (PackageHelper.isManaged(item)) {
      dispatch(setGlobalInfoMsg(`${StringHelper.capitalizeFirstLetter(item.type)} cannot be updated from here.`));
      return;
    }
    // If the package is being updated, do not allow the update.
    if (updatingPackages.includes(item.name)) {
      dispatch(setGlobalInfoMsg(`${StringHelper.capitalizeFirstLetter(item.type)} is being updated.`));
      return;
    }
    // Call the API to update the package.
    const data = {
      website_slug: website.slug,
      type: item.type,
      src: item.update_url,
      folder_name: item.name,
    };
    dispatch(setGlobalPleaseWaitMsg({ model: item.type, action: 'updated' }));
    setUpdatingPackages([...updatingPackages, item.name]);
    dispatch(installPackage(data)).then((_package) => {
      dispatch(setGlobalSuccessMsg({ model: item.type, id: item.display_name, action: 'updated', onId: WebsiteHelper.getLabel(website) }));
      setTablePackages(tablePackages.filter(p => p.name !== item.name && p.type !== item.type));
    }).finally(() => setUpdatingPackages(updatingPackages.filter(p => p !== item.name)));
  }

  // --- Actions ---

  const goToGlobalSettings = () => history.push({ pathname: `/settings/auto-updater` });

  const actions = [
    {
      value: 'Update',
      doHide: (item) =>   PackageHelper.isManaged(item) ||
                          !UserHelper.hasPermissions(`website:update:${website.slug}`) ||
                          !item.update_url,
      onClick: (item) => {
        DialogHelper
          .confirmAction(confirm, 'Update', item.display_name, item.type, null, true, 'Yes', 'update', 'info')
          .then(() => updatePackage(item))
      }
    },
    {
      value: 'Delete',
      doHide: (item) =>   PackageHelper.isManaged(item) ||
                          !UserHelper.hasPermissions(`website:update:${website.slug}`),
      onClick: item => DialogHelper
        .confirmDelete(confirm, item && item.display_name, item.type)
        .then(() => deletePackage(item))
    },
  ];

  const headers = [
    PackageHelper.renderScopeHeader(),
    PackageHelper.renderDisplayNameHeader('Name', '25%'),
    JsxHelper.createTableTextHeader('version', 'Version', '10%', '-'),
    JsxHelper.createTableTextHeaderWithCallback('description', 'Description', '50%',
      (row) => {
        const warningText = !row.update_url ? 'Update URL is not available' : (PackageHelper.isManaged(row) ? `Global ${row.type} cannot be updated from here` : '');
        const warningBox = warningText?  JsxHelper.createWarningBox(warningText, true) : '';
        const icon = JsxHelper.createIcon({
          icon: row.level,
          tooltip: row.level === 'error' ? 'Security Vulnerability' : `Outdated ${StringHelper.capitalizeFirstLetter(row.type)}`,
          color: row.level === 'error' ? 'danger' : 'warning',
          style: { fontSize: '18px', display: 'inline', position: 'relative', top: '3px', marginRight: '3px' },
        });
        return row.level === 'error'
            ? <span>{icon} Vulnerability detected: <b>{row.vulnerability.group}</b>{warningBox}</span>
            : <span>{icon} {StringHelper.capitalizeFirstLetter(row.type)} update available: <b>{row.version} {JsxHelper.ARROW_SYMBOL} {row.update}</b>{warningBox}</span>;
      }
    ),
    JsxHelper.createTableActionsHeader(actions, '10%'),
  ];

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>Alerts</TitleBar.Title>
      </TitleBar>
      <Fragment>
        {<p className='color-primary subheader'>
          This page displays all alerts for the website, automatically generated when {env.getBrandShortName()} detects issues such as outdated plugins or themes, or identifies security vulnerabilities.
        </p>}
        {UserHelper.isPartner() && <p className='subheader-box subheader'>{JsxHelper.createTipBox(<div>
          Consider using our <span onClick={goToGlobalSettings} className='subheader-link'><b>Auto-Updater</b></span> to keep your websites up-to-date automatically.
        </div>)}</p>}
      </Fragment>
      <Content>
        <WPSDataTable customClass='packages-table' columns={headers} body={tablePackages} loading={loading} noSearchOnTable={true} />
      </Content>
    </Container>
  );
};

export default WebsiteAlerts;
