import React, { Fragment, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import Can from 'utils/can';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSButton } from 'styles/layout/buttons';
import { useSelector, useDispatch } from 'react-redux';
import PackageService from 'services/package';
import { globalPluginsSelector, globalThemesSelector } from 'store/globalPackage/globalPackageSelector';
import { partnerPluginsSelector, partnerThemesSelector } from 'store/partnerPackage/partnerPackageSelector';
import useConfirm from 'hooks/useConfirm';
import {
  updateGlobalPackageFromUrl,
  deleteGlobalPackage,
  setGlobalPackageAutoUpdate,
  setGlobalPackagePropagateUpdates,
} from 'store/globalPackage/globalPackageActions';
import {
  updatePartnerPackageFromUrl,
  deletePartnerPackage,
  setPartnerPackageAutoUpdate,
  setPartnerPackagePropagateUpdates,
} from 'store/partnerPackage/partnerPackageActions';
import {
  setGlobalSuccessMsg,
  setGlobalWarningMsg,
  setGlobalPleaseWaitMsg,
  setGlobalErrorMsg,
} from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { partnersSelector } from 'store/user/userSelectors';
import { partnerSelector } from 'store/me/meSelectors';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import StringHelper from 'helpers/string';
import UserHelper from 'helpers/user';
import PackageHelper from 'helpers/package';
import ArrayHelper from 'helpers/array';
import useModal from 'hooks/useModal';
import 'components/package/packages.css';

const GlobalPackageIndex = ({type}) => {
  const typeCapitalized = StringHelper.capitalizeFirstLetter(type);
  useTitle(`Global ${typeCapitalized}s`);
  const isPlugin = type === 'plugin';
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();
  const allPartners = useSelector(partnersSelector);
  const globalPackages = useSelector(isPlugin ? globalPluginsSelector : globalThemesSelector);
  const partnerPackages = useSelector(isPlugin ? partnerPluginsSelector : partnerThemesSelector);
  const thePartner = useSelector(partnerSelector);
  const [openedPropagateUpdate, setOpenedPropagateUpdate] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(thePartner ? thePartner.slug : null);
  const [loadingAutoUpdate, setLoadingAutoUpdate] = useState(false);
  const [loadingPropagateUpdates, setLoadingPropagateUpdates] = useState(false);
  const [modalSettings, setModalSettings] = useState(null);
  const [busyPackages, setBusyPackages] = useState([]);
  const BREADCRUMB_INDEX = JsxHelper.createBreadcrumbs(`Global ${typeCapitalized}s`);
  const modalDialog = useModal();

  // --------------------------------------------------------
  // MODAL METHODS
  // --------------------------------------------------------

  const closeModal = () => {
    setModalSettings(false);
  }

  const openModal = (title, callback, text, btnText) => {
    setModalSettings({
      title,
      onConfirm: callback,
      onClose: closeModal,
      btnText,
      header: text,
      confirmColor: 'info',
    });
  }

  const displayPropagateUpdatesExplanationOnAutoUpdate = (_package) => {
    if (openedPropagateUpdate) {
      return; // Show only once
    }
    setOpenedPropagateUpdate(true);
    DialogHelper.info(
      modalDialog,
      '<b>Auto-Update Enabled</b>',
      `<div style='text-align:left;'>You've enabled Auto-Update for <b>${_package.display_name}</b>, but it won't automatically apply to all your installed websites.<br><br>
      To propagate the update across all websites, please enable the <b>Propagate Update</b> option. This will replace any existing version of the ${_package.type} on your websites with the global version.<br><br>
      For better control over updates, we recommend using our <b>Auto Updater</b> or <b>Git Integration</b> for private ${_package.type}.
      </div>`
    );
  }

  // ------------------
  // UPDATE PACKAGE
  // ------------------

  const updatePackageFromSrc = (item) => {
    const data = {
      package_slug: item.slug,
      src: item.latest_version_url,
    };
    const actionFunc = PackageHelper.isGlobal(item) ? updateGlobalPackageFromUrl : updatePartnerPackageFromUrl;
    setBusyPackages([...busyPackages, item.slug]);
    dispatch(setGlobalPleaseWaitMsg({ id: item.display_name, model: type, action: 'updated' }));
    dispatch(actionFunc(data))
      .then(() => dispatch(setGlobalSuccessMsg({ id: item.display_name, model: type, action: 'updated' })))
      .finally(() => setBusyPackages(busyPackages.filter(slug => slug !== item.slug)));
  }

  const openUpdatePackageModal = (item) => {
    if (busyPackages.includes(item.slug)) {
      dispatch(setGlobalWarningMsg('Please wait until the current update finishes.'));
      return;
    }
    openModal(
      `Update ${typeCapitalized}`,
      () => {
        updatePackageFromSrc(item)
        closeModal(); 
      },
      <Fragment>Are you sure you want to update the {' '}<strong>{item.display_name}</strong> {type} from version <strong>{item.version}</strong> to <strong>{item.latest_version}</strong>?</Fragment>,
      'Yes'
    );
  }
  
  // ---------------
  // INDEX TABLE
  // ---------------

  // CALLBACKS

  const handleDeletePackage = item => {
    const id = `${item.display_name} v${item.version}`;
    dispatch(setGlobalPleaseWaitMsg({ id, model: type, action: 'deleted' }));
    const data = { package_slug: item.slug };
    if (item.scope === 'global') {
      dispatch(deleteGlobalPackage(data)).then(() => onDeletePackage(id));
    } else {
      dispatch(deletePartnerPackage(data)).then(() => onDeletePackage(id));
    }
  };

  const getTableItems = () =>
    PackageHelper.sortPackages(
      selectedPartner ? partnerPackages.filter(plugin => plugin.partner_slug === selectedPartner) : globalPackages,
      true
    );

  // CLICK EVENTS

  const onDeletePackage = id => dispatch(setGlobalSuccessMsg({ id, model: type, action: 'deleted' }));

  const handleSelectPartner = e => {
    const { value } = e.target;
    setSelectedPartner(!value ? null : value);
  };

  const copyWebhookURL = async res =>
    JsxHelper.copyToClipboard(dispatch, res.webhook_api_url, 'Webhook URL');

  const requestGitWebhookURL = async item => {
    PackageService.getWebhookUrl({ package_slug: item.slug })
      .then(copyWebhookURL)
      .catch(err => dispatch(setGlobalErrorMsg(err)));
  };

  const goToGitEvents = item => {
    history.push({
      pathname: `/${type}s/git-events`,
      state: item,
    });
  };

  const handleAutoUpdate = (item, checked) => {
    const data = {
      package_slug: item.slug,
      auto_update: checked,
    };
    const actionFunc = PackageHelper.isGlobal(item) ? setGlobalPackageAutoUpdate : setPartnerPackageAutoUpdate;
    setLoadingAutoUpdate(item.slug);
    dispatch(setGlobalPleaseWaitMsg({ id: item.display_name, model: type, action: 'updated' }));
    dispatch(actionFunc(data))
      .then(() => dispatch(setGlobalSuccessMsg({ id: item.display_name, model: type, action: 'updated' })))
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => {
        setLoadingAutoUpdate(false)
        checked && !item.propagate_update && displayPropagateUpdatesExplanationOnAutoUpdate(item);
      });
  }

  const handlePropagateUpdates = (item, checked) => {
    const data = {
      package_slug: item.slug,
      propagate_updates: checked,
    };
    const actionFunc = PackageHelper.isGlobal(item) ? setGlobalPackagePropagateUpdates : setPartnerPackagePropagateUpdates;
    setLoadingPropagateUpdates(item.slug);
    dispatch(setGlobalPleaseWaitMsg({ id: item.display_name, model: type, action: 'updated' }));
    dispatch(actionFunc(data))
      .then(() => dispatch(setGlobalSuccessMsg({ id: item.display_name, model: type, action: 'updated' })))
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoadingPropagateUpdates(false));
  }

  // TABLE

  const hasUpdatePermissions = (item) => UserHelper.hasPermissions(`partner-package:update:${item.slug}`);

  const actions = [
    {
      value: 'Download',
      doHide: item => PackageHelper.isGit(item) || !hasUpdatePermissions,
      onClick: item => {
        const data = {
          package_slug: item.slug,
          file_name: item.slug,
        };
        dispatch(setGlobalPleaseWaitMsg({ id: item.display_name, model: type, action: 'downloaded' }));
        PackageService.download(data).catch(err => dispatch(setGlobalErrorMsg(err)));
      },
    },
    {
      value: 'Upload New',
      doHide: item => PackageHelper.isGit(item) || !hasUpdatePermissions(item),
      onClick: item => history.push({ pathname: `/${type}s/update`, state: item }),
    },
    {
      value: 'History',
      doHide: item => !PackageHelper.isGit(item) || !hasUpdatePermissions(item),
      onClick: goToGitEvents,
    },
    {
      value: 'Update',
      doHide: item => !item.latest_version_url || PackageHelper.isGit(item) || !hasUpdatePermissions(item),
      onClick: openUpdatePackageModal,
    },
    {
      value: 'Copy Webhook URL',
      doHide: item => !PackageHelper.isGit(item) || !hasUpdatePermissions(item),
      onClick: requestGitWebhookURL,
    },
    {
      value: 'Delete',
      doHide: item => !hasUpdatePermissions(item),
      onClick: item => DialogHelper
        .confirmDelete(confirm, item.display_name, item.type)
        .then(() => handleDeletePackage(item))
    },
  ];

  const headers = [
    PackageHelper.renderScopeHeader(),
    PackageHelper.renderDisplayNameHeader(typeCapitalized),
    PackageHelper.renderMustInstallHeader(isPlugin ? 'Must Install' : 'Default'),
    PackageHelper.renderVersionHeader(),
    PackageHelper.renderSizeHeader(),
    PackageHelper.renderGlobalAutoUpdateHeader(handleAutoUpdate, loadingAutoUpdate),
    PackageHelper.renderGlobalPropagateUpdatesHeader(handlePropagateUpdates, loadingPropagateUpdates),
    JsxHelper.createTableActionsHeader(actions, '19%'),
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={BREADCRUMB_INDEX}>
        {`Global ${typeCapitalized}s`}
        </TitleBar.Title>
        <TitleBar.Actions>
          {UserHelper.isAdminOrAgent() && (JsxHelper.createSelectInput({
            name: 'partner_slug_option',
            value: selectedPartner,
            options: ArrayHelper.buildSelectOptions(allPartners, 'display_name', 'slug'),
            sortOff: true,
            isSearchable: true,
            onChange: handleSelectPartner,
            placeholder: 'Select partner...',
          }))}
          <Can
            perform='partner-package:create:*'
            yes={() => (
              <Fragment>
                <NavLink
                  to={`/${type}s/create` + ((UserHelper.isAdminOrAgent() ? (selectedPartner ? '?partner=' + selectedPartner : '') : ''))}>
                  <WPSButton className='upload--btn'>Add {typeCapitalized}</WPSButton>
                </NavLink>
                {!UserHelper.isAdminOrAgent() && (
                  <NavLink to={`/${type}s/git-connect`}>{JsxHelper.createButton({
                    classes: 'primary--btn',
                    style: { textTransform: 'inherit' },
                    label: 'Connect via Git',
                  })}</NavLink>
                )}
              </Fragment>
            )}
          />
        </TitleBar.Actions>
      </TitleBar>

      <Content>
        {<WPSDataTable customClass='packages-table' columns={headers} body={getTableItems()}/>}
        {modalSettings && DialogHelper.inputs(modalSettings)}
      </Content>
    </Fragment>
  );
};

export default GlobalPackageIndex;
