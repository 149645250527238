import React, { useState, Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { WPSForm } from 'styles/layout/forms';
import { TitleBar } from 'styles/layout/titlebar';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import JsxHelper from 'helpers/jsx';
import SMTPHelper from 'helpers/smtp';
import CustomerService from 'services/customer';
import useModal from 'hooks/useModal';
import DialogHelper from 'helpers/dialog';
import { useDispatch } from 'react-redux';
import 'components/website/website.css';
import env from 'config/env';
import UserHelper from 'helpers/user';

const CustomSMTPServer = () => {
  useTitle('Custom SMTP Server');

  const dispatch = useDispatch();
  const { handleSubmit } = useForm({
    reValidateMode: 'onSubmit',
  });

  let inputFields = SMTPHelper.getInputFields();
  inputFields.smtp.auth.hide = true;
  inputFields.smtp.autotls.hide = true;
  inputFields.mail.from_email_force.hide = true;
  inputFields.mail.from_name_force.hide = true;
  inputFields.mail.return_path.hide = true;
  const modalDialog = useModal();
  const breadcrumbs = JsxHelper.createBreadcrumbs('SMTP', 'clients');
  const [loading, setLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [loadingSMTPConfig, setLoadingSMTPConfig] = useState(true);
  const [triggerRerender, setTriggerRerender] = useState(1);
  const [userConfig, setUserConfig] = useState({ smtp: {}, mail: {} });
  const [disableTest, setDisableTest] = useState(true);

  const fillInputFields = (partnerConfig) => {
    // Convert partner config to the format that the form expects.
    const _partnerConfig = {
      smtp: {
        host: partnerConfig.host,
        port: partnerConfig.port,
        user: partnerConfig.username,
        pass: partnerConfig.password,
        encryption: partnerConfig.encryption,
      },
      mail: {
        from_email: partnerConfig.from_email,
        from_name: partnerConfig.from_name,
      },
    };
    const formData = SMTPHelper.buildUserConfig(inputFields, _partnerConfig);
    setUserConfig(formData);
    setTriggerRerender(triggerRerender + 1)
    if (partnerConfig.host) setDisableTest(false);
  };

  useEffect(() => {
    CustomerService.fetchSMTPSettings()
      .then(res => fillInputFields(res || {}))
      .finally(() => setLoadingSMTPConfig(false))
      .catch(err => DialogHelper.error(modalDialog, err));
    // eslint-disable-next-line
  }, []);

  const handleOnChange = (e, group) => {
    const { name, type } = e.target;
    const value = type === 'checkbox' ? e.target.checked : e.target.value;
    let groupData = { ...userConfig[group], [name]: value };
    setUserConfig(prev => ({ ...prev, [group]: groupData }));
    setDisableTest(true); // Hide the test email button when the user changes the SMTP settings
  };

  const buildApiParams = () => ({ 
    host: userConfig.smtp.host,
    port: userConfig.smtp.port,
    username: userConfig.smtp.user,
    password: userConfig.smtp.pass,
    encryption: userConfig.smtp.encryption,
    from_email: userConfig.mail.from_email,
    from_name: userConfig.mail.from_name,
  });

  const onSubmit = () => {
    setLoading(true);
    CustomerService.updateSMTPSettings(buildApiParams())
      .then(() => {
        DialogHelper.success(modalDialog, 'SMTP settings updated successfully')
        if (userConfig.smtp.host) setDisableTest(false);
      })
      .catch(err => DialogHelper.error(modalDialog, err))
      .finally(() => setLoading(false));
  };

  const onSendTestEmail = () => {
    setTestLoading(true);
    const sendTo = UserHelper.getEmail();
    CustomerService.sendSMTPTestEmail({ send_to_email: sendTo })
      .then(() => DialogHelper.success(
        modalDialog,
        `A test email has been sent sent to <b>${sendTo}</b>.`,
        'Please check your inbox to confirm if it was received.'
      ))
      .catch(err => DialogHelper.error(modalDialog, err))
      .finally(() => setTestLoading(false));
  }

  return (
  <Fragment>
    <TitleBar className='titlebar'>
      <TitleBar.Title breadcrumbs={breadcrumbs}>Custom SMTP Server</TitleBar.Title>
      <TitleBar.Actions>{JsxHelper.createBackButton()}</TitleBar.Actions>
    </TitleBar>
    <Content className='smtp-mailer'>
      <p className='color-primary subheader padding-left-0 margin-bottom-12'>
      Set up your SMTP server to send emails from your domain, ensuring client emails come from your brand, not {env.getBrandShortName()}. This improves deliverability and reduces spam risks by overriding {env.getBrandShortName()}'s default SMTP settings.
        <span style={{marginTop: '7px', display: 'block'}}>For detailed instructions, visit our {JsxHelper.createFaqLink('setup-agency-smtp', 'knowledge base')}.</span>
      </p>
      {!loadingSMTPConfig ? <WPSForm id='smtp-mailer-form' key={triggerRerender} onSubmit={handleSubmit(onSubmit)} noValidate>
        {SMTPHelper.renderCustomFormFields(inputFields, userConfig, handleOnChange)}
        {SMTPHelper.renderSPFBox(dispatch)}
        <div className='action-buttons' style={{display: 'flex'}}>
          {JsxHelper.createButton({ label: 'Save', onClick: onSubmit, loading })}
          {JsxHelper.createButton({ label: 'Test SMTP', onClick: onSendTestEmail, loading: testLoading, disabled: disableTest, tooltip: disableTest ? 'Please save your SMTP settings first' : null })}
        </div>
      </WPSForm> : JsxHelper.createTableLoader()}
    </Content>
  </Fragment>
)};

export default CustomSMTPServer;
