import React, { Fragment, useState, useEffect, useRef } from 'react';
import { StepperStep } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import StringHelper from 'helpers/string';
import DomainHelper from 'helpers/domain';
import dnsHelper from 'helpers/dnszone';
import ArrayHelper from 'helpers/array';
import FormHelper from 'helpers/form';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import UrlHelper from 'helpers/url';
import { isEmptyOrNull } from 'helpers';
import { WPSBubble } from 'styles/layout/buttons';
import { WPSForm, WPSLabel, WPSInput, WPSCheckboxLabel, WPSCheckbox } from 'styles/layout/forms';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import env from 'config/env';
import DNSRecordModal from './recordModal';
import { createDNSZone, testDNSNameservers } from 'store/dnsZone/dnsZoneActions';
import { websitesSelector } from 'store/website/websiteSelectors';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import useModal from 'hooks/useModal';
import { useHistory } from 'react-router-dom';
import Icon from 'components/layout/icon';
import 'components/stepper/stepper.css';

const DNSZoneSetup = () => {
  useTitle('DNS Zone Setup');

  const history = useHistory();
  const modalDialog = useModal();
  const dispatch = useDispatch();
  const websites = useSelector(websitesSelector);
  const unassignedWebsites = websites.filter(w => isEmptyOrNull(w.dns_zone_slug));

  const initialState = dnsHelper.recordInitialState();
  const supportedTtl = dnsHelper.ttlOptions.map(option => option.value);

  const mounted = useRef(true);
  const stepperRef = useRef();
  const disabledStepsElements = useRef([]);
  const stepToStateColors = useRef([]);

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scanLoading, setScanLoading] = useState(false);
  const [domainName, setDomainName] = useState('');
  const [newDnsRecords, setNewDnsRecords] = useState(null);
  const [prevNewDnsRecords, setPrevNewDnsRecords] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepForData, setCurrentStepForData] = useState(false);
  const [disabledStepsData, setDisabledStepsData] = useState([]);
  const [record, setRecord] = useState(initialState);
  const [editFlag, setEditFlag] = useState(false);
  const [autosetARecords, setAutosetARecords] = useState(false);
  const [warnedOnBadARecords, setWarnedOnBadARecords] = useState(false);
  const [invalidDomainName, setInvalidDomainName] = useState(false);
  const [createDefaultARecords, setCreateDefaultARecords] = useState(false);
  const [newDnsZone, setNewDnsZone] = useState(false);
  const [newNameServers, setNewNameservers] = useState(false);
  const [scannedARecords, setScannedARecords] = useState([]);
  const [assignedWebsite, setAssignedWebsite] = useState(UrlHelper.getQueryParam('website'));
  const [defaultARecord, setDefaultARecord] = useState(UrlHelper.getQueryParam('defaultARecord'));
  const origin = UrlHelper.getQueryParam('origin');
  const continueTo = origin ? origin.replace('-', ' ') : null;

  const initStepper = (step) => {
    let disabledSteps = []; // Starts with index 0 (step 1 = index 0)
    let stepToState = { 1: 'complete', 2: 'complete', 3: 'complete' }

    disabledSteps = createDisabledSteps(step);

    // Set disabled steps
    setDisabledStepsData(disabledSteps);
    disabledStepsElements.current = disabledSteps;
    stepToStateColors.current = stepToState;

    // Initialize steps and go to initial
    stepperSteps[step - 1].onClick();

    // Set initial step at the end of conditions
    updateCurrentStep(step);
  }

  useEffect(() => {
    initStepper(1);
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const initStep = stepNum => {
    for (var i = 1; i <= 3; i++) {
      const stepIndex = i - 1;
      let step = stepperRef.current.children[0].children[0].children[stepIndex].children[0];
      step.classList.remove('complete-color');
      step.classList.remove('warning-color');
      step.classList.remove('danger-color');
      step.classList.remove('active-color');
      if (stepNum === i) {
        // Color the clicked step with active-color
        step.classList.add('active-color');
      } else if (!disabledStepsElements.current.includes(stepIndex)) {
        // Color the clicked step with the state color
        step.classList.add(`${stepToStateColors.current[i]}-color`);
      }
    }
    setCurrentStepForData(stepNum);
  };

  /* eslint-disable no-unused-vars */
  const [stepperSteps, setStepperSteps] = useState([
    {
      title: 'Set Domain Name',
      onClick: () => initStep(1),
    },
    {
      title: 'Review DNS Records',
      onClick: () => initStep(2),
    },
    {
      title: 'Change Nameservers',
      onClick: () => initStep(3),
    }
  ]);

  const createDisabledSteps = (start) => {
    let steps = [];
    for (let i = start; i <= stepperSteps.length; i++) {
      steps.push(i);
    }
    return steps;
  }

  const updateCurrentStep = newStep => {
    setCurrentStep(newStep + 1);
    setCurrentStepForData(newStep);
  };

  const onSaveNewRecord = () => {
    // value + name was modified and need to be fixed
    const modifiedRecord = {...record};
    modifiedRecord.values = modifiedRecord.values.map(v => v.value);
    // search for record by id
    const currentRecords = ArrayHelper.clone(newDnsRecords);
    const index = currentRecords.findIndex(r => r.id === modifiedRecord.id);
    if (index === -1) {
      // add new record
      modifiedRecord.name += '.' + StringHelper.trimRight(domainName, '.') + '.';
      modifiedRecord.id = StringHelper.randomSlug(8);
      currentRecords.push(modifiedRecord);
    } else {
      // modify existing record
      currentRecords[index].values = modifiedRecord.values;
      currentRecords[index].ttl = modifiedRecord.ttl;
    }
    setNewDnsRecords(currentRecords);
    setModal(false);
  }

  const onScanDomainRecords = async () => {
    const isValidDomain = DomainHelper.isRegistrableDomain(domainName);
    if (!isValidDomain) {
      setInvalidDomainName(true);
      return;
    }
    initStepper(2);
    setScanLoading(true);
    let records = await DomainHelper.scanRecords(domainName);
    records = records.filter(r => r.type !== 'NS').map(r => {
      r.ttl = supportedTtl.includes(r.ttl) ? r.ttl : 300;
      r.id = StringHelper.randomSlug(8);
      return r;
    });
    setScannedARecords(records.filter(r => r.type === 'A'));
    setNewDnsRecords(records);
    setScanLoading(false);
    if (defaultARecord) {
      performAutosetARecords(records);
    }
    setInvalidDomainName(false);
  }

  const onAddNewRecord = () => {
    if (defaultARecord) {
      initialState.values = [ FormHelper.createSelectOption(defaultARecord) ];
    }
    setRecord(initialState);
    setEditFlag(false);
    setModal(true);
  }

  const onGoToDnsRecords = () => {
    history.push({
      pathname: `/dns-zones/${newDnsZone.slug}/records`,
    });
  }

  const onGoToOriginPage = () => {
    if (origin === 'go-live') {
      history.push({
        pathname: `/websites/${assignedWebsite}/go-live`,
        search: `?origin=dnszone-setup`
      });
    }
  }

  const onCheckNameservers = async () => {
    setLoading(true);
    const data = {
      dns_zone_slug: newDnsZone.slug
    }
    dispatch(testDNSNameservers(data))
    .then((result) => dnsHelper.openNSCheckDialog(modalDialog, domainName, result.success))
    .catch((error) => dispatch(setGlobalErrorMsg(error)))
    .finally(() => setLoading(false));
  }

  const performAssignWebsite = (slug) => {
    const website = websites.find(w => w.slug === slug);
    if (website) {
      setDefaultARecord(website.public_ipv4);
      setAssignedWebsite(slug);
    }
  }

  const onCreateDefaultARecords = () => {
    const _domainName = StringHelper.trimRight(domainName, '.') + '.';
    setNewDnsRecords(newDnsRecords.concat([
      { type: 'A', name: _domainName, values: [ defaultARecord ], ttl: 300, id: StringHelper.randomSlug(8) },
      { type: 'A', name: 'www.' + _domainName, values: [ defaultARecord ], ttl: 300, id: StringHelper.randomSlug(8) }
    ]));
    setCreateDefaultARecords(true);
  }

  const performAutosetARecords = (scanRecords) => {
    if (!autosetARecords) {
      // Save the previous state for when user unchecks
      const copy = ArrayHelper.clone(scanRecords);
      setPrevNewDnsRecords(copy);
      // Update current records
      const updatedNsRecords = scanRecords.map(r => {
        if (r.type === 'A' && !r.values.includes(defaultARecord)) {
          r.values = [ defaultARecord ];
        }
        return r;
      });
      setNewDnsRecords(updatedNsRecords)
      setAutosetARecords(true);
    } else {
      setAutosetARecords(false);
      if (!isEmptyOrNull(prevNewDnsRecords)) {
        setNewDnsRecords(prevNewDnsRecords);
      }
    }
  }

  const getSuspiciousARecords = () => {
    if (!defaultARecord) {
      return false;
    }
    const records = newDnsRecords.filter(r => r.type === 'A');
    let warnOn = [];
    for (const r of records) {
      if (r.values && !r.values.includes(defaultARecord)) {
        warnOn.push(r.name);
      }
    }
    return isEmptyOrNull(warnOn) ? false : warnOn;
  }

  const onCreateDnsZone = () => {
    const warnOnDomains = getSuspiciousARecords();
    if (!warnedOnBadARecords && warnOnDomains) {
      DialogHelper.warning(modalDialog, null, null, {
        line1: `<span>We have detected that the following A record(s) are not configured to point to the website's expected IP address <b>${defaultARecord}</b>:${JsxHelper.createModalItems(warnOnDomains)}</span>`,
        line2: `<span>Please fix the record(s) if you think they are incorrect. Otherwise, you can continue to create the DNS zone.</span>`,
        textAlign: 'left',
        btnText: 'Continue',
      });
      setWarnedOnBadARecords(true);
      return;
    }
    applyCreateDnsZone();
  }

  const applyCreateDnsZone = () => {
    setLoading(true);
    const data = {
      name: domainName,
      website_slug: assignedWebsite,
      records: newDnsRecords.filter(r => r.type !== 'NS').map(r => {
        delete r.class;
        delete r.inputValue;
        delete r.id;
        delete r.dns_zone_slug;
        return r;
      })
    };
    dispatch(createDNSZone(data))
    .then((dnsZone) => {
      setNewDnsZone(dnsZone);
      setNewNameservers(dnsZone.records.find(r => r.type === 'NS').values);
      dispatch(setGlobalSuccessMsg({ id: domainName, model: 'DNS zone' }));
      initStepper(3);
    })
    .catch((error) => {
      dispatch(setGlobalErrorMsg(error));
    }).finally(() => {
      setLoading(false);
    });
  }

  // DataTable.
  const dnsScanActions = [
    // {
    //   value: 'Edit',
    //   onClick: item => {
    //     const _data = {
    //       ...item,
    //       name: dnsHelper.getShortRecordName(item.name, domainName),
    //       values: item.values.map(e => FormHelper.createSelectOption(e)),
    //     };
    //     setRecord(_data);
    //     setEditFlag(true);
    //     setModal(true);
    //   },
    // },
    {
      value: 'Remove',
      onClick: item => {
        const currentRecords = [...newDnsRecords];
        const index = currentRecords.findIndex(r => r.id === item.id);
        if (index !== -1) {
          currentRecords.splice(index, 1);
          setNewDnsRecords(currentRecords);
        }
      },
    },
  ];

  const dnsScanHeader = [
    JsxHelper.createTableTextHeader('type', 'Type', '14%'),
    JsxHelper.createTableTextHeader('name', 'Name', '30%'),
    {
      name: 'Value',
      selector: 'values',
      width: '33%',
      cell: row => {
        let warning = null;
        if (defaultARecord && assignedWebsite && row.type === 'A' && !row.values.includes(defaultARecord)) {
          warning = `${row.name} does not point to ${defaultARecord}.\r\nYou can use edit button or select the checkbox below to change it.`;
        }
        return <Fragment>
          {JsxHelper.createItemsList(row.values, true)}
          {warning && JsxHelper.createBubble({
            icon: 'warning',
            color: 'warning',
            tooltip: warning,
          })}
        </Fragment>
      },
    },
    JsxHelper.createTableTextHeader('ttl', 'TTL', '10%'),
    JsxHelper.createTableActionsHeader(dnsScanActions, '13%'),
  ]

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'DNS Zones',
      link: '/dns-zones',
    },
    {
      text: 'Setup',
      link: '/setup',
    },
  ];

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Create DNS Zone</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <Content id='dns-setup-wizard'>
        <Fragment>
          <div
            ref={stepperRef}
            className='stepper'
            style={{ marginLeft: '0px', marginBottom: '15px' }}>
            {JsxHelper.createStepper(stepperSteps, currentStep, disabledStepsData)}
          </div>
          {currentStepForData === 1 && (
            <WPSForm className='step-custom-form'>
              <StepperStep>
                <p className='step-title'>
                  Set the domain name to migrate to {env.getBrandShortName()} so that it can be managed by the platform.<br/>
                  In the next step, your domain will be scanned for DNS records to be imported into your DNS zone configuration.
                </p>
                <div className='notice'>
                  <WPSForm.RowItem style={{ position: 'relative' }}>
                    <WPSLabel required>
                      <strong>Enter your domain:</strong>
                      {invalidDomainName && (
                        <span className='danger-font-color'>
                          Enter root domain only (e.g. example.com)
                        </span>
                      )}
                    </WPSLabel>
                    <WPSInput
                      type='text'
                      placeholder='example.com'
                      value={domainName}
                      disabled={newDnsZone}
                      onChange={e => setDomainName(e.target.value)}
                      className='step-copy-input'
                    />
                  </WPSForm.RowItem>
                  <WPSForm.RowItem style={{ maxWidth: '580px', marginLeft: '0px', position: 'relative' }}>
                    <WPSLabel>
                      <strong>Assign to website:</strong>
                    </WPSLabel>
                    <WPSSelect
                      name='website_slug'
                      value={assignedWebsite}
                      options={ArrayHelper.buildSelectOptions(unassignedWebsites, 'slug', 'slug')}
                      onChange={e => performAssignWebsite(e.target.value)}
                      disabled={newDnsZone}
                      required={false}
                      sortOff={true}
                      isSearchable={true}
                    />
                  </WPSForm.RowItem>
                </div>
                <div className='display-flex action-buttons'>
                  {JsxHelper.createButton({
                    label: 'Manage DNS Records',
                    classes: 'margin-left-6',
                    onClick: onScanDomainRecords,
                    disabled: scanLoading || loading || newDnsZone || isEmptyOrNull(domainName),
                  })}
                </div>
              </StepperStep>
            </WPSForm>
          )}
          {currentStepForData === 2 && (
            <WPSForm className='step-custom-form'>
              <StepperStep>
                <p className='step-title' style={{ marginBottom: '30px' }}>
                  {scanLoading &&
                    <span>Your domain is being scanned for DNS records to be imported into your DNS zone configuration.</span>
                  }
                  {!scanLoading &&
                    <span>Verify that the DNS records below are configured correctly before you create the DNS zone.<br/>
                    Note that the records will only take effect after you change your nameservers.</span>
                  }
                  {!scanLoading && defaultARecord && <div style={{ display: 'flex', alignItems: 'center', marginTop: '7px' }}>
                    All your website domains must point to:
                    {JsxHelper.createCopyButton({
                      value: defaultARecord,
                      dispatch,
                      style: {padding: '2px 5px'}
                    })}
                  </div>}
                </p>
                <div className='notice'>
                  <WPSDataTable
                    columns={dnsScanHeader}
                    loading={scanLoading}
                    body={newDnsRecords}
                    rowsPerPage={10}
                    noSearchOnTable={true}
                  />
                </div>
                {autosetARecords && defaultARecord && <WPSForm.RowItem direction='row' className='custom-row' style={{minHeight: 'auto'}}>
                  <WPSBubble color='light' background='warning' style={{marginLeft: '0px'}}>
                    <Icon tag='warning' /> Please note that {env.getBrandShortName()} has automatically configured your A records to point to {defaultARecord}.
                  </WPSBubble>
                </WPSForm.RowItem>}
                {!scanLoading && defaultARecord && !createDefaultARecords && isEmptyOrNull(scannedARecords) && <WPSForm.RowItem direction='row' className='custom-row' style={{minHeight: 'auto'}}>
                  <WPSCheckboxLabel disabled={loading}>
                    <WPSCheckbox
                      type='checkbox'
                      checked={createDefaultARecords}
                      onChange={onCreateDefaultARecords}
                    />
                  </WPSCheckboxLabel>
                  <div className='custom-checkbox-label'>
                    <span>Auto create <b>{domainName}</b> and <b>www.{domainName}</b> as A records and point them to my website.</span>
                  </div>
                </WPSForm.RowItem>}
                <div className='display-flex action-buttons'>
                  {JsxHelper.createButton({
                    label: 'Add New Record',
                    onClick: onAddNewRecord,
                    disabled: scanLoading || loading || newDnsZone,
                    classes: 'margin-left-6', // align with "Pleas note..."
                  })}
                  {JsxHelper.createButton({
                    label: 'Create DNS Zone',
                    onClick: onCreateDnsZone,
                    disabled: scanLoading || loading || newDnsZone,
                    loading: loading,
                    classes: 'primary--btn',
                  })}
                </div>
                {modal && (
                  <DNSRecordModal
                    isEdit={editFlag}
                    isTemplate={false}
                    onSubmit={onSaveNewRecord}
                    setRecord={setRecord}
                    record={record}
                    setModal={setModal}
                    loading={loading}
                    domainName={domainName}
                    dnsSlug={null}
                  >
                  </DNSRecordModal>
                )}
              </StepperStep>
            </WPSForm>
          )}
          {currentStepForData === 3 && (
            <WPSForm className='step-custom-form'>
              <StepperStep>
                {DomainHelper.renderAddNSRecordsInstructions(domainName, newNameServers, dispatch)}
                <div className='display-flex action-buttons'>
                  {JsxHelper.createButton({
                    label: 'Check Nameservers',
                    onClick: onCheckNameservers,
                    disabled: loading,
                    loading: loading,
                    classes: 'margin-left-6'
                  })}
                  {JsxHelper.createButton({
                    label: 'Manage DNS Records',
                    onClick: onGoToDnsRecords,
                  })}
                  {continueTo ? JsxHelper.createButton({
                    label: `Continue To ${continueTo}`,
                    onClick: onGoToOriginPage,
                    classes: 'warning--btn'
                  }) : null}
                </div>
              </StepperStep>
            </WPSForm>
          )}
        </Fragment>
      </Content>
    </Fragment>
  );
};

export default DNSZoneSetup;
