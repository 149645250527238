import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Content } from 'styles/globalStyles';
import { TitleBar } from 'styles/layout/titlebar';
import ReportService from 'services/report';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { timezoneSelector } from 'store/me/meSelectors';
import { websitesSelector } from 'store/website/websiteSelectors';
import StringHelper from 'helpers/string';
import { getStatusColor } from 'helpers';
import JsxHelper from 'helpers/jsx';
import PackageHelper from 'helpers/package';
import TableHelper from 'helpers/table';
import { partnersSelector } from 'store/user/userSelectors';
import UserHelper from 'helpers/user';
import ArrayHelper from 'helpers/array';
import WebsiteHelper from 'helpers/website';
import { isUndefined } from 'helpers'

import 'components/package/packages.css';

const PackageUpdatesHistory = () => {
  const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const allPartners = useSelector(partnersSelector);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const allWebsites = useSelector(websitesSelector);
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [failedOnlyMode, setFailedOnlyMode] = useState(false);
  const timezone = useSelector(timezoneSelector);
  const PER_PAGE = 100;
  const mounted = useRef(true)

  useEffect(() => {
    refreshRecords();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const loadMoreRecords = () => {
    setPage(page + 1);
    setLoadingMore(true);
    fetchHistoryRecords(page + 1);
  }

  const refreshRecords = (_partnerSlug, _websiteSlug, _failedOnly) => {
    setLoadingRefresh(true);
    setPage(1);
    setTableData([]);
    fetchHistoryRecords(1, _partnerSlug, _websiteSlug, _failedOnly);
  }

  const handleSelectPartner = e => {
    const { value } = e.target;
    setSelectedPartner(!value ? null : value);
    setSelectedWebsite(null);
    refreshRecords(value);
  };

  const handleSelectWebsite = e => {
    const { value } = e.target;
    setSelectedPartner(null);
    setSelectedWebsite(value);
    refreshRecords(null, value);
  };

  const fetchHistoryRecords = (_page, _partnerSlug, _websiteSlug, _failedOnly) => {
    const data = {
      page: _page || 1,
      limit: PER_PAGE,
      failed_only: isUndefined(_failedOnly) ? failedOnlyMode : _failedOnly,
    }
    const partnerSlug = _partnerSlug || selectedPartner;
    if (partnerSlug) {
      data.partner_slug = partnerSlug;
    }
    const websiteSlug = _websiteSlug || selectedWebsite;
    if (websiteSlug) {
      data.website_slug = websiteSlug;
    }
    ReportService.listPackagesUpdateHistory(data).then((res) => {
      if (!mounted.current) return;
      const newItems = res.map(item => {
        item.key = StringHelper.randomSlug();
        item.reason = item.metadata ? item.metadata.reason : null;
        item.scope = item.metadata ? item.metadata.scope : null;
        item.is_git = item.metadata ? item.metadata.is_git : false;
        return item;
      });
      setTableData(prev => [...prev, ...newItems]);
    })
    .finally(() => {
      setLoadingRefresh(false);
      setLoadingMore(false);
    });
  }

  const switchFailureMode = () => {
    setFailedOnlyMode(!failedOnlyMode);
    refreshRecords(null, null, !failedOnlyMode);
  }

  const breadcrumbs = [
    {
      text: 'Home',
    },
    {
      text: 'Reports',
      link: '/reports',
    },
    {
      text: 'Plugin & Theme Updates',
      link: '#'
    }
  ];

  const noFilterHeaders = [
    {
      name: 'Action',
      selector: 'action',
      width: '29%',
      cell: PackageHelper.renderActionCell,
    },
    PackageHelper.renderTypeHeader(),
    {
      name: 'Status',
      selector: 'status',
      width: '7%',
      sortable: true,
      cell: row => {
        let tooltip = StringHelper.capitalizeFirstLetter(row.status);
        TableHelper.customizeCellValue(row, 'status', row.status);
        if (row.status === 'error' && row.status_message) {
          const reason = row.metadata ? row.metadata.post_healthcheck : null;
          if (reason) {
            tooltip = `${StringHelper.trimRight(row.status_message, '.')}: ${reason}`;
          }
        }
        return JsxHelper.createBubble({
          color: getStatusColor(row),
          icon: row.status === 'error' ? 'error' : (row.status === 'processing' ? 'arrowsLoading' : 'success'),
          tooltip: row.status === 'error' ? (row.status_message || 'Unknown error') : tooltip,
        });
      },
    },
    {
      name: 'Version',
      selector: 'old_version',
      width: '11%',
      cell: row => JsxHelper.createTableCellFromTo({
        from: row.old_version,
        to: row.new_version,
      }),
    },
  ];

  const failedOnlyHeaders = noFilterHeaders.filter(h => h.name === 'Version').concat([
    {
      name: 'Error Message',
      selector: 'error',
      sortable: true,
      cell: row => <div className='danger-font-color'>{row.status_message || 'Unknown error'}</div>,
    }
  ])

  const headers = [
    JsxHelper.createTableTimeHeader('created_at', timezone),
    {
      name: 'Name',
      selector: 'display_name',
      searchable: true,
      width: '23%',
      cell: row => {
        TableHelper.customizeCellValue(row, 'display_name', row.display_name + ':' + (row.website_slug || ''));
        return JsxHelper.createTableMultiLineCell({
          header: row.display_name || row.name,
          subheader: row.website_slug ? row.website_slug : `Global ${row.type}`,
          subheaderLink: row.website_slug ? `/websites/${row.website_slug}` : `/${row.type}s`,
          subheaderClass: 'inactive-font-color',
        })
      }
    },
  ].concat(failedOnlyMode ? failedOnlyHeaders : noFilterHeaders);

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Updates History</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createSelectInput({
            name: 'website_slug_option',
            value: selectedWebsite,
            options: WebsiteHelper.buildSelectOptions(allWebsites),
            sortOff: true,
            isSearchable: true,
            onChange: handleSelectWebsite,
            placeholder: 'Select website...',
          })}
          {UserHelper.isAdminOrAgent() && JsxHelper.createSelectInput({
            name: 'partner_slug_option',
            value: selectedPartner,
            options: ArrayHelper.buildSelectOptions(allPartners, 'display_name', 'slug'),
            isSearchable: true,
            onChange: handleSelectPartner,
            placeholder: 'Select partner...',
            sortOff: true
          })}
          {!failedOnlyMode && JsxHelper.createButton({
            label: 'Failed Only',
            onClick: switchFailureMode,
            tooltip: 'Show only failed updates',
            disabled: loadingRefresh || loadingMore,
          })}
          {failedOnlyMode && JsxHelper.createButton({
            label: 'All Updates',
            classes: 'success--btn',
            onClick: switchFailureMode,
            tooltip: 'Show all updates',
            disabled: loadingRefresh || loadingMore,
          })}
          {!loadingRefresh && JsxHelper.createLoadMoreButton({ onClick: () => loadMoreRecords(), loading: loadingMore })}
          {!loadingMore && JsxHelper.createRefreshButton({ onClick: () => refreshRecords(), loading: loadingRefresh })}
          {JsxHelper.createBackButton('/reports')}
        </TitleBar.Actions>
      </TitleBar>
      <Content>
        <WPSDataTable
          customClass='package-history-table'
          columns={headers}
          body={tableData}
          loading={loadingRefresh || loadingMore}
          rowsPerPage={100}
          dataKey={'key'}
        />
      </Content>
    </Fragment>
  );
}

export default PackageUpdatesHistory;
