import React, { useState, Fragment } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { WPSButton } from 'styles/layout/buttons';
import { Row } from 'styles/layout/grid';
import WebsiteService from 'services/website';
import { useDispatch } from 'react-redux';
import { setGlobalErrorMsg } from 'store/global/globalActions';
import { Container } from 'styles/website/profile';
import { WPSBubble } from 'styles/layout/buttons';
import { SFTPDetails } from 'styles/website/profile';
import { WPSForm, WPSLabel, WPSInput } from 'styles/layout/forms';
import StringHelper from 'helpers/string';
import JsxHelper from 'helpers/jsx';
import { setGlobalSuccessMsg } from 'store/global/globalActions';

const SftpAccess = ({ slug }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState(false);
  const [showOutputTable, setShowOutputTable] = useState(false);

  const getSftpConnectDetails = (renew) => {
    setLoading(true);
    const apiFunc = renew ? WebsiteService.updateSftpUser : WebsiteService.getSftpUser;
    apiFunc({ website_slug: slug })
      .then(res => {
        let output = {}
        output['host'] = res.host;
        output['port'] = res.port;
        output['username'] = res.username;
        output['password'] = res.password;
        setOutput(output);
        setShowOutputTable(true);
        if (renew) {
          dispatch(setGlobalSuccessMsg({ onId: slug, model: 'SFTP password', action: 'generated' }));
        }
      })
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoading(false));
  };

  return (
    <Container className='margin-24'>
      <TitleBar className='titlebar padding-0'>
        <TitleBar.Title>Secure FTP Access</TitleBar.Title>
      </TitleBar>
      <p className='color-primary subheader padding-left-0'>Use SFTP (Secure File Transfer Protocol) to connect to your WordPress site.
      Note that the SFTP protocol is strictly enforced and you can not connect using the regular FTP scheme (use <b>sftp://[HOST]</b> instead).</p>
      {showOutputTable && (
        <Fragment>
          <WPSForm>
            <SFTPDetails className='margin-top-20'>
            {Object.keys(output).map((k,i) => (
              <WPSForm.RowItem key={i} style={{ position: 'relative', marginLeft: '0px', marginRight: '0px' }}>
                <WPSLabel className='margin-top-5'><strong>{StringHelper.capitalizeFirstLetter(k.replaceAll('_', ' '))}</strong></WPSLabel>
                <WPSInput
                  type='text'
                  value={output[k]}
                  className='sftp-input'
                  disabled={true}
                />
                <WPSBubble
                  color='link'
                  icon='copy'
                  padding='0'
                  margin='0'
                  className='sftp-icon'
                  onClick={() => JsxHelper.copyToClipboard(dispatch, output[k], StringHelper.capitalizeFirstLetter(k.replaceAll('_', ' ')))}>
                </WPSBubble>
              </WPSForm.RowItem>
            ))}
            </SFTPDetails>
          </WPSForm>
          <Row className='action-buttons'>
            <WPSButton
              className='execute--btn'
              type='button'
              onClick={() => getSftpConnectDetails(true)}
              loading={loading}>
              Generate New Password
            </WPSButton>
          </Row>
        </Fragment>
      )}
      {!showOutputTable && <Row className='action-buttons'>
          <WPSButton
            className='info--btn'
            type='button'
            onClick={() => getSftpConnectDetails(false)}
            loading={loading}>
            Retrieve Connection Details
          </WPSButton>
        </Row>
      }
    </Container>
  );
};

export default SftpAccess;
