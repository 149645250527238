import { dispatchPostPromise } from 'helpers';
import WebsiteService from 'services/website';
import ClientReportService from 'services/clientReport';
import { saveGlobalServerOption } from 'services/server';
import EmailService from 'services/emailtemplate';
import ReportService from 'services/report';

/*
  Action Types
*/
export const SETTING_SET_ALL = 'SETTING_SET_ALL';
export const SETTING_UPDATE = 'SETTING_UPDATE';
export const SETTING_WEBSITES_UPDATE = 'SETTING_WEBSITES_UPDATE';
export const SETTING_SERVERS_UPDATE = 'SETTING_SERVERS_UPDATE';
export const SETTING_EMAIL_TEMPLATE_UPDATE = 'SETTING_EMAIL_TEMPLATE_UPDATE';
export const GLOBAL_SMTP_MAIL_UPDATE = 'GLOBAL_SMTP_MAIL_UPDATE';
export const GLOBAL_NGINX_UPDATE = 'GLOBAL_NGINX_UPDATE';
export const GLOBAL_WP_DEFINES_UPDATE = 'GLOBAL_WP_DEFINES_UPDATE';
export const SETTING_CLIENT_REPORT_UPDATE = 'SETTING_CLIENT_REPORT_UPDATE';
export const GLOBAL_AUTO_UPDATER_UPDATE = 'GLOBAL_AUTO_UPDATER_UPDATE';
export const GLOBAL_CUSTOM_DEPLOYMENT_UPDATE = 'GLOBAL_CUSTOM_DEPLOYMENT_UPDATE';
export const GLOBAL_WHITE_LABEL_UPDATE = 'GLOBAL_WHITE_LABEL_UPDATE';
export const REPORTS_SETTINGS_UPDATE = 'REPORTS_SETTINGS_UPDATE';

/*
  Action Creators
*/

// Update a global SMTP Mail config
export const updateGlobalSMTPConfig = data => {
  return dispatchPostPromise(WebsiteService.updateGlobalSMTPConfig(data), GLOBAL_SMTP_MAIL_UPDATE);
};

// Update a global Nginx config
export const updateGlobalNginxConfig = data => {
  return dispatchPostPromise(WebsiteService.updateGlobalNginxConfig(data), GLOBAL_NGINX_UPDATE);
};

// Update the global auto updater config
export const updateGlobalAutoUpdaterConfig = data => {
  return dispatchPostPromise(WebsiteService.updateGlobalAutoUpdaterConfig(data), GLOBAL_AUTO_UPDATER_UPDATE);
};

// Update the global white label config
export const updateGlobalWhiteLabelConfig = data => {
  return dispatchPostPromise(WebsiteService.updateGlobalWhiteLabelConfig(data), GLOBAL_WHITE_LABEL_UPDATE);
};

// Update the global white label config
export const updateSingleReportSettings = data => {
  return dispatchPostPromise(ReportService.saveReportSettings(data), REPORTS_SETTINGS_UPDATE);
};

// Update the global custom deployment config
export const updateCustomDeploymentConfig = data => {
  return dispatchPostPromise(WebsiteService.updateGlobalCustomDeploymentConfig(data), GLOBAL_CUSTOM_DEPLOYMENT_UPDATE);
};

// Update website maintenance report config
export const updateClientReportConfig = data => {
  return dispatchPostPromise(ClientReportService.updatePartnerConfig(data), SETTING_CLIENT_REPORT_UPDATE);
};

// Update globalsettings
export const updateGlobalSettings = body => {
  return dispatch => dispatch({ type: SETTING_UPDATE, payload: body });
};

// Set global server options
export const updateGlobalServerOptionPromise = (data, optionName) => {
  return dispatchPostPromise(saveGlobalServerOption(data, optionName), SETTING_SERVERS_UPDATE);
};
export const updateGlobalServerOption = data => {
  return dispatch => dispatch({ type: SETTING_SERVERS_UPDATE, payload: data });
};

// Update global PHP constants
export const updateGlobalWPDefines = data => {
  return dispatchPostPromise( WebsiteService.updateGlobalPHPConstants(data), GLOBAL_WP_DEFINES_UPDATE);
};

// Update email templates
export const updateEmailTemplates = data => {
  return dispatchPostPromise(EmailService.update(data), SETTING_EMAIL_TEMPLATE_UPDATE);
};

// Reset email template
export const resetEmailTemplate = data => {
  return dispatchPostPromise(EmailService.resetEmailTemplate(data), SETTING_EMAIL_TEMPLATE_UPDATE);
};
