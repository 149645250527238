import React, { Fragment, useState } from 'react';
import { AuthContainer, ImageWrapper, FormWrapper } from 'styles/auth';
import { useEffect } from 'react';
import useQuery from 'hooks/useQuery';
import GuestService from 'services/guest';
import { PaymentTable } from 'styles/layout/tables';
import { WPSCheckbox, WPSForm, WPSInput } from 'styles/layout/forms';
import { WPSButton } from 'styles/layout/buttons';
import { RingLoader } from 'styles/layout/loaders';
import CustomerPaymentImg from 'assets/customer_payment.png';
import SuccessImg from 'assets/email_success.png';
import { isEmpty, getErrorMsg } from 'helpers';
import PlanDetails from 'components/guest/planDetails';
import StripeForm from 'components/guest/stripeForm';
import useDidMountEffect from 'hooks/useDidMountEffect';
import { Link } from 'react-router-dom';
import FormHelper from 'helpers/form';

const CustomerProfileSetup = () => {
  const query = useQuery();

  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(null);
  const [error, setError] = useState(null);
  const [agree, setAgree] = useState(false);
  const [addNewCard, setAddNewCard] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [step, setStep] = useState(1);
  const [defaultCreditCard, setDefaultCreditCard] = useState({});
  const [creditCards, setCreditCards] = useState({});
  const [stepsData, setStepsData] = useState({});
  const [stripeRes, setStripeRes] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tempLoginUrl, setTempLoginUrl] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [newLinkStatus, setNewLinkStatus] = useState('');

  // Verify customer email and token.
  useEffect(() => {
    const token = query.get('token');
    const email = query.get('email');

    const data = { token, email };

    GuestService.verifyProfileSetupToken(data)
      .then(res => {
        setDetails({ ...res, token, email });
        setAddNewCard(isEmpty(res.customer_details.credit_cards));
        setCreditCards(res.customer_details.credit_cards);
        setDefaultCreditCard(res.customer_details.credit_cards.find(c => c.is_default));
        initStepsData(res);
      })
      .catch(err => setError(err))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  useDidMountEffect(() => {
    setStep(4);
    if (stripeRes && stripeRes.type) {
      setDefaultCreditCard(stripeRes[stripeRes.type]);
      setCreditCards([...creditCards, stripeRes[stripeRes.type]]);
    }
  }, [stripeRes]);

  const onChange = e => {
    const { name, value } = e.target;
    setDetails(prev => ({
      ...prev,
      customer_details: { ...prev.customer_details, [name]: value },
    }));
  };

  const isDefaultCard = c => {
    return (
      defaultCreditCard.last4 === c.last4 &&
      defaultCreditCard.exp_month === c.exp_month &&
      defaultCreditCard.exp_year === c.exp_year
    );
  };

  const openPlanModal = subscription => {
    setSelectedSubscription(subscription);
    setModal(true);
  };

  const initFormData = () => {
    return {
      token: details.token,
      email: details.email,
      is_default: true,
      customer_details: details.customer_details,
      data: stripeRes,
    };
  };

  const submitPayment = e => {
    e.preventDefault();
    setFormLoading(true);
    const data = FormHelper.switchBooleans(initFormData());
    GuestService.processProfileSetupRequest(data)
      .then(res => {
        setSuccess(true);
        setStep(5);
        // @todo: add login url when customer UI is ready
        setTempLoginUrl(false);
      })
      .catch(err => window.logHelper.error(err))
      .finally(() => setFormLoading(false));
  };

  const initStepsData = data => {
    const termsAndCondsUrl = `/terms-and-conditions?page=billing&email=${data.partner_details.email}`;
    setStepsData({
      1: {
        pageTitle: '',
        imgTitle: 'Get Started',
        pageHeader: (
          <Fragment>
            Welcome to <b>{data.partner_details.name}</b>! Your account is now set up and you are
            (almost) ready to go. This 4-step guide walks you through reviewing your account details
            and creating a payment profile.
            <br />
            <br />
            {!isEmpty(data.subscriptions) ? 'Your current active subscriptions:' : ''}
          </Fragment>
        ),
      },
      2: {
        pageTitle: '',
        imgTitle: 'Billing Details',
        pageHeader: (
          <Fragment>
            Below are the billing details that will appear in all future invoices. You can change
            the billing details at anytime by logging into your personal dashboard.
          </Fragment>
        ),
      },
      3: {
        pageTitle: '',
        imgTitle: 'Secure Payment',
        pageHeader: (
          <Fragment>
            Please insert your credit card details. Your full card information are never stored on
            our systems and is secured by{' '}
            <a href='https://stripe.com' target='_blank' rel='noopener noreferrer'>
              Stripe
            </a>
            .
            <br />
            <br />
          </Fragment>
        ),
      },
      4: {
        pageTitle: '',
        imgTitle: 'Review & Agreement',
        pageHeader: (
          <Fragment>
            Please carefully read and review your information and the{' '}
            <a href={termsAndCondsUrl} target='_blank' rel='noopener noreferrer'>
              Terms and Conditions
            </a>{' '}
            agreement.
          </Fragment>
        ),
      },
      5: {
        pageTitle: 'You are ready to go',
        imgTitle: 'Profile Completed',
        pageHeader: (
          <Fragment>Congratulations! You have completed the setup of your profile.</Fragment>
        ),
      },
    });
  };

  const sendNewSecureLink = () => {
    const token = query.get('token');
    const email = query.get('email');

    const data = { token, email };
    setSendLoading(true);
    GuestService.sendNewSecureLink(data)
      .then(() => {
        setNewLinkStatus('success');
      })
      .catch(err => {
        setNewLinkStatus(`Error: ${err.data.error}`);
      })
      .finally(() => setSendLoading(false));
  };
  return (
    <AuthContainer>
      <ImageWrapper className='customer-profile-setup-image-wrapper'>
        <div className='side-bar'>
          <img src={success ? SuccessImg : CustomerPaymentImg} alt='Profile Setup' />
          <h1>{stepsData[step] ? stepsData[step].imgTitle : ''}</h1>
        </div>
      </ImageWrapper>
      <FormWrapper>
        {!success ? (
          loading ? (
            <Fragment>
              <h1 style={{ marginTop: '25%' }}>Verifying...</h1>
              <RingLoader color='primary' size='40' margin='16px 0' />
            </Fragment>
          ) : !details && error ? (
            <Fragment>
              <h1 style={{ marginTop: '25%' }}>{getErrorMsg(error)}</h1>
              <WPSButton className='info--btn' onClick={sendNewSecureLink} loading={sendLoading}>
                Resend secure link to email
              </WPSButton>
              {newLinkStatus === 'success' && !isEmpty(newLinkStatus) && (
                <div className='message success'>
                  A new secure link has been sent to your email.
                </div>
              )}
              {newLinkStatus !== 'success' && !isEmpty(newLinkStatus) && (
                <div className='message error'>{newLinkStatus}</div>
              )}
            </Fragment>
          ) : (
            <WPSForm
              className='payment-form'
              onSubmit={submitPayment}
              style={{ alignItems: 'center' }}>
              <h1>{stepsData[step] ? stepsData[step].pageTitle : ''}</h1>
              <img
                style={{ maxWidth: '150px', maxHeight: '150px' }}
                src={details.partner_details.logo_url}
                alt={`${details.partner_details.name} Logo`}
              />
              {step === 1 && (
                <Fragment>
                  <div className='step-1'>
                    <p className='notes'>{stepsData[step].pageHeader}</p>
                    <PaymentTable>
                      {details &&
                        !isEmpty(details.subscriptions) &&
                        details.subscriptions.map((s, index) => (
                          <Fragment key={index}>
                            <li>
                              <span className='title'>{s.subscription_name}</span>
                              <span className='price'>${s.price}</span>
                            </li>
                            <li className='plan-details'>
                              <span onClick={() => openPlanModal(s)}>See plan data</span>
                              <span>per {s.interval}</span>
                            </li>
                          </Fragment>
                        ))}
                    </PaymentTable>
                    <WPSButton className='success2' type='button' onClick={() => setStep(2)}>
                      Get Started
                    </WPSButton>
                  </div>
                </Fragment>
              )}
              {step === 2 && (
                <Fragment>
                  <div className='step-2'>
                    <div className='form-container'>
                      <p className='notes'>{stepsData[step].pageHeader}</p>
                      <label>Address 1</label>
                      <WPSInput
                        type='text'
                        name='business_address_1'
                        value={details.customer_details.business_address_1}
                        onChange={onChange}
                      />
                      <label>Address 2</label>
                      <WPSInput
                        type='text'
                        name='business_address_2'
                        value={details.customer_details.business_address_2}
                        onChange={onChange}
                      />
                      <label>City</label>
                      <WPSInput
                        type='text'
                        name='business_city'
                        value={details.customer_details.business_city}
                        onChange={onChange}
                      />
                      <label>ZIP/Post Code</label>
                      <WPSInput
                        type='text'
                        name='business_postcode'
                        value={details.customer_details.business_postcode}
                        onChange={onChange}
                      />
                      <div style={{ display: 'inline-flex', marginTop: '20px' }}>
                        <WPSButton
                          className='prev prev--btn'
                          type='button'
                          onClick={() => setStep(step - 1)}>
                          Previous
                        </WPSButton>
                        <WPSButton
                          className='next success2'
                          type='button'
                          onClick={() => setStep(step + 1)}>
                          Next
                        </WPSButton>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
              {step === 3 && (
                <Fragment>
                  <div className='step-3'>
                    <p className='notes' style={{ marginBottom: '0px' }}>
                      {stepsData[step].pageHeader}
                    </p>
                    {!addNewCard && (
                      <Fragment>
                        <p className='notes' style={{ marginTop: '0px' }}>
                          Your current registered credit cards:
                        </p>
                        <PaymentTable>
                          {!isEmpty(creditCards) &&
                            creditCards.map((c, index) => (
                              <Fragment key={index}>
                                <li>
                                  <span className='title'>{c.brand}</span>
                                  <span className='price'>{c.last4}</span>
                                </li>
                                <li className='plan-details'>
                                  <span className='default-cc-tip'>
                                    {isDefaultCard(c) ? 'default' : ''}
                                  </span>
                                  <span>
                                    {c.exp_month < 10 ? '0' : ''}
                                    {c.exp_month}/{c.exp_year}
                                  </span>
                                </li>
                              </Fragment>
                            ))}
                        </PaymentTable>
                        <label className='newcard-label' htmlFor='newcard'>
                          <WPSCheckbox
                            type='checkbox'
                            id='newcard'
                            checked={false}
                            onChange={() => setAddNewCard(true)}
                          />
                          I want to update my default credit card.
                        </label>
                        <div
                          style={{
                            display: 'inline-flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginTop: '0px',
                          }}>
                          <WPSButton
                            className='prev prev--btn'
                            type='button'
                            onClick={() => setStep(step - 1)}>
                            Previous
                          </WPSButton>
                          <WPSButton
                            className='next success2'
                            type='button'
                            onClick={() => setStep(step + 1)}>
                            Next
                          </WPSButton>
                        </div>
                      </Fragment>
                    )}
                    {addNewCard && (
                      <StripeForm
                        setStripeRes={setStripeRes}
                        setStep={setStep}
                        setAddNewCard={!isEmpty(creditCards) ? () => setAddNewCard(false) : null}
                        stripeKey={details.stripe_publishable_key}
                      />
                    )}
                  </div>
                </Fragment>
              )}
              {step === 4 && (
                <Fragment>
                  <div className='step-4'>
                    <p className='notes'>{stepsData[step].pageHeader}</p>
                    {!isEmpty(details.subscriptions) && (
                      <p className='title'>Active Subscriptions</p>
                    )}
                    <PaymentTable>
                      {!isEmpty(details.subscriptions) &&
                        details.subscriptions.map((s, index) => (
                          <Fragment key={index}>
                            <li>
                              <span className='title'>{s.subscription_name}</span>
                              <span className='price'>${s.price}</span>
                            </li>
                            <li className='plan-details'>
                              <span onClick={() => openPlanModal(s)}>See plan data</span>
                              <span>per {s.interval}</span>
                            </li>
                          </Fragment>
                        ))}
                    </PaymentTable>
                    <p className='title'>Billing Address</p>
                    <PaymentTable>
                      <li>
                        <span className='title'>Address 1</span>
                        <span className='addr'>{details.customer_details.business_address_1}</span>
                      </li>
                      <li>
                        <span className='title'>Address 2</span>
                        <span className='addr'>{details.customer_details.business_address_2}</span>
                      </li>
                      <li>
                        <span className='title'>City</span>
                        <span className='city'>{details.customer_details.business_city}</span>
                      </li>
                      <li>
                        <span className='title'>ZIP/Post Code</span>
                        <span className='zip'>{details.customer_details.business_postcode}</span>
                      </li>
                    </PaymentTable>
                    <p className='title'>Credit Card</p>
                    {!isEmpty(creditCards) && (
                      <PaymentTable>
                        {creditCards.map((c, index) => (
                          <Fragment key={index}>
                            <li>
                              <span className='title'>{c.brand}</span>
                              <span className='price'>{c.last4}</span>
                            </li>
                            <li className='plan-details'>
                              <span className='default-cc-tip'>
                                {isDefaultCard(c) ? 'default' : ''}
                              </span>
                              <span>
                                {c.exp_month < 10 ? '0' : ''}
                                {c.exp_month}/{c.exp_year}
                              </span>
                            </li>
                          </Fragment>
                        ))}
                      </PaymentTable>
                    )}
                    <label className='agree-label' htmlFor='agree'>
                      <WPSCheckbox
                        type='checkbox'
                        id='agree'
                        checked={agree}
                        onChange={() => setAgree(!agree)}
                      />
                      I have read and accept the terms and conditions
                    </label>
                    <div
                      style={{
                        display: 'inline-flex',
                        marginTop: '0px',
                        marginBottom: '10px',
                        width: '100%',
                      }}>
                      <WPSButton
                        className='prev prev--btn'
                        type='button'
                        onClick={() => setStep(step - 1)}>
                        Previous
                      </WPSButton>
                      <WPSButton
                        style={{ marginTop: '0px' }}
                        type='submit'
                        disabled={!agree}
                        className='next next--btn'
                        loading={formLoading}>
                        Finish
                      </WPSButton>
                    </div>
                  </div>
                </Fragment>
              )}
            </WPSForm>
          )
        ) : (
          <Fragment>
            <h1 style={{ marginTop: '25%' }}>{stepsData[step].pageTitle}</h1>
            <p>{stepsData[step].pageHeader}</p>
            {tempLoginUrl && (
              <Link to={tempLoginUrl}>
                <WPSButton className='primary--btn'>Go to dashboard</WPSButton>
              </Link>
            )}
          </Fragment>
        )}
      </FormWrapper>
      <PlanDetails open={modal} setOpen={setModal} plan={selectedSubscription} />
    </AuthContainer>
  );
};

export default CustomerProfileSetup;
