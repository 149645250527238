import { postRequest, deleteRequest, getRequest, downloadRequest } from '../config/axiosRequest';
import env from '../config/env';

/**
 * Fetches the metadata from the uploaded content.
 *
 * @param   {FormData}  data
 * @return  {Array}
 */
const fetch = data => {
  return postRequest({
    url: env.buildApiEndpoint('packages/fetch'),
    data
  });
};

/**
 * Creates a new package.
 *
 * @param   {Array}   data
 * @return  {Array}
 */
const create = data => {
  return postRequest({
    url: env.buildApiEndpoint('packages'),
    data
  });
};

/**
 * Updates the package.
 *
 * @param   {Array}   data
 * @return  {Array}
 */
const update = data => {
  return postRequest({
    url: env.buildApiEndpoint(`packages/${data.package_slug}`),
    data
  });
};

/**
 * Updates the package from the specified URL.
 *
 * @param   {Array}   data
 * @return  {Array}
 */
const updateFromUrl = data => {
  return postRequest({
    url: env.buildApiEndpoint(`packages/${data.package_slug}/update-from-url`),
    data
  });
};

/**
 * Get the presigned download URL of the specified package.
 *
 * @param   {Object}   data
 * @return  {Object}
 */
const download = data => {
  return downloadRequest({
    url: env.buildApiEndpoint(`packages/${data.package_slug}/download-url`),
    data
  });
};

/**
 * Sets the auto update of the specified package.
 *
 * @param   {Array}   data
 * @return  {Array}
 */
const setAutoUpdate = data => {
  return postRequest({
    url: env.buildApiEndpoint(`packages/${data.package_slug}/auto-update`),
    data,
  });
};

/**
 * Sets the propagate updates of the specified package.
 *
 * @param   {Array}   data
 * @return  {Array}
 */
const setPropagateUpdates = data => {
  return postRequest({
    url: env.buildApiEndpoint(`packages/${data.package_slug}/propagate-updates`),
    data,
  });
}

/**
 * Get the git webhook URL of the specified package.
 *
 * @param   {Object}   data
 * @return  {Object}
 */
const getWebhookUrl = data => {
  return getRequest({
    url: env.buildApiEndpoint(`packages/git/webhook-url`),
    data
  });
};

/**
 * Generates unique SSH + Webhook API keys.
 *
 * @return {Object}
 */
const generateGitKeys = () => {
  return postRequest({
    url: env.buildApiEndpoint(`packages/git/keys`),
    data: {}
  });
};

/**
 * Fetches metadata from git repository.
 *
 * @param   {Object}   data
 * @return {Object}
 */
const fetchFromGit = (data) => {
  return postRequest({
    url: env.buildApiEndpoint(`packages/git/fetch`),
    data
  });
};

/**
 * Fetches metadata from git repository.
 *
 * @param   {Object}   data
 * @return {Object}
 */
const createFromGit = (data) => {
  return postRequest({
    url: env.buildApiEndpoint(`packages/git/install`),
    data
  });
};

/**
 * List commits history items.
 *
 * @param   {Array}   data
 * @return  {Array}
 */
const fetchGitEvents = data => {
  return getRequest({
    url: env.buildApiEndpoint(`packages/git/${data.package_slug}/events`),
    data,
  });
};

/**
 * Delete the specified package.
 *
 * @param   {Object}   data
 * @return  {Object}
 */
export const del = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`packages/${data.package_slug}`),
    data
  });
};

const PackageService = {
  create,
  fetch,
  update,
  updateFromUrl,
  download,
  delete: del,
  generateGitKeys,
  fetchFromGit,
  createFromGit,
  fetchGitEvents,
  getWebhookUrl,
  setAutoUpdate,
  setPropagateUpdates,
};

export default PackageService;
