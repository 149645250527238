import React from 'react';
import ArrayHelper from 'helpers/array';
import { isEmptyOrNull } from 'helpers';
import JsxHelper from 'helpers/jsx';
import { StepperStep } from 'styles/website/profile';
import StringHelper from 'helpers/string';
import { WPSForm } from 'styles/layout/forms';
import env from 'config/env';

// ------------------------------
// CONSTANTS
// ------------------------------

const GIT_SERVICES = [
  {
    name: 'Bitbucket',
    value: 'bitbucket',
    placeholder: 'https://bitbucket.org/MyAgency/my-agency-plugin',
    regexp: 'https://bitbucket.org/(.*?)/([^/]+)/?', 
  },
  {
    name:'GitHub',
    value:'github',
    placeholder: 'https://github.com/MyAgency/my-agency-plugin',
    regexp: 'https://github.com/(.*?)/([^/]+)/?', 
  },
];

const GIT_SERVICE_SELECT_OPTIONS = ArrayHelper.buildSelectOptions(GIT_SERVICES, 'name', 'value')

// ------------------------------
// PRIVATE METHODS
// ------------------------------

const _findGitService = (value) => ArrayHelper.find(GIT_SERVICES, 'value', value);

const _renderKnowledgeBaseLink = (type, service) => {
  const path = type === 'WordPress' ?
    `how-to-create-wordpress-from-git` : // websites.
    `how-to-setup-${service}-with-staq`; // packages.
  return <div style={{marginTop: '7px'}}>For detailed instructions, visit our {JsxHelper.createFaqLink(path, 'knowledge base')}.</div>
}

const _renderGithubWebhookInstructions = (repoUrl, sshPublicKey, webhookApiUrl) => {
  const urlPath = '/settings/hooks/';
  const pageName = 'Webhooks';
  const urlHtml = JsxHelper.createSubheaderLink(repoUrl + urlPath, pageName, (sshPublicKey ? 'primary' : 'disabled') + "-font-color")
  return (<div className={webhookApiUrl ? '' : 'disabled-font-color'}>
    <p>2. <strong>Navigate</strong> to your repository's {urlHtml} page.</p>
    <p>&nbsp;&nbsp;&nbsp;a. <strong>Click</strong> the <b>Add webhook</b> button.</p>
    <p>&nbsp;&nbsp;&nbsp;b. <strong>Copy & paste</strong> the secure URL from step 1 to the <b>Payload URL</b> field.</p>
    <p>&nbsp;&nbsp;&nbsp;c. <strong>Leave</strong> the <b>Secret</b> field empty.</p>
    <p>&nbsp;&nbsp;&nbsp;d. <strong>Validate</strong> only the <b>Just the push event.</b> checkbox under Triggers is checked.</p>
    <p>&nbsp;&nbsp;&nbsp;e. <strong>Validate</strong> the <b>Active</b> checkbox is checked.</p>
    <p>&nbsp;&nbsp;&nbsp;f. <strong>Click</strong> the <b>Add webhook</b> button to save the webhook.</p>
    <p>&nbsp;&nbsp;&nbsp;g. <strong>Validate</strong> your webhook is added to the {urlHtml} page.</p>
  </div>)
}

const _renderBitbucketWebhookInstructions = (repoUrl, sshPublicKey, webhookApiUrl) => {
  const urlPath = '/admin/webhooks/';
  const pageName = 'Webhooks';
  const urlHtml = JsxHelper.createSubheaderLink(repoUrl + urlPath, pageName, (sshPublicKey ? 'primary' : 'disabled') + "-font-color")
  return (<div className={webhookApiUrl ? '' : 'disabled-font-color'}>
    <p>2. <strong>Navigate</strong> to your repository's {urlHtml} page.</p>
    <p>&nbsp;&nbsp;&nbsp;a. <strong>Click</strong> the <b>Add webhook</b> button.</p>
    <p>&nbsp;&nbsp;&nbsp;b. <strong>Set</strong> the title to anything (e.g. <b><span style={{fontStyle: 'italic'}}>{env.getBrandShortName().toLowerCase()}-api</span></b>).</p>
    <p>&nbsp;&nbsp;&nbsp;c. <strong>Copy & paste</strong> the secure URL from step 1 to the <b>URL</b> field.</p>
    <p>&nbsp;&nbsp;&nbsp;d. <strong>Validate</strong> the <b>Active</b> checkbox under Status is checked.</p>
    <p>&nbsp;&nbsp;&nbsp;e. <strong>Validate</strong> only the <b>Push</b> checkbox under Triggers is checked.</p>
    <p>&nbsp;&nbsp;&nbsp;f. <strong>Save</strong> the webhook.</p>
    <p>&nbsp;&nbsp;&nbsp;g. <strong>Validate</strong> your webhook is added under <b>Repository hooks</b>.</p>
  </div>)
}

const _renderBitbucketSshKeyInstructions = (repoUrl, sshPublicKey) => {
  const urlPath = '/admin/access-keys/';
  const pageName = 'Access keys';
  const keyText = 'Add key';
  const urlHtml = JsxHelper.createSubheaderLink(repoUrl + urlPath, pageName, (sshPublicKey ? 'primary' : 'disabled') + "-font-color")
  return (<div className={sshPublicKey ? '' : 'disabled-font-color'}>
    <p>2. <strong>Navigate</strong> to your repository's {urlHtml} page.</p>
    <p>&nbsp;&nbsp;&nbsp;a. <strong>Click</strong> the <b>{keyText}</b> button.</p>
    <p>&nbsp;&nbsp;&nbsp;b. <strong>Label</strong> the key with anything (e.g. <b><span style={{fontStyle: 'italic'}}>{env.getBrandShortName().toLowerCase()}-api</span></b>).</p>
    <p>&nbsp;&nbsp;&nbsp;c. <strong>Copy & paste</strong> the SSH key from step 1 to the <b>Key</b> field.</p>
    <p>&nbsp;&nbsp;&nbsp;d. <strong>Save</strong> the SSH key.</p>
  </div>)
}

const _renderGithubSshKeyInstructions = (repoUrl, sshPublicKey) => {
  const urlPath = '/settings/keys/';
  const pageName = 'Deploy keys';
  const keyText = 'Add deploy key';
  const urlHtml = JsxHelper.createSubheaderLink(repoUrl + urlPath, pageName, (sshPublicKey ? 'primary' : 'disabled') + "-font-color")
  return (<div className={sshPublicKey ? '' : 'disabled-font-color'}>
    <p>2. <strong>Navigate</strong> to your repository's {urlHtml} page.</p>
    <p>&nbsp;&nbsp;&nbsp;a. <strong>Click</strong> the <b>{keyText}</b> button.</p>
    <p>&nbsp;&nbsp;&nbsp;b. <strong>Label</strong> the key with anything (e.g. <b><span style={{fontStyle: 'italic'}}>{env.getBrandShortName().toLowerCase()}-api</span></b>).</p>
    <p>&nbsp;&nbsp;&nbsp;c. <strong>Copy & paste</strong> the SSH key from step 1 to the <b>Key</b> field.</p>
    <p>&nbsp;&nbsp;&nbsp;d. <strong>Validate</strong> that the <b>Allow write access</b> checkbox is unchecked.</p>
    <p>&nbsp;&nbsp;&nbsp;e. <strong>Save</strong> the SSH key.</p>
  </div>)
}

// Checks whether the repository URL is valid.
const _parseRepositoryUrl = (repoUrl, service) => {
  const result = { error: null, url: null };
  const gitService = _findGitService(service);
  const regexp = new RegExp(gitService.regexp);
  if (!regexp.test(repoUrl)) {
    result.error = 'Invalid repository URL';
    return result;
  }
  const matches = repoUrl.match(regexp);
  result.url = StringHelper.trimRight(matches[0], '/');
  return result;
}

const _renderSshKeyInstructions = (service, repoUrl, sshPublicKey) => {
  if (service === 'bitbucket') {
    return _renderBitbucketSshKeyInstructions(repoUrl, sshPublicKey);
  } else if (service === 'github') {
    return _renderGithubSshKeyInstructions(repoUrl, sshPublicKey);
  }
  return '';
}

// ------------------------------
// PUBLIC METHODS
// ------------------------------

// Render the webhook instructions
const renderWebhookInstructions = (service, repoUrl, sshPublicKey, webhookApiUrl) => {
  if (service === 'bitbucket') {
    return _renderBitbucketWebhookInstructions(repoUrl, sshPublicKey, webhookApiUrl);
  } else if (service === 'github') {
    return _renderGithubWebhookInstructions(repoUrl, sshPublicKey, webhookApiUrl);
  }
  return '';
}

// Render "Set Git Repository" step
const renderSetGitRepositoryStep = (type, service, setService, repoUrl, setRepoUrl, repoUrlError, setRepoUrlError, sshPublicKey, mainBranch, setMainBranch, loading, goToStep) =>
  <WPSForm className='step-custom-form'>
    <StepperStep>
      <p className='step-title'>
        Configure the Git repository for your {type} that you want {env.getBrandShortName()} to deploy and to monitor for changes.
        Make sure to enter the exact production branch name, as changes will be ignored if this is incorrect.
        {_renderKnowledgeBaseLink(type, service)}
      </p>
      <div className='notice'>
        <WPSForm.RowItem>
          {JsxHelper.createSelectInput({
            label: 'Select Git service',
            name: 'git_service',
            value: service,
            options: GIT_SERVICE_SELECT_OPTIONS,
            onChange: e => setService(e.target.value),
            required: true,
            disabled: !isEmptyOrNull(sshPublicKey),
          })}
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          {JsxHelper.createTextInput({
            label: 'Enter the repository URL',
            name: 'repository_url',
            value: repoUrl,
            placeholder: _findGitService(service).placeholder,
            disabled: !isEmptyOrNull(sshPublicKey),
            onChange: e => setRepoUrl(e.target.value),
            error: repoUrlError,
          })}
        </WPSForm.RowItem>
        <WPSForm.RowItem style={{ position: 'relative', marginLeft: '0px' }}>
          {JsxHelper.createTextInput({
            label: 'Enter the production branch',
            name: 'production_branch',
            placeholder: 'e.g. main',
            disabled: !isEmptyOrNull(sshPublicKey),
            value: mainBranch,
            onChange: e => setMainBranch(e.target.value),
            required: true,
          })}
        </WPSForm.RowItem>
      </div>
      {JsxHelper.createButton({
        label: 'Add SSH Keys',
        classes: 'next--btn',
        margin: '24px 0 0 6px',
        disabled: loading || isEmptyOrNull(repoUrl) || isEmptyOrNull(mainBranch),
        onClick: () => {
          const parsedUrl = _parseRepositoryUrl(repoUrl, service);
          if (parsedUrl.error) {
            setRepoUrlError(parsedUrl.error);
            return;
          }
          setRepoUrlError(null);
          setRepoUrl(parsedUrl.url);
          goToStep();
        },
      })}
    </StepperStep>
  </WPSForm>

// Render "Set SSH Keys" step
const renderSetSshKeysStep = (type, service, dispatch, repoUrl, sshPublicKey, onGenerateKeys, onConnectRepository, sshLoading, loading) => 
  <WPSForm className='step-custom-form'>
    <StepperStep>
      <p className='step-title'>
        Follow the steps below to grant {env.getBrandShortName()} read-only access to your repository via SSH keys.
        To simplify and secure the process, {env.getBrandShortName()} will generate the SSH public and private keys for you.
        Simply copy and paste the public key into your repository's settings.
        {_renderKnowledgeBaseLink(type, service)}
      </p>
      <div className='ns-steps'>
        <p>1. <strong>Generate</strong> secure SSH keys by clicking the following button:</p>
        {!sshPublicKey && <div className='ns-record'>{JsxHelper.createBubble({
          background: 'warning',
          icon: 'lock',
          padding: '4px 8px',
          loading: sshLoading,
          onClick: onGenerateKeys,
          text: 'Generate SSH key',
        })}</div>}
        {sshPublicKey && <div className='ns-record'>{JsxHelper.createCopyButton({
          label: 'Copy SSH key',
          value: sshPublicKey,
          dispatch,
        })}</div>}
        {_renderSshKeyInstructions(service, repoUrl, sshPublicKey)}
      </div>
      {JsxHelper.createButton({
        classes: 'next--btn',
        label: 'Connect Repository',
        margin: '24px 0 0 7px',
        disabled: !sshPublicKey,
        onClick: onConnectRepository,
        loading
      })}
    </StepperStep>
  </WPSForm>

// Render "Review" step
const renderReviewStep = (type, service, jsx, goToStep) =>
  <WPSForm className='step-custom-form'>
    <StepperStep>
      <p className='step-title'>
        Success! Your repository is now connected. Please review the {type} details to ensure everything is accurate.
        Once you're satisfied with the review, proceed to add a webhook so {env.getBrandShortName()} can be notified whenever new code is pushed to your repository.
        {_renderKnowledgeBaseLink(type, service)}
      </p>
      {jsx}
      {JsxHelper.createButton({
        classes: 'next--btn',
        label: 'Add Webhook',
        margin: '0 0 0 7px',
        onClick: goToStep
      })}
    </StepperStep>
  </WPSForm>

// Render "Add Webhook" step
const renderAddWebhookStep = (type, service, dispatch, repoUrl, sshPublicKey, webhookApiUrl, confirmAdd, setConfirmAdd, loading, onBtnClick, onConfirm) =>
  <WPSForm className='step-custom-form'>
    <StepperStep>
      <p className='step-title'>
        Follow the steps below to enable {env.getBrandShortName()} to automatically receive notifications whenever new code is pushed to your repository.
        This ensures your plugin stays up to date with the latest version of your configured branch.
        {_renderKnowledgeBaseLink(type, service)}
      </p>
      <div className='ns-steps'>
        <p>1. <strong>Copy</strong> the unique <b>API endpoint</b> to your {type} by clicking the following button:</p>
        {webhookApiUrl && <div className='ns-record'>
          {JsxHelper.createCopyButton({ small: true, label: 'Copy secure URL', value: webhookApiUrl, dispatch })}
        </div>}
        {GitHelper.renderWebhookInstructions(service, repoUrl, sshPublicKey, webhookApiUrl)}
      </div>
      <div className='display-flex'>{JsxHelper.createCheckbox({
          label: 'I confirm that I added the Webhook as instructed above.',
          id: 'confirmwh',
          class: 'margin-top-12',
          checked: confirmAdd,
          onChange: () => {
            setConfirmAdd(!confirmAdd)
            onConfirm && onConfirm()
          }
        })}</div>
        {confirmAdd && onBtnClick && <div className='display-flex action-buttons'>{
          JsxHelper.createButton({
            label: 'Install ' + StringHelper.capitalizeFirstLetter(type),
            loading,
            disabled: !webhookApiUrl,
            onClick: onBtnClick,
            margin: '24px 0 0 7px',
          })}
        </div>}
    </StepperStep>
  </WPSForm>

const GitHelper = {
  renderWebhookInstructions,
  renderSetGitRepositoryStep,
  renderSetSshKeysStep,
  renderReviewStep,
  renderAddWebhookStep,
};
  
export default GitHelper;
