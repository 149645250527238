import React, { Fragment, useState, useEffect, useRef } from 'react';
import { StatusContainer } from 'styles/website';
import { useHistory } from 'react-router-dom';
import WebsiteService from 'services/website';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalInfoMsg, setGlobalSuccessMsg, setGlobalPleaseWaitMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { deleteWebsite, fetchWebsite, purgeWebsiteCache } from 'store/website/websiteActions';
import UserHelper from 'helpers/user';
import { useForm } from 'react-hook-form';
import { getPartnerBySlug } from 'store/user/userSelectors';
import { partnerSelector } from 'store/me/meSelectors';
import WebsiteHelper from 'helpers/website';
import useModal from 'hooks/useModal';
import './website.css';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import UrlHelper from 'helpers/url';
import { HelpButton, NotificationBarList } from 'styles/layout/userTopBar';
import useConfirm from 'hooks/useConfirm';
import useOnClickOutside from 'utils/useOnClickOutside';

const WebsiteStatus = ({ website }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const modalDialog = useModal();
  const { handleSubmit, register, errors } = useForm({ reValidateMode: 'resetCredentials' });
  const [loadingWhileLogin, setLoadingWhileLogin] = useState([]);
  const [loadingWhileRefresh, setLoadingWhileRefresh] = useState(false);
  const [loadingWhileClearCache, setLoadingWhileClearCache] = useState(false);
  const [loadingWhileGitPull, setLoadingWhileGitPull] = useState(false);
  const [reseting, setReseting] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [credentials, setCredentials] = useState(null);
  const [modal, setModal] = useState(false);
  const [resetErrorMessage, setResetErrorMessage] = useState(null);
  const statusStyle = WebsiteHelper.getStatusStyle(website.status, website.status_reason);
  const stateStyle = WebsiteHelper.getStateStyle(website.state);
  const cdn = WebsiteHelper.getCdnCache(website) || WebsiteHelper.getCdnProxy(website) || WebsiteHelper.getCloudflareZone(website);
  const cdnStyle = WebsiteHelper.getCdnStatus(cdn);
  const partnerUserForAdmin = useSelector(getPartnerBySlug(website.partner_slug));
  const partnerUser = useSelector(partnerSelector);
  const confirm = useConfirm();
  const ref = useRef();

  const userLoginForAdmin = partnerUserForAdmin ? (partnerUserForAdmin.custom_wp_admin_user || partnerUserForAdmin.slug) : null;
  const userEmailForAdmin = partnerUserForAdmin ? partnerUserForAdmin.email : null;

  const [resetData, setResetData] = useState({
    user_login: UserHelper.isAdminOrAgent() ? userLoginForAdmin : partnerUser?.custom_wp_admin_user || partnerUser?.slug,
    user_email: UserHelper.isAdminOrAgent() ? userEmailForAdmin : partnerUser?.email,
  });
  const pageVisibilityRef = useRef(true);

  useEffect(() => {
    // store the tab/window Visibility State, because the clipboard will not
    // work if the tab hidden, to work correctly tab/document need to be focused/visible.
    document.addEventListener('visibilitychange', () => {
      pageVisibilityRef.current = document.visibilityState === 'visible';
    });
    // eslint-disable-next-line
  }, []);

  // -------------------------
  // CALL BACKS
  // -------------------------

  useOnClickOutside(ref, () => setOpenMenu(false));

  const openHome = () => UrlHelper.open(`https://${website.default_domain}`);

  const handleDeleteWebsite = website => {
    dispatch(setGlobalPleaseWaitMsg({ id: WebsiteHelper.getLabel(website), model: 'website', action: 'deleted' }));
    dispatch(deleteWebsite(website)).then(() => {
        dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'website', action: 'deleted' }))
        WebsiteHelper.goToIndex(history);
    });
  };

  const pullGitRepository = () => {
    if (loadingWhileGitPull) {
      return;
    }
    setLoadingWhileGitPull(true);
    dispatch(setGlobalPleaseWaitMsg({ onId: WebsiteHelper.getLabel(website), model: 'Git repository', action: 'pulled' }));
    WebsiteService.pullGit({ website_slug: website.slug })
      .then(() => dispatch(setGlobalSuccessMsg({ onId: WebsiteHelper.getLabel(website), model: 'Git repository', action: 'pulled' })))
      .catch(err => dispatch(setGlobalErrorMsg(err)))
      .finally(() => setLoadingWhileGitPull(false));
  };

  const onClearCache = () => {
    if (loadingWhileClearCache) {
      return;
    }
    setLoadingWhileClearCache(true);
    const data = {
      website_slug: website.slug,
    };
    dispatch(setGlobalPleaseWaitMsg({ id: WebsiteHelper.getLabel(website), model: 'Website cache', action: 'cleared' }));
    dispatch(purgeWebsiteCache(data))
      .then(() => dispatch(setGlobalSuccessMsg({ onId: WebsiteHelper.getLabel(website), model: 'Website cache', action: 'cleared' })))
      .catch((err) => dispatch(setGlobalErrorMsg(err)))
  }

  const onRefreshWebsite = () => {
    const data = {
      website_slug: website.slug,
      check_status: true,
      check_hosting_provider: true
    }
    setLoadingWhileRefresh(true);
    dispatch(setGlobalPleaseWaitMsg({ id: WebsiteHelper.getLabel(website), model: 'website', action: 'refreshed' }));
    dispatch(fetchWebsite(data))
    .then(() => dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'website', action: 'refreshed' })))
    .finally(() => setLoadingWhileRefresh(false));
  }

  // Reset website credentials
  const resetCredentials = async () => {
    if (reseting) {
      window.logHelper.warn('Reset credentials is already in progress.');
      return;
    }
    setReseting(true);
    dispatch(setGlobalInfoMsg({ text: 'Website credentials are being reset...' }));

    const data = {
      website_slug: website.slug,
      user_login: resetData.user_login,
      user_email: resetData.user_email,
    };

    await WebsiteService.resetCredentials(data)
      .then(() => {
        dispatch(setGlobalSuccessMsg({ model: 'credentials', onId: WebsiteHelper.getLabel(website), plural: true, action: 'reset', }));
        setModal(false);
        setResetErrorMessage(null);
      })
      .catch(err => {
        const errorText = err.data && err.data.error ? err.data.error : '';
        setResetErrorMessage(errorText);
        setModal(true);
      })
      .finally(() => setReseting(false));
  };

  // Fetch credentials and copy to clipboard
  const copyCredentials = async (type) => {
    const callback = (_credentials) => {
      _credentials = _credentials || credentials;
      const text = type === 'user' ? _credentials.user : _credentials.pass;
      JsxHelper.copyToClipboard(dispatch, text, type === 'user' ? 'Username' : 'Password');
    };
    credentials ? callback() : fetchCredentials(callback);
  }

  const fetchCredentials = async (callback) => {
    if (fetching) {
      dispatch(setGlobalInfoMsg({ text: 'Website credentials are being fetched.' }));
      return;
    }
    dispatch(setGlobalInfoMsg({ text: `Fetching credentials for ${WebsiteHelper.getLabel(website)}...` }));
    const data = { website_slug: website.slug };
    setFetching(true);
    WebsiteService.fetchCredentials(data)
      .then(res => {
        const _credentials = { user: res.user, pass: res.pass, role: res.role };
        setCredentials(_credentials);
        callback && callback(_credentials);
      }).finally(() => setFetching(false));
  }

  // -------------------------
  // RENDER
  // -------------------------

  const actions = [
    {
      icon: 'login',
      value: 'Login (One Click)',
      onClick: () => WebsiteHelper.dispatchGetWPLoginToken(website, loadingWhileLogin, setLoadingWhileLogin, dispatch, modalDialog),
    },
    {
      icon: 'lock',
      value:'Reset Credentials',
      onClick: resetCredentials
    },
    {
      icon: 'user',
      value: 'Copy Username',
      onClick: () => copyCredentials('user'),
    },
    {
      icon: 'password',
      value: 'Copy Password',
      onClick: () => copyCredentials('pass'),
    },
    {
      icon: 'refresh',
      value: 'Refresh Status',
      onClick: onRefreshWebsite,
    },
    {
      icon: 'trash',
      value:'Clear Cache',
      onClick: onClearCache,
    },
    {
      icon: 'linkicon',
      value: 'Visit Website',
      onClick: openHome
    },
    {
      icon: 'git',
      value: 'Pull Git Changes',
      onClick: pullGitRepository,
      doHide: item => !item.is_git,
    },
    {
      icon: 'remove',
      value: 'Delete',
      doHide: item => !UserHelper.hasPermissions(`website:delete:${item.slug}`),
      onClick: () => DialogHelper
        .confirmDelete(confirm, WebsiteHelper.getLabel(website), 'website')
        .then(() => handleDeleteWebsite(website)),
    },
  ];

  const handleOnChange = e => {
    const { name, value } = e.target;
    setResetData(prev => ({ ...prev, [name]: value }));
  };
  const handleCloseModal = () => {
    setModal(false);
    setResetErrorMessage(null);
  };
  
  return (
    <StatusContainer>
      {website && (
        <Fragment>
          <div>
            <ul>
              <li className='status-left'>
                <h2>
                  {WebsiteHelper.getLabel(website)}
                  <Fragment>
                    {WebsiteHelper.createOneClickLoginBubble(website, loadingWhileLogin, setLoadingWhileLogin, dispatch, modalDialog)}
                    {JsxHelper.createBubble({
                      customClass: 'status-refresh',
                      color: 'link',
                      icon: 'refresh',
                      onClick: onRefreshWebsite,
                      loading: loadingWhileRefresh,
                    })}
                    <HelpButton className='status-dropdown' onClick={() => setOpenMenu(true)}>
                      {JsxHelper.createIcon({ icon: 'verticalDots' })}
                      {openMenu && (
                        <NotificationBarList ref={ref}>
                          {actions.map(i => (!i.doHide || !i.doHide(website)) && <NotificationBarList.Item className={i.icon} onClick={i.onClick} key={i.value} icon={i.icon}>{i.value}</NotificationBarList.Item>)}
                        </NotificationBarList>
                      )}
                    </HelpButton>
                  </Fragment>
                </h2>
                <div>{WebsiteHelper.createLink(website)}</div>
              </li>
              <div className='status-right'>
                <li>
                  <span className='ws-profile-subheader'>STATE</span>
                  {JsxHelper.createIcon({
                    icon: stateStyle.icon,
                    color: stateStyle.color,
                    tooltip: stateStyle.text,
                    customClass: 'ws-state-icon',
                  })}
                </li>
                <li>
                  <span className='ws-profile-subheader'>STATUS</span>
                  {JsxHelper.createIcon({
                    icon: statusStyle.icon,
                    color: statusStyle.color,
                    tooltip: statusStyle.text,
                    customClass: 'ws-status-icon',
                  })}
                </li>
                <li>
                  <span className='ws-profile-subheader'>CDN</span>
                  {JsxHelper.createIcon({
                    icon: cdnStyle.icon,
                    color: cdnStyle.color,
                    tooltip: cdnStyle.text,
                    customClass: `ws-cdn-icon ${cdnStyle.customClass}`,
                  })}
                </li>
                <li>
                  <span className='ws-profile-subheader'>ALERTS</span>
                  {WebsiteHelper.createAlertIcon(history, website, 'ws-alert-icon')}
                </li>
                {UserHelper.isAdminOrAgent() && <li>
                  <span className='ws-profile-subheader'>SERVER</span>
                  <span>{website && website.server_slug ? website.server_slug : '-'}</span>
                </li>}
                {UserHelper.isAdminOrAgent() && <li>
                  <span className='ws-profile-subheader'>PARTNER</span>
                  <span>{website && website.partner_slug ? website.partner_slug : '-'}</span>
                </li>}
              </div>
            </ul>
          </div>
        </Fragment>
      )}
      {modal && DialogHelper.inputs({
        title: 'Reset Credentials',
        onClose: () => handleCloseModal(),
        onConfirm: handleSubmit(resetCredentials),
        loading: reseting,
        icon: 'error',
        iconColor: 'danger',
        confirmBtn: 'Reset',
        confirmColor: 'warning',
        register,
        header: <div>
          {resetErrorMessage && <p className='danger-font-color'>{resetErrorMessage }</p>}
          <p className='margin-top-20'>Retry with the following details:</p>
        </div>,
        inputs: [{
          label: 'User',
          name: 'user_login',
          value: resetData.user_login,
          onChange: handleOnChange,
          errors,
          required: true,
        }, {
          label: 'Email',
          name: 'user_email',
          value: resetData.user_email,
          onChange: handleOnChange,
          errors,
          required: true,
        }]
      })}
    </StatusContainer>
  );
};

export default WebsiteStatus;
