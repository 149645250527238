import React, { Fragment, useEffect, useRef } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { Content } from 'styles/globalStyles';
import { useDispatch } from 'react-redux';
import { getAllInovices } from 'store/billing/billingActions';
import { useState } from 'react';
import BillingService from 'services/billing';
import {
  setGlobalErrorMsg,
  setGlobalWarningMsg,
  setGlobalSuccessMsg,
  setGlobalPleaseWaitMsg
} from 'store/global/globalActions';
import { getAllCustomersInvoices } from 'store/customerBilling/customerBillingActions';
import UserHelper from 'helpers/user';
import StringHelper from 'helpers/string';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import AdvancedTabs from 'components/website/advanced/advancedTabs/advancedTabs';
import { isEmptyOrNull, exportCsvToFile } from 'helpers';
import useTitle from 'hooks/useTitle';
import ArrayHelper from 'helpers/array';
import useConfirm from 'hooks/useConfirm';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';

const Invoice = ({ customers }) => {
  useTitle('Invoices');
  const pageSize = 1000;
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [invoices, setInvoices] = useState([]);
  const [data, setData] = useState({
    partner_slug: null,
    page: 1,
    limit: pageSize,
  });
  const [customerData, setCustomerData] = useState({
    customer_slug: null,
    page: 1,
    limit: pageSize,
  });
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [showInvoiceDataTable, setShowInvoiceDataTable] = useState(false);
  const [invoiceDataRows, setInvoiceDataRows] = useState(null);
  const [paymentAttemptsTableFlag, setPaymentAttemptsTableFlag] = useState(false);
  const [paymentAttemptsTableRows, setPaymentAttemptsTableRows] = useState(null);
  const [selectedTableItem, setSelectedTableItem] = useState(null);

  const invoiceType = !customers ? 'partner' : 'customer';
  let pageTitle = 'Invoices';
  if (UserHelper.isPartner()) {
    pageTitle = invoiceType === 'partner' ? 'My Invoices' : 'Client Invoices';
  }
  const mounted = useRef(true);

  const fetchData = () => {
    setLoading(true);
    if (invoiceType === 'partner') {
      if (data.page === 1) {
        setTableLoading(true);
      }
      dispatch(getAllInovices(data))
        .then(res => {
          if (mounted.current) {
            setInvoices(invoices.concat(res));
            let myData = { ...data };
            myData.page = myData.page + 1;
            setData(myData);
            if (res.length < pageSize) {
              setDisabled(true);
            }
          }
        })
        .finally(() => {
          if (mounted.current) {
            setLoading(false);
            setTableLoading(false);
          }
        });
    } else {
      if (customerData.page === 1) {
        setTableLoading(true);
      }
      dispatch(getAllCustomersInvoices(customerData))
        .then(res => {
          if (mounted.current) {
            let myData = { ...data };
            myData.page = myData.page + 1;
            setInvoices(invoices.concat(res));
            setCustomerData(prev => ({ ...prev, page: customerData.page + 1 }));
            if (res.length < pageSize) {
              setDisabled(true);
            }
          }
        })
        .finally(() => {
          if (mounted.current) {
            setLoading(false);
            setTableLoading(false);
          }
        });
    } // eslint-disable-next-line
  };

  useEffect(() => {
    fetchData();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleLocalTablesClose = () => {
    setPaymentAttemptsTableRows(null);
    setInvoiceDataRows(null);
    setShowInvoiceDataTable(false);
    setPaymentAttemptsTableFlag(false);
  };

  const exportCSVFile = () => {
    if (isEmptyOrNull(invoices)) {
      dispatch(setGlobalWarningMsg('You cannot export an empty table.'));
      return;
    }
    const _data = invoices.map(el => {
      let row = {
        'Invoice ID': el.invoice_id,
        'Start Date': el.invoice_data.period_start_date,
        'End Date': el.invoice_data.period_end_date,
        Total: `${el.total} ${el.currency}`,
        Paid: el.is_paid ? 'Yes' : 'No',
      };
      if (UserHelper.isAdmin() && invoiceType === 'partner') {
        row.Partner = el.recipient_email;
      }
      if (UserHelper.isPartner() && invoiceType === 'customer') {
        row.Customer = el.recipient_email;
      }
      return row;
    });
    exportCsvToFile(_data, `${pageTitle.toLowerCase().replace(' ', '-')}.csv`);
  };

  const onInvoiceDeleteSucceeded = item => {
    let invoiceIndex = invoices.findIndex(el => el.guid === item.guid);
    if (invoiceIndex !== -1) {
      let data = [...invoices];
      data.splice(invoiceIndex, 1);
      setInvoices([...data]);
    }
    dispatch(setGlobalSuccessMsg({ model: 'invoice', id: item.invoice_id, action: 'deleted' }));
  }

  const onInvoiceDeleteFailed = err => {
    dispatch(setGlobalErrorMsg(err));
  }

  const onClickDeleteInvoice = item => {
    dispatch(setGlobalPleaseWaitMsg({ model: 'invoice', action: 'deleted', id: item.invoice_id }));
    if (UserHelper.isAdmin()) {
      BillingService.deletePartnerInvoice({ guid: item.guid })
      .then(() => onInvoiceDeleteSucceeded(item))
      .catch((err) => onInvoiceDeleteFailed(err))
    } else {
      BillingService.deleteCustomerInvoice({ guid: item.guid, customer_slug: item.recipient_slug })
      .then(() => onInvoiceDeleteSucceeded(item))
      .catch((err) => onInvoiceDeleteFailed(err))
    }
  };

  const onInvoiceChargeSucceeded = item => {
    const updatedInvoices = ArrayHelper.update(invoices, 'guid', item);
    setInvoices(updatedInvoices);
    dispatch(setGlobalSuccessMsg({ model: 'invoice', id: item.invoice_id, action: 'charged' }));
  }

  const onInvoiceChargeFailed = err => {
    dispatch(setGlobalErrorMsg(err));
  }

  const onClickChargeInvoice = item => {
    dispatch(setGlobalPleaseWaitMsg({ model: 'invoice', action: 'charged', id: item.invoice_id }));
    if (UserHelper.isAdmin() || (UserHelper.isPartner() && invoiceType === 'partner')) {
      BillingService.chargePartnerInvoice({ guid: item.guid })
      .then(() => onInvoiceChargeSucceeded(item))
      .catch((err) => onInvoiceChargeFailed(err))
    } else {
      BillingService.chargeCustomerInvoice({ guid: item.guid, customer_slug: item.recipient_slug })
      .then(() => onInvoiceChargeSucceeded(item))
      .catch((err) => onInvoiceChargeFailed(err))
    }
  };

  const onClickSetInvoiceAsPaid = item => {
    dispatch(setGlobalPleaseWaitMsg({ model: 'invoice', action: 'set as paid', id: item.invoice_id }));
    if (invoiceType === 'customer') {
      BillingService.setCustomerInvoiceAsPaid({ guid: item.guid, customer_slug: item.recipient_slug })
      .then((_item) => {
        const updatedInvoices = ArrayHelper.update(invoices, 'guid', _item);
        setInvoices(updatedInvoices);
        dispatch(setGlobalSuccessMsg({ model: 'invoice', id: item.invoice_id, action: 'set as paid' }));
      }).catch(err => dispatch(setGlobalErrorMsg(err)));
    }
  }

  const actions = [
    {
      value: 'Download',
      onClick: item => {
        const data = { file_name: item.id, guid: item.guid };
        if (!downloadLoading) {
          setDownloadLoading(true);
          BillingService.downloadInovice(data)
            .catch(err => dispatch(setGlobalErrorMsg(err)))
            .finally(() => setDownloadLoading(false));
        }
      },
    },
    {
      value: 'Breakdown',
      onClick: item => {
        let _data = [];
        if (!isEmptyOrNull(item.invoice_data.subscriptions)) {
          const subscriptions = [...item.invoice_data.subscriptions];
          for (const s of subscriptions) {
            _data.push(s);
          }
        }
        setInvoiceDataRows(_data);
        setSelectedTableItem({ ...item });
        setShowInvoiceDataTable(true);
      },
    },
    {
      value: 'Charge Now',
      doHide: item => item.is_paid,
      onClick: onClickChargeInvoice,
    },
    {
      value: 'Set as Paid',
      doHide: item => !UserHelper.isPartner() || invoiceType !== 'customer' || item.is_paid,
      onClick: onClickSetInvoiceAsPaid,
    },
    {
      value: 'Payment Attempts',
      doHide: item => isEmptyOrNull(item.payment_attempts),
      onClick: item => {
        if (!isEmptyOrNull(item.payment_attempts)) {
          setPaymentAttemptsTableFlag(true);
          setPaymentAttemptsTableRows([...item.payment_attempts]);
          setSelectedTableItem({ ...item });
          setShowInvoiceDataTable(true);
        }
      },
    },
    {
      value: 'Delete',
      doHide: item => (UserHelper.isPartner() && invoiceType === 'partner') || item.is_paid,
      onClick: item => DialogHelper
        .confirmDelete(confirm, item.invoice_id, 'invoice')
        .then(() => onClickDeleteInvoice(item))
    },
  ];

  const paymentAttemptsHeaders = [
    JsxHelper.createTableTimeHeader('attempted_at', null, '15%'),
    JsxHelper.createTableBinaryBubbleHeader('success', 'Status', '12%', (row) => row.success, true, 'Succeed', 'Failed'),
    JsxHelper.createTableTextListHeader('errors', 'Errors', null, '68%', '', true),
  ];

  const subscriptionFeatureUsage = (data) => {
    const currency = '$';
    let used = data.used;
    let limitFactor = parseFloat(data.limit);
    let proratedLimitFactor = data.prorated_limit && data.prorated_limit < limitFactor ? parseFloat(data.prorated_limit) : null;
    let limit = '';
    let unit = false; // don't print
    let perc = 0;
    let excess = false;
    if (data.limit_unit === '256 MB') { // php maxmem
      limit = limitFactor * 256;
      perc = ((used / limit) * 100).toFixed(2);
      unit = 'MB';
      excess = used - limit;
    } else if (data.limit_unit === 'GB') { // storage, bandwidth
      if (limitFactor === 0) {
          limit = 'unlimited';
          perc = 0;
          unit = false;
      } else {
        const rule = data.used_unit + '2' + data.limit_unit;
        limit = proratedLimitFactor ? proratedLimitFactor : limitFactor;
        used = StringHelper.convertDown(rule, data.used);
        perc = ((used / limit) * 100).toFixed(2);
        unit = data.limit_unit;
        excess = (used - limit).toFixed(2);
      }
    } else if (data.used_unit === '1' || data.used_unit === 1) { // visits (pro-rated)
      used = data.used * data.used_unit;
      if (limitFactor === 0) {
          limit = 'unlimited';
          perc = 0;
      } else {
        limit = (proratedLimitFactor ? proratedLimitFactor : limitFactor) * data.limit_unit;
        perc = ((used / limit) * 100).toFixed(2);
        excess = used - limit;
      }
    } else if (data.limit_unit === 'h') { // cpu time (pro-rated)
      limit = proratedLimitFactor ? proratedLimitFactor : limitFactor;
      used = (data.used / 3600).toFixed(2);
      perc = ((used / limit) * 100).toFixed(2);
      unit = 'h';
      excess = (used - limit).toFixed(2);
    }

    return <Fragment>
      <span className="used">{used} / {limit}{unit?` ${unit}`:''} ({perc}%)</span>
      {data.excess_usage && excess > 0 && <span className="excess" style={{display: 'block'}}>
        Extra {excess}{unit?` ${unit}`:''} charged {currency}{data.excess_usage_fees} at {currency}{data.excess_usage_unit_price} / {data.excess_usage_unit}
      </span>}
    </Fragment>
  }

  const subscriptionBreakdown = (s) => {
    const features = {
      'visits': 'Visits',
      'bandwidth': 'Bandwidth',
      'cloud_storage': 'Cloud storage',
      'local_storage': 'Local storage',
      'cpu_time': 'CPU usage',
      'db_storage': 'DB storage',
      'php_maxmem': 'PHP Memory',
    };

    return <div className="subscription-breakdown">
      { // Display the active period proration.
      s.usage_proration_factor < 1 && <div className="subscription-breakdown-row">
        <div className="prorate">
          <strong>Pro-rated</strong>: {s.period_active_days} / {s.period_total_days} days ({s.period_usage_perc.toFixed(2)}%)
        </div>
        {!isEmptyOrNull(s.subscription_start) && s.subscription_start > s.period_start_date && (
          <div className="prorate prorate-notice">Billed from {s.subscription_start.split(' ')[0]}</div>
        )}
        {!isEmptyOrNull(s.subscription_end) && s.subscription_end < s.period_end_date && (
          <div className="prorate prorate-notice">Billed until {s.subscription_end.split(' ')[0]}</div>
        )}
      </div>}
      { // Display the usage per features.
      Object.keys(features).map((f) => {
        return <div className="subscription-breakdown-row" key={f}>
          <strong>{features[f]}</strong>: {!s[f] ? 'N/A' : subscriptionFeatureUsage(s[f])}
        </div>
      })}
    </div>
  }

  const headers = [
    JsxHelper.createTableTextHeader('invoice_id', 'Invoice ID', '12%'),
    JsxHelper.createTableTextHeaderWithCallback(
      'recipient_email',
      (UserHelper.isAdmin() && invoiceType === 'partner') ? 'Partner' : 'Client',
      '28%',
      (row) => JsxHelper.createTableMultiLineCell({
        header: row.recipient_display_name,
        subheader: row.recipient_email,
      })
    ),
    {
      name: 'Billing Period',
      selector: 'period_start_date',
      searchable: true,
      width: '20%',
      cell: row => JsxHelper.createTableCellFromTo({
        from: row.invoice_data && row.invoice_data.period_start_date ? row.invoice_data.period_start_date : null,
        to: row.invoice_data && row.invoice_data.period_end_date ? row.invoice_data.period_end_date : null
      }),
    },
    JsxHelper.createTableTextHeaderWithCallback('total', 'Total', '12%', (row) => `${row.total} ${row.currency}`),
    JsxHelper.createTableBinaryBubbleHeader('is_paid', 'Paid', '8%'),
    JsxHelper.createTableActionsHeader(actions, '20%'),
  ];

  const breadcrumbs = JsxHelper.createBreadcrumbs('Invoices', !customers ? 'Billing' : 'Clients');

  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>{pageTitle}</TitleBar.Title>
        <TitleBar.Actions>
          {showInvoiceDataTable ? (
            <Fragment>{JsxHelper.createButton({
              label: 'Back',
              onClick: handleLocalTablesClose,
            })}
            </Fragment>
          ) : (
            <Fragment>
              {JsxHelper.createButton({
                label: 'Load More',
                loading,
                disabled,
                onClick: fetchData,
              })}
              {JsxHelper.createButton({
                label: 'Export',
                onClick: exportCSVFile,
              })}
              {JsxHelper.createBackButton()}
            </Fragment>
          )}
        </TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader margin-top-24 font-small'>
        {(invoices && invoices.length > 0) && JsxHelper.createTipBox(`Loaded ${invoices.length} ${StringHelper.maybePluraize('invoice', invoices.length)}.`, true)}
      </p>
      <Content>
        {showInvoiceDataTable ? (
          <Fragment>
            <TitleBar.Title style={{ fontSize: '20px', marginBottom: '20px' }}>
              {selectedTableItem && selectedTableItem.invoice_id && invoiceDataRows
                ? `Invoice #${selectedTableItem.invoice_id} (${invoiceDataRows.length} ${StringHelper.maybePluraize('subscription', invoiceDataRows.length)})`
                : ''}
            </TitleBar.Title>
            {paymentAttemptsTableFlag && <WPSDataTable
              columns={paymentAttemptsHeaders}
              body={ArrayHelper.generateUniqueField(
                paymentAttemptsTableRows,
                'attempted_at',
                'rowId',
              )}
              noSearchOnTable={true}
              dataKey={'rowId'}
            />}
            {!paymentAttemptsTableFlag && (
              <div className="invoice-breakdown">
                {invoiceDataRows.map((s) => (
                  <AdvancedTabs
                    key={s.subscription_guid}
                    name={s.subscription_name}
                    title={s.subscription_name}
                    desc={subscriptionBreakdown(s)}
                  />
                ))}
            </div>)}
          </Fragment>
        ) : (
          <WPSDataTable
            columns={headers}
            body={invoices}
            loading={tableLoading}
            dataKey='invoice_id'
          />
        )}
      </Content>
    </Fragment>
  );
};

export default Invoice;
